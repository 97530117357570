import React from 'react';
import { useOutletContext } from 'react-router-dom';
import classNames from 'classnames';

import demoStyles from '../extension-demo.module.css';
import { adFormats } from '../../../../utils/content-stream-monitoring-utils';

const Lbar = () => {
  /** @type Object*/
  const [encodeArgs, setEncodeArgs] = useOutletContext();
  const uploadedContent = encodeArgs.contentUrl;
  const uploadedBgSkin = encodeArgs.skin;

  React.useEffect(() => {
    const { adFormat } = encodeArgs;
    if (adFormat !== adFormats.squeezeBack)
      setEncodeArgs({ ...encodeArgs, adFormat: adFormats.squeezeBack, adUrl: null });
  }, []);

  return (
    <React.Fragment>
      <div
        className={classNames(demoStyles.adFormatLayoutContainer, demoStyles.placeholderContainer)}
        style={{ backgroundImage: uploadedBgSkin ? `url(${uploadedBgSkin})` : '' }}
      >
        <div className={demoStyles.lbarAssetsContainer}>
          {uploadedContent ? (
            <video className={demoStyles.lbarContent} autoPlay muted loop src={uploadedContent} />
          ) : (
            <div className={demoStyles.lbarContentPlaceholder} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

Lbar.defaultProps = {};
Lbar.propTypes = {};

export default Lbar;
