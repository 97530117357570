import { combineReducers } from 'redux';

import user from './user-reducer';
import auth from './auth-reducer';
import account from './account-reducer';
import currentAccount from './current-account-reducer';
import selectedAccount from './selected-account-reducer';
import breadcrumb from './breadcrumb-reducer';
import nav from './nav-reducer';
import { CreativeStudioReducer } from '../creative-studio/studio-reducer';
import ToastReducer from './toast-reducer';
import ContentStreamWizardReducer from './content-stream-wizard-reducer';

const AppReducer = combineReducers({
  auth,
  user,
  account,
  currentAccount,
  selectedAccount,
  breadcrumb,
  nav,
  studio: CreativeStudioReducer,
  toast: ToastReducer,
  contentStreamWizard: ContentStreamWizardReducer,
});

const RootReducer = (state, action) => {
  return AppReducer(state, action);
};

export default RootReducer;
