import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import classNames from 'classnames';
import theme from '../../styles/theme';
import variables from '../../styles/variables';
import styles from './site.module.css';

const useStyles = makeStyles(() => ({
  root: {
    '& .highlight': {
      color: variables.composerMarketingPurple,
    },
  },
  container: {
    maxWidth: 1080,
    padding: '0 45px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    minHeight: '100vh',

    [theme.breakpoints.down('md')]: {
      padding: '0 18px',
    },
  },

  header: {
    fontSize: '54px',
    fontWeight: 800,
    lineHeight: '64px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: variables.composerMarketingPurple,

    [theme.breakpoints.down('md')]: {
      fontSize: '52px',
      lineHeight: '52px',
    },
  },

  policy: {
    margin: '20px 0 0 0',
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    '& b': {
      fontWeight: 800,
    },
    '& i': {
      fontStyle: 'italic',
    },
  },
}));

const Privacy = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classNames(styles.container)}>
        <div className={styles.centerContainer}>
          <section className={classNames(styles.flex, styles.flexCol, styles.horizontalPaddingContainer)}>
            <div className={classNames(styles.headerOffset)}>
              <div className={classNames(styles.gap, styles.roundedContainer)}>
                <div className={classNames(styles.flex, styles.flexCol, styles.gap, styles.alignCenter)}>
                  <h2 className={styles.heading}>Privacy Policy</h2>

                  <Box className={classes.policy}>
                    <p>Effective Date: July 27, 2022</p>
                    <br />
                    <p>
                      Transmit.Live LLC (“Transmit.Live”)provides this privacy policy to describe how we collect, use,
                      and share personal information when you visit https://www.transmit.live/and other websites that we
                      own or control that link to this privacy policy (the “Sites”),and personal information we collect
                      through the online video advertising services we provide to advertisers, agencies, publishers, and
                      other businesses across third-party websites and online media (the “Services”). Please read the
                      following carefully to understand our practices regarding information we collect.
                    </p>
                    <br />
                    <b>Table of Contents:</b>
                    <br />
                    <br />
                    <p>‍1. Information Transmit.Live Collects</p>
                    <p>2. How We Use Information</p>
                    <p>3. How We Share Information</p>
                    <p>4. Digital Advertising & Analytics</p>
                    <p>5. Third-Party Links & Tools</p>
                    <p>6. Choices We Offer</p>
                    <p>7. Privacy Rights</p>
                    <p>8. Changes to This Privacy Policy</p>
                    <p>9. How to Contact Us</p>
                    <br />
                    <b>Information Transmit.Live Collects</b>
                    <br />
                    <br />
                    <i>When you visit our Sites</i>
                    <br />
                    <br />
                    <p>
                      ‍When visiting our Sites, you can choose to provide personal information, including your name,
                      contact information, and any other information you choose. For example, we collect this
                      information when you request a demo, subscribe to our newsletter, contact us, or interact with us
                      for any other purpose. We (and other entities) also automatically collect information through our
                      Sites, including browser type, IP address, pages visited and other activities on the Sites, device
                      type, time and date of visit, and other information we collect through the use of cookies and
                      similar technology.
                    </p>
                    <br />
                    <i>When we provide our Services</i>
                    <br />
                    <br />
                    <p>
                      ‍We partner with numerous companies to provide online advertisements to visitors of third-party
                      websites, mobile websites, mobile applications, digital media streaming services, and other online
                      media. We refer to visitors of such third parties as “End Users.” To provide Services to our
                      partners, we collect certain information about End Users. For instance, our partners may send us
                      certain information about a specific End User, including, but not limited to, IP address, online
                      advertising identifiers (such as “Mobile Advertising Identifiers” and “Identifiers for
                      Advertising”), browser or device type, pages visited or other activities on the partner’s
                      property, general location information, and other information the partner chooses to share. This
                      information may be used to generate new advertisements to be delivered to End Users or to better
                      ensure that advertisements delivered to End Users are delivered without interrupting the End
                      User’s experience.
                    </p>
                    <br />
                    <p>
                      We also may combine the information about End Users we receive from our partners with other
                      information we collect. For example, we may receive information from third parties that helps to
                      inform what advertisements might be of interest to End Users, such as audience interest segments
                      and product categories. We may also derive general device location based on an End User’s IP
                      address. Please see the “Digital Advertising & Analytics” section of this privacy policy to learn
                      more about the use of the information we collect through our Services and the choices available to
                      you.
                    </p>
                    <br />
                    <b>How We Use Information</b>
                    <br />
                    <br />
                    <p>
                      ‍We may use the information we collect to operate our business and as otherwise allowed by law,
                      such as:
                    </p>
                    <p>· To respond to your inquiries;</p>
                    <p>
                      · To provide our Services, including to create, schedule, deliver, customize, and/or optimize
                      advertisements;
                    </p>
                    <p>
                      · To provide information and reports to advertisers, agencies, and publishers who participate in
                      our Services,
                    </p>
                    <p>· To operate, personalize, and improve our Sites and Services;</p>
                    <p>· For troubleshooting purposes, including fraud detection, and to provide customer support;</p>
                    <p>· To send updates that may be of interest to you;</p>
                    <p>· To comply with the law and to maintain the security of our Sites and Services; or</p>
                    <p>· With your consent, or as otherwise disclosed at the time information is collected.</p>
                    <br />
                    <b>How We Share Information</b>
                    <br />
                    <br />
                    <p>‍We may share the information we collect with other parties, including the following:</p>
                    <p>
                      · With our partners, including advertisers, agencies, and publishers who participate in our
                      Services, as well as third-party services providers these partners may use;
                    </p>
                    <p>
                      · With third-party service providers who process information on our behalf, such as cloud-based
                      data hosting services, and email service providers, to the extent necessary to perform services
                      for us;
                    </p>
                    <p>
                      · With advertising technology companies we work with to provide our Services, such as demand side
                      platforms and companies delivering advertisements;
                    </p>
                    <p>
                      · With market measurement companies, such as Nielsen, to better understand the reach of
                      advertising campaigns;
                    </p>
                    <p>· With any affiliates or joint venture partners that we may have in the future;</p>
                    <p>
                      · As part of a corporate sale, merger, or acquisition, or other disposition of all or part of our
                      assets;
                    </p>
                    <p>
                      · Pursuant to a subpoena, court order, governmental inquiry, or other legal process or as
                      otherwise required by law;
                    </p>
                    <p>· To protect our rights or the rights of others, or for safety or security reasons;</p>
                    <p>
                      · With your consent or direction, or as otherwise disclosed at the time information is collected.
                    </p>
                    ‍
                    <p>
                      Further, we may share information we have collected through the Sites that has been de-identified
                      or aggregated without limitation. In addition, we may share information that we have collected
                      through the Services that has been de-identified or aggregated more broadly when permitted by our
                      partners.
                    </p>
                    <br />
                    <b>Digital Advertising & Analytics</b>
                    <br />‍
                    <p>
                      Transmit.Live operates on behalf of our partners, including publishers and advertisers, to create
                      advertising opportunities and deliver advertisements. To provide our Services, we may use data
                      that has been collected across different devices and from non-affiliated websites, applications,
                      and devices over time as well as other data described in this policy to assist in providing
                      advertising that is relevant to a device’s general location and/or interests inferred from this
                      data. This type of advertising is known as interest-based advertising.
                    </p>
                    <p>
                      Transmit.Live adheres to the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles in
                      connection with this interest-based advertising activity. Please visit www.aboutads.info to learn
                      more about this type of advertising.
                    </p>
                    <br />
                    <p>Additionally, you may exercise the following choices:</p>‍
                    <p>
                      · Mobile Devices (Applications). You may download the DAA AppChoices application from the Android
                      or iOS app stores to opt out of interest-based advertising on mobile devices. ‍
                    </p>
                    <p>
                      · Browsers (Websites). You may use the DAA Web choices tool at www.aboutads.info to opt out of
                      interest-based advertising on websites by companies participating in the DAA program.
                    </p>
                    <p>
                      · Connected Televisions. You may be able to opt out of the collection of information for
                      interest-based advertising on your connected or “smart” TV or set up box by visiting your device’s
                      settings menu.
                    </p>
                    <br />
                    <p>
                      If you use a different device or browser, or delete cookies, you may need to renew your opt out
                      choice(s). Opting out will not stop advertising in your devices or browsers but it might make the
                      ads you see less relevant to your interests.{' '}
                    </p>
                    <br />
                    <p>
                      Additionally, to operate our Sites, we may work with third parties that collect data about your
                      use of the Sites and other sites or apps over time for non-advertising purposes. Transmit.Live may
                      use Google Analytics and other third-party services to improve the performance of the Sites and
                      for analytics and marketing purposes. For more information about how Google Analytics may collect
                      and use data when you use our Sites, visit www.google.com/policies/privacy/partners, and to opt
                      out of Google Analytics, visit tools.google.com/dlpage/gaoptout.
                    </p>
                    <br />
                    <b>Third-Party Links & Tools</b>
                    <br />
                    <br />
                    <p>
                      ‍The Sites may have links to third-party websites or apps. We do not control those websites or
                      apps, and they are not covered by this privacy policy. You should review the privacy policies of
                      other websites or apps that you use to learn about their data practices. The Sites may also have
                      integrated social media tools or “plug-ins,” such as social networking tools offered by third
                      parties. If you interact with these tools on the Sites, those other companies may collect
                      information about you and may use and share such information under your account settings,
                      including by sharing such information with the general public.
                    </p>
                    <br />
                    <p>
                      Additionally, your interactions with third-party companies, including partners that use our
                      Services, fall under these companies’ privacy policies. While our Services may appear on
                      third-party companies’ websites, apps, or other online media, we do not control the information
                      practices of these companies and they are not covered by this privacy policy. We encourage you to
                      review the privacy policies of companies whose properties you may visit or use.
                    </p>
                    <br />
                    <b>Choices We Offer</b>
                    <br />
                    <br />
                    <p>
                      ‍If you have requested to join our newsletter or receive a demo, we may send you email marketing
                      messages. To opt out of our email marketing, you can use the link provided at the bottom of each
                      marketing message. If you opt out of email marketing, we will still send you messages related to
                      our transactions and relationship with you, like account updates.
                    </p>
                    <br />
                    <p>
                      For choices with respect to interest-based advertising activities, please see the “Digital
                      Advertising & Analytics” section above.{' '}
                    </p>
                    <br />
                    <b>Privacy Rights</b>
                    <br />
                    <br />
                    <p>
                      ‍Certain jurisdictions provide privacy rights to residents or other individuals by law, and
                      Transmit.Live provides services to partners who may be required to provide you with rights under
                      these laws. For instance, if you are a California resident or live in the European Union, you may
                      have rights under privacy laws in those jurisdictions. We encourage you to visit the privacy
                      policies of websites and apps you visit and streaming services you use to learn what rights might
                      be available to you.
                    </p>
                    <br />
                    <b>Changes to This Privacy Policy</b>
                    <br />
                    <br />
                    <p>
                      ‍If our information practices change, we will post these changes on this page. We encourage you to
                      visit this page periodically to learn of any updates.
                    </p>
                    <br />
                    <b>How to Contact Us</b>
                    <br />
                    <br />
                    <p>
                      ‍If you have questions, comments, or concerns about this privacy policy, please contact us at:
                    </p>
                    <p>
                      <a href="mailto:admin@transmit.live">admin@transmit.live</a>
                    </p>
                  </Box>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Container>
    </div>
  );
};

export default Privacy;
