import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { LoadingButton } from '@mui/lab';

import styles from './site.module.css';
import requestDemoStyles from './request-demo.module.css';

const useStyles = makeStyles(() => ({
  messageTextField: {
    '& .MuiOutlinedInput-root': {
      padding: 14,
    },
  },
}));

const RequestDemoForm = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  /** @type {[Object, React.Dispatch<Object>]} */
  const [errors, setErrors] = useState({});

  const demoForm = useRef(undefined);
  const [demoFormContents, setDemoFormContents] = useState(null);
  const [demoFormSent, setDemoFormSent] = useState(false);

  const sendDemoEmail = () => {
    if (!demoForm.current) {
      return;
    }

    let valid = true;
    setErrors({});

    ['first_name', 'last_name', 'message'].forEach((field) => {
      const val = demoForm.current[field].value;
      if (val === null || val.match(/^ *$/) !== null) {
        setErrors((prevState) => {
          return {
            ...prevState,
            [field]: "can't be blank",
          };
        });
        valid = false;
      }
    });

    const emailReg = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    const val = demoForm.current.email.value;
    if (!val.match(emailReg)) {
      setErrors((prevState) => {
        return {
          ...prevState,
          email: 'must be valid',
        };
      });

      valid = false;
    }

    if (valid) {
      setLoading(true);
      emailjs.sendForm('service_dmb3xgb', 'template_hbjyzti', demoForm.current, 'NmlqxbNRQANpGue3u').then(
        () => {
          setDemoFormSent(true);
          setDemoFormContents(null);
          setLoading(false);
        },
        (error) => {
          console.log({ error });
          setLoading(false);
        }
      );
    }
  };

  const demoFormChange = (e) => {
    const { name, value } = e.target;

    setDemoFormContents((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <>
      {demoFormSent ? (
        <div>
          <h2 className={styles.center}>Thanks!</h2>
          <div className={classNames(styles.mt2, styles.center, styles.noTransform)}>Well be in touch soon.</div>
        </div>
      ) : (
        <div>
          <div style={{ marginBottom: 24 }} className={classNames(styles.flex)}>
            <h3 className={requestDemoStyles.interFont}>Request a Demo</h3>
          </div>
          <form ref={demoForm} onSubmit={sendDemoEmail}>
            <div className={styles.inputBlock}>
              <InputLabel htmlFor="first_name" className={styles.inputLabel}>
                First Name
              </InputLabel>
              <TextField
                value={demoFormContents ? demoFormContents.first_name || '' : ''}
                onChange={demoFormChange}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name}
                id="first_name"
                name="first_name"
                autoFocus
              />
            </div>
            <div className={styles.inputBlock}>
              <InputLabel htmlFor="last_name" className={styles.inputLabel}>
                Last Name
              </InputLabel>
              <TextField
                value={demoFormContents ? demoFormContents.last_name || '' : ''}
                onChange={demoFormChange}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name}
                id="last_name"
                name="last_name"
              />
            </div>
            <div className={styles.inputBlock}>
              <InputLabel htmlFor="email" className={styles.inputLabel}>
                Email
              </InputLabel>
              <TextField
                value={demoFormContents ? demoFormContents.email || '' : ''}
                onChange={demoFormChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
                id="email"
                name="email"
              />
            </div>
            <div className={styles.inputBlock}>
              <InputLabel htmlFor="message" className={styles.inputLabel}>
                Message
              </InputLabel>
              <TextField
                className={classes.messageTextField}
                multiline
                rows={3}
                value={demoFormContents ? demoFormContents.message || '' : ''}
                onChange={demoFormChange}
                error={Boolean(errors.message)}
                helperText={errors.message}
                id="message"
                name="message"
              />
            </div>

            <div className={classNames(styles.flex, styles.mt2, styles.gap)}>
              <LoadingButton
                sx={{ width: '100%' }}
                loading={loading}
                className={classNames(styles.keyButton)}
                onClick={() => {
                  sendDemoEmail();
                }}
              >
                Submit
              </LoadingButton>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default RequestDemoForm;
