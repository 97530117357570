import { matchPath } from 'react-router-dom';

import { UPDATE_BREADCRUMB, RECEIVE_BREADCRUMB, RECEIVE_PATHNAME, LOGOUT } from '../action-types';

const initialState = [];

const BreadCrumbReducer = (state = initialState, action) => {
  let unsortedCrumbs;
  switch (action.type) {
    case UPDATE_BREADCRUMB:
      unsortedCrumbs = state.map((existing) => {
        return existing.path === action.breadcrumb.path ? action.breadcrumb : existing;
      });
      break;
    case RECEIVE_BREADCRUMB:
      if (state.length > 0 && state[state.length - 1].path === action.breadcrumb.path) {
        unsortedCrumbs = [...state.slice(0, state.length - 1), action.breadcrumb];
      } else {
        unsortedCrumbs = [...state, action.breadcrumb];
      }
      break;
    case RECEIVE_PATHNAME:
      unsortedCrumbs = [
        ...state.reduce((acc, curr) => {
          const { path, id } = curr;

          const match = matchPath({ path }, action.breadcrumb) && action.breadcrumb.includes(id);

          if (match) acc.push(curr);
          return acc;
        }, []),
      ];
      break;
    case LOGOUT:
      return initialState;
    default:
      return [...state];
  }

  return unsortedCrumbs.sort((a, b) => a.sortOrder - b.sortOrder);
};

export default BreadCrumbReducer;
