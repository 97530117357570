/* eslint-disable max-len */
import React from 'react';
import { Box, Button, Container } from '@mui/material';
import classNames from 'classnames';

import styles from '../site.module.css';
import homeStyles from './home.module.css';
import LogoGrid from '../logo-grid';

import HomeBgLogoLeft from '../svg/home-bg-logo-left';
import HomeBgLogoRight from '../svg/home-bg-logo-right';
import PartnerShowcase from './partner-showcase';
import NielsenLogo from '../svg/nielsen-logo';
import NielsenMetric from './nielsen-metric';
import ComposerCard from './composer-card';
import ExtensionCard from './extension-card';
import RequestDemoBanner from '../request-demo-banner';
import CreativeStudioCard from './creative-studio-card';

const Home = () => {
  return (
    <div>
      <div className={styles.homeScreenGradientFade} />
      <div className={styles.homeScreenGradient} />
      <Container className={classNames(styles.container, homeStyles.home)}>
        <div className={classNames(homeStyles.homeHeader)}>
          <div className={styles.centerBlock}>
            <h1 className={classNames(styles.newHeading, homeStyles.homeHeading)}>
              <span>Go beyond</span> traditional ad breaks
            </h1>
            <Box className={classNames(styles.description, styles.medium, homeStyles.headerDescription)}>
              <span>Transform viewer interruptions into engagements. </span>
              <span>Discover Transmit’s innovative ad solutions.</span>
            </Box>
            <Button className={classNames(styles.button, styles.buttonPurple, styles.mt2)} href="/request-demo">
              Request a demo
            </Button>
          </div>

          <div className={classNames(styles.mt6)}>
            <div className={classNames(styles.centerBlock, styles.mb2)}>
              <p className={classNames(styles.trustedByTopBrands)}>Trusted by top brands</p>
            </div>
          </div>
          <LogoGrid />

          <div className={styles.section} style={{ maxWidth: 1152 }}>
            <Box>
              <video
                className={homeStyles.homeVideo}
                src="https://tl-open-content.s3.amazonaws.com/web-assets/mastersrolex-2024-PIPtemplate.mp4"
                width="100%"
                height="100%"
                loop
                muted
                poster="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/transmit-hero.jpg"
                autoPlay
                playsInline
              />
            </Box>
          </div>

          <HomeBgLogoLeft />
          <HomeBgLogoRight />
        </div>

        <div
          style={{
            overflowX: 'hidden',
            marginBottom: 91,
          }}
        >
          <div className={classNames(styles.section, styles.flex, styles.flexCol)} style={{ marginTop: 91, gap: 14 }}>
            <h2 className={classNames(styles.centerBlock, styles.heading)}>Our streaming partnerships</h2>
            <div className={classNames(styles.flex, styles.justifyCenter)}>
              <p className={homeStyles.streamingPartnershipsDesc}>
                Compose and publish content streams. Build creatives to leverage new inventory.
              </p>
            </div>
          </div>
          <div style={{ marginTop: 51 }}>
            <PartnerShowcase />
          </div>
        </div>

        <div className={classNames(styles.flex, styles.flexCol, homeStyles.homeCardWrapper)}>
          <ComposerCard />
          <ExtensionCard />
          <CreativeStudioCard />
          <div className={classNames(styles.sectionCard, homeStyles.nielsenMetricsContainer)}>
            <div className={homeStyles.nielsenMetrics}>
              <div className={classNames(styles.leftAlign, homeStyles.nielsenMetricHeader)}>
                <NielsenLogo />
                <div>
                  <h2 className={classNames(styles.heading, styles.nielsenMetricsTitle)}>
                    Our ad formats boost view engagement
                  </h2>
                  <div className={homeStyles.nielsenMetricsDesc}>
                    Validated by the world&apos;s largest measurement company, Nielsen, our ad format has been proven to
                    sustain consumer attention.
                  </div>
                </div>
              </div>
              <NielsenMetric
                title="Brand recall"
                description="94% of respondents were able to recall the featured brand"
                percentage={94}
              />
              <NielsenMetric
                title="Ad favorability"
                description="91% of users preferred our PIP user experience"
                percentage={91}
              />
              <NielsenMetric
                title="Preferred ad experience"
                description="Preferred by 77% of users for sustained attention"
                percentage={77}
              />
              <NielsenMetric
                title="Message association"
                description="85% of users recalled specific messaging in ad"
                percentage={85}
              />
            </div>
          </div>
        </div>
        <div className={classNames(styles.centerBlock, homeStyles.requestDemoWrapper)} style={{ padding: 0 }}>
          <RequestDemoBanner />
        </div>
      </Container>
    </div>
  );
};

export default Home;
