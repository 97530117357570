import { useNavigate } from 'react-router-dom';

import { shouldRedirectToInitialStep } from '../utils';

const useRedirectToInitialStep = ({ step, encodeArgs }) => {
  const navigate = useNavigate();
  const redirectToInitialStep = shouldRedirectToInitialStep({
    step,
    encodeArgs,
  });

  return redirectToInitialStep ? navigate('/demo/create-your-own/upload-content') : null;
};

export default useRedirectToInitialStep;
