import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import homeStyles from '../home/home.module.css';
import styles from '../site.module.css';
import tabbedStyles from './tabbed-section.module.css';

const TabbedSectionContent = ({ children, tabTitle, heading, description, page }) => {
  const tabTitleClassName = useMemo(() => {
    switch (page) {
      case 'composer':
        return tabbedStyles.composerColor;
      case 'extension':
        return tabbedStyles.extensionColor;
      default:
        return tabbedStyles.extensionColor;
    }
  }, [tabTitle]);

  return (
    <div className={tabbedStyles.tabSectionContent}>
      <div className={tabbedStyles.tabContentChildren}>{children}</div>
      <div className={classNames(tabbedStyles.headerAndText, styles.fullWidth)}>
        <div className={classNames(tabbedStyles.tabContentHeader, styles.leftAlign)}>
          <div className={classNames(tabTitleClassName)}>{tabTitle}</div>
          <h3 className={tabbedStyles.tabHeading}>{heading}</h3>
          <div className={classNames(styles.description, tabbedStyles.tabDescription, homeStyles.cardsDesc)}>
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

TabbedSectionContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
  tabTitle: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  page: PropTypes.oneOf(['composer', 'extension']).isRequired,
};

export default TabbedSectionContent;
