import React from 'react';
import { Chip } from '@mui/material';
import { format as d3Format } from 'd3';
import { format } from 'date-fns';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import { userTZFromMongo } from './date-utils';

export const formatDateTime = (dateTime, dateFormat = 'MM/dd/yy', timezone) => {
  const iso = userTZFromMongo(dateTime, timezone);
  if (!iso) return 'N/A';

  return format(iso, dateFormat);
};

export const formatCurrency = (currency) => d3Format('$,.2f')(currency);

export const formatFloat = (int, decimalPlaces = 2) => d3Format(`,.${decimalPlaces}f`)(int);

export const formatLongInt = (longInt) => {
  return Number.isFinite(longInt) ? d3Format(',.0f')(longInt) : longInt;
};

export const formatPercent = (fraction, precision) => d3Format(`.${precision || 0}%`)(fraction);

export const formatAdDuration = (adDuration) => (adDuration ? `:${Math.round(adDuration)}` : 'Rotating');

export const deformatAdDuration = (adDuration) =>
  adDuration === 'Rotating' ? null : parseInt(adDuration.slice(1), 10);

export const formatAdType = (ad_type, classes) => {
  if (!ad_type) {
    return <Chip className={classes.ssai} label="None" title="Ad not yet accociated" />;
  }

  if (ad_type === 'Ad') {
    return <Chip className={classes.ssai} label="Internal" title="Internal" />;
  }

  return <Chip className={classes.vast} label="External" title="Internal" />;
};

export const formattedObjects = ({ objects, formats }) => {
  return objects.map((object) => {
    return Object.entries(object).reduce((acc, cur) => {
      const [k, v] = cur;
      acc[k] = formats[k] ? formats[k](v) : v;
      return acc;
    }, {});
  });
};

export const formatRunning = (value, classes) => {
  if (value) {
    return <Chip className={classes.runningChip} label="Running" title="Running" />;
  }

  return <Chip className={classes.notRunningChip} label="Not Running" title="Not Running" />;
};

export const formatSsai = (value, classes) => {
  if (value) {
    return <CloudDoneOutlinedIcon className={classes.openMarketplace} titleAccess="Open Marketplace" />;
  }

  return <CloudOffOutlinedIcon className={classes.notOpenMarketplace} titleAccess="Private Marketplace" />;
};

export const formatLiveVideoTimestamp = (dateTime) => {
  return format(dateTime, 'HH:mm:ss');
};
