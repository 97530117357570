import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useOutletContext } from 'react-router-dom';
import styles from '../site.module.css';
import csStyles from './creative-studio.module.css';
import homeStyles from '../home/home.module.css';

const CreativeStudioShowcase = () => {
  const /** @type {Object} */ { scrollY } = useOutletContext();
  const studioScreenshotContainer = React.useRef(null);
  const imgPlaceholderRef = React.useRef(null);

  const [loadedImages, setLoadedImages] = React.useState({
    studioScreenshot: false,
  });
  const allImagesLoaded = useMemo(() => Object.values(loadedImages).every(Boolean), [loadedImages]);

  useEffect(() => {
    if (allImagesLoaded) {
      if (imgPlaceholderRef.current) {
        imgPlaceholderRef.current.style.height = `${studioScreenshotContainer.current.clientHeight - 90}px`;
      }
    }
  }, [scrollY, allImagesLoaded]);

  return (
    <div className={classNames(csStyles.csHeaderContainer, styles.medium, homeStyles.csCardContent)}>
      <div ref={imgPlaceholderRef} />
      <img
        ref={studioScreenshotContainer}
        className={classNames(csStyles.csHeader, csStyles.creativeStudioImg)}
        src="https://tl-open-content.s3.amazonaws.com/web-assets/cs-screenshot.png"
        alt="creative studio screenshot"
        height="auto"
        width="100%"
        onLoad={() => setLoadedImages((prev) => ({ ...prev, studioScreenshot: true }))}
      />
      <img
        width="auto"
        src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/ad-content-scene-card.svg"
        alt=""
        className={classNames(csStyles.csHeaderLeft, csStyles.csHeaderOverlayImgs)}
        onLoad={() => setLoadedImages((prev) => ({ ...prev, headerLeft: true }))}
      />
      <img
        width="auto"
        src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/initial-view.svg"
        alt=""
        className={classNames(csStyles.csHeaderRight, csStyles.csHeaderOverlayImgs)}
        onLoad={() => setLoadedImages((prev) => ({ ...prev, headerRight: true }))}
      />
    </div>
  );
};

export default CreativeStudioShowcase;
