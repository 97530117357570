import Cookies from 'js-cookie';
import axios from 'axios';
import { LOGOUT } from '../redux/action-types';

export const defaultHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  if (Cookies.get('authToken')) {
    headers.Authorization = `Bearer ${Cookies.get('authToken')}`;
  }
  return headers;
};

export const prepareRequestHeaders = () => {
  axios.defaults.headers.common = { ...axios.defaults.headers.common, ...defaultHeaders() };
};

export const handleAuthErrors = (store) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && [401, 403].includes(error.response.status)) {
        store.dispatch({ type: LOGOUT });
      }

      return Promise.reject(error);
    }
  );
};
