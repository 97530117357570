import Cookies from 'js-cookie';
import { RECEIVE_SELECTED_ACCOUNT, SWITCH_SELECTED_ACCOUNT, LOGOUT } from '../action-types';

const initialState = null;

const SelectedAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SELECTED_ACCOUNT:
      return action.selectedAccount;
    case SWITCH_SELECTED_ACCOUNT:
      Cookies.remove('selectedAccountId');
      return initialState;
    case LOGOUT:
      Cookies.remove('selectedAccountId');
      return initialState;
    default:
      return state;
  }
};

export default SelectedAccountReducer;
