/* eslint-disable max-len */
import React from 'react';

const DeveloperReadyIcon = React.memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="26" viewBox="0 0 33 26" fill="none">
    <path
      d="M7.74202 6.70511L1 13.4471L7.74202 20.1893"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.2581 6.70511L32.0001 13.4471L25.2581 20.1893"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.3677 24.5807L21.618 1" stroke="#3CB094" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
));

export default DeveloperReadyIcon;
