@font-face {
  font-family: 'Selecta';
  src: url('../../assets/fonts/selecta-regular.woff2') format('woff2'),
    url('../../assets/fonts/selecta-regular.woff') format('woff');
  font-style: normal;
  font-weight: 1 1000;
}

@font-face {
  font-family: 'SelectaMedium';
  src: url('../../assets/fonts/selecta-medium.woff2') format('woff2'),
    url('../../assets/fonts/selecta-medium.woff') format('woff');
  font-style: normal;
  font-weight: 1 1000;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/inter-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'InterMedium';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/inter-medium.woff2') format('woff2');
}

:root {
  /* colors */
  --composerTextBlack: #000000;
  --composerBlack: #001e2b;
  --siteWhite: #ffffff;
  --siteDarkWhite: #f7f7f7;
  --siteBlack: #000000;
  --sitePurple: #7b50fc;
  --sitePurpleDark: #5a24fb;
  --siteGrey: #eceff5;
  --siteGreyLight: #f5f5f5;
  --siteGreen: #3cb094;
  --siteGreenLight: #4ed8a0;
  --siteGreenLighter: #eaf1ef;
  --siteBlue: #3490e0;
  --siteBlueLight: #51abfa;
  --extensionBlue: #dfeefb;
  --siteDarkGrey: #232833;
}

.websiteRoot {
  font-family: 'Selecta', sans-serif;
  height: 100%;
}

.defaultSiteBg {
  background: linear-gradient(97.15deg, #f6f6f6 0%, #e0d2f1 100%);
}

.fullViewHeight {
  height: 100dvh;
}

/* buttons */

.button {
  padding: 7px 20px;
  border-radius: 100px;
  text-transform: none;
}

button.button span {
  display: flex;
}

.buttonPurple {
  background-color: var(--sitePurpleDark);
  color: var(--siteWhite);
}

.buttonPurple:hover {
  background-color: var(--sitePurple);
}

.buttonPurple svg {
  color: var(--siteWhite);
}

.buttonGreen {
  background-color: var(--siteGreen);
  color: var(--siteWhite);
}

.buttonGreen:hover {
  background-color: var(--siteGreenLight);
}

.buttonGreen svg {
  color: var(--siteWhite);
}

.buttonWhite {
  color: var(--siteBlack);
  background-color: var(--siteWhite);
}

.buttonWhite:hover {
  background-color: var(--siteDarkWhite);
}

.buttonWhite svg {
  color: var(--siteBlack);
}

.buttonBlue {
  color: var(--siteWhite);
  background-color: var(--siteBlue);
}

.buttonBlue:hover {
  background-color: var(--siteLightBlue);
}

.buttonBlue svg {
  color: var(--siteWhite);
}

.headerOffset {
  padding-top: 62px;
}

.container {
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  max-width: unset;
  padding: 0;
}

.section {
  max-width: 1080px;
  padding: 0 45px;
  margin: 0 auto;
}

.greyBg {
  background-color: var(--siteGrey);
}

.centerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionDescription {
  color: var(--siteDarkGrey);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  padding: 10px 12vw;
}

.horizontalPaddingContainer {
  row-gap: 50px;
  max-width: 1440px;
  padding: 0px 45px;
  max-width: 1320px;
}

.roundedContainer {
  border-radius: 24px;
  margin-top: 50px;
  padding: 53px 45px;
  background-color: var(--siteWhite);
  display: flex;
  justify-content: center;
}

.heading {
  line-height: 70px;
  letter-spacing: -1.12px;
  color: var(--siteBlack);
}

.newHeading {
  text-align: center;
  font-family: Selecta;
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.44px;
}

h1.heading {
  font-size: 72px;
}

h2.heading {
  font-size: 56px;
}

h3.heading {
  font-size: 32px;
}

.leftAlign {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.description {
  margin: 8px 0 0 0;
  font-size: 18px;
  font-family: 'Inter';
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  color: var(--Text, #232833);
}

.description span {
  color: var(--siteBlack);
  font-family: 'Inter';
}

.description.narrow {
  max-width: 450px;
}

.centerBlock .narrow {
  max-width: 450px;
}

.centerBlock .medium {
  max-width: 650px;
}

.centerBlock span {
  display: block;
}

.logoGridWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1080px;
}

.logoGrid {
  display: flex;
  place-items: center;
  gap: 75px;
}

.sectionCardContainer {
  display: flex;
  justify-content: center;
  padding: 0 44px;
}

.sectionCard {
  background-color: var(--siteWhite);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.centerBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 44px;
}

.miniCard {
  background-color: var(--siteWhite);
  border-radius: 16px;
}

.miniCardHeader {
  padding: 40px 40px 0px 40px;
  margin-bottom: 27px;
}

.miniCardHeader h3 {
  font-size: 32px;
  line-height: 36px;
  font-style: normal;
  font-weight: 500;
  font-family: SelectaMedium;
}

.footer {
  display: flex;
  max-width: 1320px;
  margin: 0 auto 0 auto;
  padding: 32px 45px 32px;
  flex-direction: column;
}

.blackFooter {
  background-color: var(--siteBlack);
  color: var(--siteWhite);
}

.footer a {
  color: var(--siteDarkGrey);
  font-family: Selecta;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.blackFooter .footer a {
  color: var(--siteWhite);
}

.blackFooter .footerLinkHeader {
  color: var(--siteWhite);
}

.footerIconsGrid {
  display: grid;
  grid: auto / repeat(3, 1fr);
  column-gap: 36px;
}

.footerLinkGrid {
  display: grid;
  grid: auto / repeat(4, 1fr);
  column-gap: 44px;
  row-gap: 5px;
  margin-bottom: 24px;
  width: 60%;
}

.footerLinkGrid a:hover {
  color: var(--sitePurpleDark);
}

.footerLinkHeader {
  color: var(--siteDarkGrey);
  font-family: Selecta;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
}

.footerGridCenter {
  align-items: center;
}

.footerCopyright {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--composerBlack);
  font-size: 13px;
  gap: 12px;
  padding-top: 2px;
  width: 40%;
}

.copyrightText {
  color: var(--siteDarkGrey);
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.wheelhouseLink {
  text-decoration: underline;
}

.blackFooter .copyrightText {
  color: var(--siteWhite);
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}

.demoModal {
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 12px rgb(0 0 0 / 20%);
  padding: 32px;
}

.demoModal h2 {
  font-size: 24px;
}

.demoModal .inputBlock {
  margin-top: 12px;
}

.secondaryButton {
  text-transform: none;
  border-radius: 10px;
  color: #333;
  background-color: #eaeaea;
  white-space: nowrap;
}

.secondaryButton:hover {
  background-color: #dbdbdb;
}

.keyButton {
  text-transform: none;
  background-color: var(--sitePurpleDark);
  color: white;
  border-radius: 10px;
  white-space: nowrap;
}

.keyButton:hover {
  background-color: var(--sitePurple);
}

.keyButton svg {
  color: white;
}

.menuButton {
  background-color: rgba(0, 0, 0, 0);
  min-width: unset;
  margin-left: 8px;
  border-radius: 10px;
}

.menuButton:hover {
  background-color: rgba(0, 0, 0, 0);
}

.menuButton svg {
  color: var(--composerBlack);
}

.menuButtonWhite svg {
  color: var(--siteWhite);
}

.navMenu {
  position: fixed;
  left: 0px;
  top: 60px;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 18px 45px;
}

.navMenu .buttonWrap {
  padding-bottom: 18px;
  border-bottom: 1px solid #e5e5e5;
}

.navMenu button {
  width: 100%;
}

.navMenu a {
  display: block;
  padding: 14px 0;
  border-bottom: 1px solid #e5e5e5;
  color: var(--composerBlack);
}

.navMenu a:hover {
  color: var(--sitePurple);
}

.demoCategories {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 4px;
  gap: 4px;
  display: flex;
}

.demoButton {
  padding: 6px 24px;
  text-transform: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.demoButton:hover {
  background-color: #e0e0e0;
}

.demoButtonActive {
  background-color: #e0e0e0;
}

.demoUnderlineButton {
  height: 35px;
  padding: 6px 0;
  text-transform: none;
  border-radius: 0;
  min-width: unset;
  border-bottom: 1px solid white;
  color: black;
}

.demoUnderlineButton:hover {
  background-color: white;
  border-bottom: 1px solid black;
}

.demoUnderlineButtonActive {
  border-bottom: 1px solid black;
}

.demoVideos {
  display: flex;
  gap: 36px;
  align-items: center;
}

.titleCase {
  text-transform: capitalize;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: end;
}

.alignCenter {
  align-items: center;
}

.justifyBetween {
  justify-content: space-between;
}

.alignBetween {
  align-items: space-between;
}

.xHidden {
  overflow-x: hidden;
}

.flex {
  display: flex;
}

.flexCol {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.gap {
  gap: 12px;
}

.f1 {
  flex: 1;
}

.mt0 {
  margin-top: 0px;
}

.mt {
  margin-top: 8px;
}

.mt2 {
  margin-top: 16px;
}

.mt3 {
  margin-top: 36px;
}

.mt4 {
  margin-top: 48px;
}

.mt5 {
  margin-top: 60px;
}

.mt6 {
  margin-top: 72px;
}

.mt12 {
  margin-top: 144px;
}

.mb1 {
  margin-bottom: 12px;
}

.mb2 {
  margin-bottom: 24px;
}

.mb3 {
  margin-bottom: 36px;
}

.mb6 {
  margin-bottom: 72px;
}

.pt3 {
  padding-top: 36px;
}

.pt4 {
  padding-top: 48px;
}

.pt5 {
  padding-top: 60px;
}

.pt6 {
  padding-top: 72px;
}

.pb3 {
  padding-bottom: 36px;
}

.pb6 {
  padding-bottom: 72px;
}

.white {
  color: white;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.textCenter {
  text-align: center;
}

.textPurpleDark {
  color: var(--sitePurpleDark);
}

.homeScreenGradient {
  background: linear-gradient(97.15deg, #f6f6f6 0%, #e0d2f1 100%);
  width: 100%;
  height: 2000px;
  position: absolute;
  z-index: -2;
}

.homeScreenGradientFade {
  width: 100%;
  height: 2120px;
  position: absolute;
  z-index: -1;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 65%,
    rgba(245, 245, 245, 0.5) 68%,
    rgba(245, 245, 245, 1) 71%,
    rgba(245, 245, 245, 1) 98%,
    rgba(245, 245, 245, 0) 100%
  );
}

.requestDemoContainer {
  margin: 58px 44px 77px;
  width: calc(100% - 84px);
  box-sizing: border-box;
  background-color: var(--sitePurpleDark);
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding: 79px 107px;
  border-radius: 24px;
  box-shadow: 0px 73px 143px 0px rgba(116, 81, 211, 0.21), 0px 36.536px 71.57px 0px rgba(116, 81, 211, 0.16),
    0px 22.007px 43.11px 0px rgba(116, 81, 211, 0.14), 0px 14.103px 27.627px 0px rgba(116, 81, 211, 0.12),
    0px 9.141px 17.906px 0px rgba(116, 81, 211, 0.1), 0px 5.754px 11.272px 0px rgba(116, 81, 211, 0.09),
    0px 3.306px 6.476px 0px rgba(116, 81, 211, 0.07), 0px 1.455px 2.85px 0px rgba(116, 81, 211, 0.05);
}

.requestDemoText {
  max-width: 482px;
  color: var(--White, #fff);
  font-family: Selecta;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 109.375% */
  letter-spacing: -1.28px;
  text-align: start;
}

.requestDemoButton {
  color: var(--sitePurpleDark);
  gap: 14px;
  font-size: 20px;
  font-weight: 500;
  padding: 17px 34px;
  > svg {
    color: var(--sitePurpleDark);
  }
}

.caseStudyButton {
  color: var(--sitePurpleDark);
  gap: 14px;
  font-size: 20px;
  font-weight: 500;
  padding: 17px 14px 17px 14px;
  width: 289px;
  > svg {
    color: var(--sitePurpleDark);
  }
}

.demoScreen {
  padding-top: 112px;
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}

.demoScreenContainer {
  display: flex;
  justify-content: center;
  width: calc(100vw - 48px);
  align-items: center;
  background-color: white;
  max-width: 1440px;
  border-radius: 24px;
}

.demoSectionContainer {
  padding: 0 44px;
  width: calc(100% - 88px);
  gap: 22px;
  display: flex;
  flex-direction: column;
}

.demoAdFormatSelect {
  width: 100%;
  height: 43px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--Stroke, #e2e2e2);
  background: #fff;
}

.demoAdFormatSelectContainer {
  min-width: 600px;
}

.demoAdFormatSelectWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.demoCreateYourOwnContainer {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0 44px;
}

.shadow {
  height: inherit;
  width: 2%;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
}

.demoVideoContent {
  width: 100%;
  display: flex;
  justify-content: center;
}

.demoVideoContainer {
  width: 848px;
  height: 480px;
}

.demoVideoLoading {
  width: 100%;
  height: 456px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
  justify-content: center;
  align-items: center;
}

.demoScreenWrapper {
  padding-top: 64px;
  margin-bottom: 112px;
}

.headerIconDesc {
  color: var(--Text, #232833);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
}

.nielsenMetricsTitle {
  line-height: 56px;
}

.demoPlayerContainer {
  width: 80%;
}

@media (min-width: 1536px) {
  .sectionCard {
    max-width: 1448px;
  }

  .requestDemoContainer {
    max-width: 1448px;
    margin: 58px 0 77px;
  }

  .sectionCardContainer {
    width: 100%;
    padding: 0;
  }

  .cardsContainer {
    max-width: 1448px;
  }

  .centerBlock {
    padding: 0;
  }

  .demoContentWrapper {
    max-width: 1536px;
  }

  .demoScreenWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .demoCreateYourOwnContainer {
    width: 100%;
    max-width: 1536px;
  }
}

@media (max-width: 900px) {
  .demoSectionContainer {
    padding: 0 44px;
    width: calc(100% - 88px);
    gap: 22px;
  }

  .centerBlock span {
    display: inline;
  }

  .logoGridWrap {
    overflow: hidden;
    justify-content: unset;
  }

  .logoGrid::-webkit-scrollbar {
    display: none;
  }

  .logoGrid {
    display: flex;
    gap: 50px;
    overflow-x: scroll;
  }

  .logoGrid svg {
    max-width: 150px;
    min-width: 124px;
  }

  .centerBlock {
    padding: 0 18px;
  }

  .horizontalPaddingContainer {
    padding: 0 18px;
  }

  .roundedContainer {
    padding: 53px 18px;
  }

  .sectionCardContainer {
    padding: 0 25px;
  }

  .sectionCard {
    padding: 30px;
  }

  .section {
    padding: 0 18px;
  }

  .requestDemoContainer {
    padding: 79px 50px;
    gap: 23px;
    width: 100%;
    margin: 0;
  }

  .requestDemoText {
    font-size: 48px;
    line-height: 54px;
  }

  .requestDemoButton {
    font-size: 16px;
    padding: 14px 12px 14px 12px;
    min-width: 200px;
  }

  .footer {
    padding: 32px 18px 72px;
  }

  .navMenu {
    padding: 18px 24px;
  }

  .demoVideoLoading {
    height: 320px;
  }
}

@media (max-width: 800px) {
  .requestDemoContainer {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .requestDemoText {
    font-size: 40px;
    line-height: 46px;
  }

  .requestDemoButton {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .description {
    font-size: 16px;
  }

  .heading {
    line-height: normal;
    letter-spacing: -1.12px;
    color: var(--siteBlack);
  }

  h1.heading {
    font-size: 54px;
  }

  h1.newHeading {
    font-size: 54px;
  }

  h2.heading {
    font-size: 42px;
  }

  .sectionDescription {
    font-size: 14px;
    padding: 10px 4vw;
  }

  .demoAdFormatSelectContainer {
    min-width: unset;
  }

  .demoAdFormatSelectWrapper {
    align-items: unset;
  }

  .demoVideoLoading {
    height: 231px;
  }
}

@media (max-width: 600px) {
  .description {
    font-size: 14px;
  }

  h1.heading {
    font-size: 36px;
  }

  h1.newHeading {
    font-size: 48px;
  }

  h2.heading {
    font-size: 36px;
  }

  .sectionDescription {
    font-size: 12x;
  }

  .sectionCardContainer {
    padding: 0 10px;
  }

  .sectionCard {
    padding: 35px 20px;
  }

  .footerLinkGrid {
    grid: auto / repeat(3, 1fr);
    column-gap: 22px;
    row-gap: 11px;
  }

  .navMenu {
    padding: 18px;
  }
  .demoButton {
    font-size: 14px;
    min-width: 60px;
    padding: 8px 12px;
  }

  .demoUnderlineButton {
    font-size: 13px;
    padding: 4px;
  }

  .demoVideos {
    gap: 12px;
    flex-direction: column;
  }

  .requestDemoContainer {
    padding: 49px 20px;
  }

  .demoSectionContainer {
    padding: 0 24px;
    width: calc(100% - 48px);
    gap: 22px;
  }

  .demoCreateYourOwnContainer {
    padding: 0 24px;
  }

  .miniCardHeader h3 {
    font-size: 24px;
    line-height: normal;
  }

  .requestDemoText {
    text-align: center;
  }

  .demoScreenWrapper {
    margin-bottom: 64px;
  }

  .logoGrid {
    flex-wrap: wrap;
    overflow: unset;
    justify-content: center;
    row-gap: 27px;
    column-gap: 45px;
  }

  .logoGrid svg {
    min-width: unset;
  }

  video {
    border-radius: 12px !important;
  }

  img {
    border-radius: 12px !important;
  }

  .mobileBorderRadius {
    border-radius: 12px !important;
  }

  .demoPlayerContainer {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .footerLinkGrid {
    grid: auto / repeat(2, 1fr);
    column-gap: 22px;
    row-gap: 11px;
  }

  .demoVideoLoading {
    height: 165px;
  }
}
