import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import classNames from 'classnames';
import RequestDemoBanner from './request-demo-banner';

import styles from './site.module.css';
import pressStyles from './press.module.css';

const ARTICLES_LINK = 'https://tl-open-content.s3.amazonaws.com/web-assets/articles.json';

const Press = () => {
  const [articles, setArticles] = useState([]);

  const fetchArticles = async () => {
    const response = await fetch(ARTICLES_LINK);
    if (response.status !== 200) return;

    const jsonBody = await response.json();
    setArticles(jsonBody);
  };

  const renderArticles = () =>
    articles.map((article) => {
      const { title, link, favIcon, publicationName } = article;
      return (
        <div className={pressStyles.articleContainer} key={title}>
          <div className={pressStyles.iconContainer}>
            <img src={favIcon} className={pressStyles.favIcon} alt={title} />
            <div className={pressStyles.publicationName}>{publicationName}</div>
          </div>
          <a href={link} className={pressStyles.articleTitle} target="_blank" rel="noreferrer">
            {title}
          </a>
          <a href={link} className={pressStyles.articleLink} target="_blank" rel="noreferrer">
            Learn More
          </a>
        </div>
      );
    });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    fetchArticles();
  }, []);

  return (
    <div>
      <Container maxWidth="xl" className={classNames(styles.container)}>
        <div className={styles.centerContainer}>
          <section className={classNames(styles.flex, styles.flexCol, styles.horizontalPaddingContainer)}>
            <div className={styles.headerOffset}>
              <div className={pressStyles.pressContainer}>
                <h2 className={styles.heading}>Press</h2>
                <div className={pressStyles.articlesContainer}>{renderArticles()}</div>
              </div>
            </div>
          </section>
        </div>

        <div className={classNames(styles.mt3, styles.mb3, styles.centerBlock)}>
          <RequestDemoBanner />
        </div>
      </Container>
    </div>
  );
};

export default Press;
