/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { Button, TextField } from '@mui/material';
import { sendPasswordResetEmail } from '../../services/password-reset-service';
import styles from './login.module.css';
import LoginHeader from './login-header';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleForgotPassword = (event) => {
    event.preventDefault();

    // We don't care what the result is. If there's an error because the email
    // doesn't exist, we don't want to give the evil hackers any clues about what
    // email addresses exist in our db.
    sendPasswordResetEmail(email)
      .then(() => {
        setIsSubmitted(true);
      })
      .catch(() => {});
  };

  const title = isSubmitted ? 'Email Sent' : 'Forgot Password';

  return (
    <div className={styles.formContainer}>
      <LoginHeader headingText={title} />
      {!isSubmitted && (
        <form onSubmit={handleForgotPassword}>
          <p>Enter your email address and we will send you a link to reset your password.</p>

          <label htmlFor="email" className={styles.textFieldLabel}>
            Email
          </label>
          <TextField
            className={styles.textField}
            size="small"
            autoComplete="on"
            type="email"
            id="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />

          <Button type="submit" className={styles.button}>
            Send Password Reset
          </Button>
          <div className={styles.backLink}>
            <a href="/login">Back to Log In</a>
          </div>
        </form>
      )}
      {isSubmitted && (
        <>
          <p>
            Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your
            spam folder.
          </p>
          <Button
            type="submit"
            className={styles.secondaryButton}
            onClick={() => {
              window.location.pathname = '/login';
            }}
          >
            Back to Login
          </Button>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
