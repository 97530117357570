export const studioTopNavHeight = '64px';
export const studioMenubarHeight = '64px';
export const studioMenubarRightLeftPadding = '20px';
export const studioSidebarWidth = '100px';
export const studioExSidebarWidth = '400px';
export const studioExSidebarExpandedWidth = `calc(100vw - ${studioSidebarWidth} - 40%)`;

export const studioBorderColor = '#E5E5E5';
export const studioPrimaryColor = '#5A24FB';
export const studioPrimaryColor100 = '#865efc';
export const studioSecondaryColor = '#EFEFEF';

export const studioNavy100 = '#515c73';
export const studioNavy200 = '#3e4a64';
export const studioNavy300 = '#2F384C';

export const studioBaseGray = '#f0f1f4';
export const studioDarkGray = '#5E5E5E';
export const studioBgGray = '#eff1f4';
export const studioTransparentGray = 'rgba(30, 30, 30, 0.32)';

export const studioMobileBreakPoint = '480px';

export const defaultStudioPresetColors = [
  '#d0021b',
  '#f5a623',
  '#f8e71c',
  '#8b572a',
  '#7ed321',
  '#417505',
  '#bd10e0',
  '#9013fe',
  '#4a90e2',
  '#50e3c2',
  '#b8e986',
  '#000000',
  '#4a4a4a',
  '#9b9b9b',
  '#ffffff',
];

export const defaultStudioGreyscaleColors = ['#000000', '#646464', '#ffffff'];
