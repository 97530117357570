.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box {
  width: 100%;
  box-sizing: border-box;
}

.body {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  padding: 0 10vw;
  width: calc(100% - 10vw);
  flex-wrap: wrap;
  gap: 12vw;
}

.addressText {
  color: #232833;
  font-family: Selecta;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

h3 {
  color: #232833;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.interFont {
  font-family: Inter;
}

.form {
  width: 35vw;
  min-width: 250px;
  max-width: 450px;
}
