import React from 'react';
import { matchPath, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';

import { defaultEncodeArgs, steps } from './constants';
import Stepper from './stepper';

import styles from '../site.module.css';
import demoStyles from './extension-demo.module.css';

const ExtensionDemo = () => {
  const [encodeArgs, setEncodeArgs] = React.useState(defaultEncodeArgs);
  const { pathname } = useLocation();

  const isUnsupportedScreenSize = useMediaQuery('(max-width:1080px)');
  const isUnsupportedDeviceRoute = matchPath(
    {
      path: '/demo/create-your-own/unsupported-device',
      end: true,
    },
    pathname
  );

  return isUnsupportedScreenSize && !isUnsupportedDeviceRoute ? (
    <Navigate to="unsupported-device" />
  ) : (
    <div className={demoStyles.extensionDemoScreen} style={{ paddingTop: 0 }}>
      <section
        className={classNames(styles.flex, styles.flexCol, demoStyles.extensionDemoLandingContainer, styles.mb3)}
      >
        <div
          className={classNames(styles.flex, styles.flexCol, styles.fullHeight, styles.fullWidth, styles.alignCenter)}
          style={{
            rowGap: 28,
          }}
        >
          <div className={classNames(styles.flex, styles.flexCol)} style={{ gap: 22 }}>
            {isUnsupportedDeviceRoute ? null : (
              <div className={demoStyles.stepperContainer}>
                <Stepper encodeArgs={encodeArgs} steps={steps} />
              </div>
            )}
          </div>
          <Outlet context={[encodeArgs, setEncodeArgs]} />
        </div>
      </section>
    </div>
  );
};

ExtensionDemo.defaultProps = {};

ExtensionDemo.propTypes = {};

export default ExtensionDemo;
