import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import classNames from 'classnames';

import styles from './site.module.css';
import requestDemoStyles from './request-demo.module.css';
import RequestDemoForm from './request-demo-form';

const RequestDemo = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div>
      <Container maxWidth="xl" className={classNames(styles.container)}>
        <div className={requestDemoStyles.center}>
          <section
            className={classNames(
              styles.flex,
              styles.flexCol,
              styles.horizontalPaddingContainer,
              requestDemoStyles.box
            )}
          >
            <div className={styles.headerOffset}>
              <div className={classNames(styles.gap, styles.roundedContainer)}>
                <div className={classNames(styles.flex, styles.flexCol, styles.gap, styles.alignCenter)}>
                  <h2 className={styles.heading}>Request A Demo</h2>
                  <div className={requestDemoStyles.body}>
                    <div className={requestDemoStyles.interFont} style={{ minWidth: 250 }}>
                      <h3 className={requestDemoStyles.title}>Address</h3>
                      <br />
                      <div className={requestDemoStyles.addressText}>
                        230 NW 24th Street
                        <br />
                        Suite 402
                        <br />
                        Miami, Florida 33127
                      </div>
                      <br />
                      <br />
                      <h3>Inquiries</h3>
                      <br />
                      <a
                        className={requestDemoStyles.addressText}
                        href="mailto:contact@transmit.live"
                        target="_blank"
                        rel="noreferrer"
                      >
                        contact@transmit.live
                      </a>
                      <br />
                    </div>
                    <div className={requestDemoStyles.form}>
                      <RequestDemoForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Container>
    </div>
  );
};

export default RequestDemo;
