import React from 'react';
import { Button, useMediaQuery } from '@mui/material';
import classNames from 'classnames';

import Formats from './formats';
import styles from '../site.module.css';
import ComposerIcon from '../svg/composer-icon';
import homeStyles from './home.module.css';

const shadowEnd = 'rgba(205, 205, 205, 0)';
const shadowStart = 'rgba(205, 205, 205, .4)';

const useShadowProps = () => {
  const sm = useMediaQuery('(max-width: 600px)');
  const lg = useMediaQuery('(min-width: 900px)');
  const md = useMediaQuery('(min-width: 700px)');

  if (sm) {
    return {
      height: 362,
      leftShadowStyleProps: { left: 0 },
      rightShadowStyleProps: { right: 0 },
    };
  }
  if (lg) {
    return {
      height: 402,
      leftShadowStyleProps: { left: 84 },
      rightShadowStyleProps: { right: 84 },
    };
  }
  if (md) {
    return {
      height: 402,
      leftShadowStyleProps: { left: 73 },
      rightShadowStyleProps: { right: 73 },
    };
  }

  return {
    height: 376,
    leftShadowStyleProps: { left: 73 },
    rightShadowStyleProps: { right: 73 },
  };
};

const ComposerCard = React.memo(() => {
  const [leftShadow, setLeftShadow] = React.useState(false);
  const [rightShadow, setRightShadow] = React.useState(true);
  const formatsRef = React.useRef(null);

  const showShadow = useMediaQuery('(max-width: 1536px)');
  const { height, leftShadowStyleProps, rightShadowStyleProps } = useShadowProps();

  // eslint-disable-next-line
  React.useEffect(() => {
    if (formatsRef?.current) {
      const onScroll = (e) => {
        const scrollWidthEnd = e.target.scrollLeft + e.target.offsetWidth === e.target.scrollWidth;

        if (e.target.scrollLeft === 0) {
          setLeftShadow(false);
        } else {
          setLeftShadow(true);
        }

        if (scrollWidthEnd) {
          setRightShadow(false);
        } else {
          setRightShadow(true);
        }
      };
      formatsRef.current.addEventListener('scroll', onScroll);

      return () => {
        if (formatsRef?.current) {
          formatsRef?.current.removeEventListener('scroll', onScroll);
        }
      };
    }
  }, [formatsRef.current]);

  return (
    <div className={classNames(styles.sectionCard, homeStyles.composerCard)}>
      <div className={homeStyles.composerCardHeader}>
        <div className={classNames(styles.leftAlign, homeStyles.composerCardHeaderHeadingContainer)}>
          <ComposerIcon />
          <div className={styles.mt2} style={{ marginLeft: 3 }}>
            Stream Composer
          </div>
          <h2 className={classNames(styles.heading, styles.center, homeStyles.composerCardHeaderText)}>
            Deliver <span>personalized</span> creatives at scale
          </h2>
          <p className={classNames(styles.description, homeStyles.cardsDesc)}>
            Expand advertising inventory,
            <br />
            engage audiences and propel growth.
          </p>
          <div className={styles.mt2}>
            <Button className={classNames(styles.button, styles.buttonGreen)} href="/stream-composer">
              Explore Stream Composer
            </Button>
          </div>
        </div>

        <video
          className={homeStyles.composerCardHeaderVideo}
          src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/nba-pip.mp4"
          width="100%"
          height="100%"
          loop
          muted
          playsInline
          autoPlay
        />
      </div>

      <div className={homeStyles.composerMiniCards}>
        <div className={classNames(styles.miniCard, homeStyles.viewingExperienceCard, styles.mobileBorderRadius)}>
          <div className={styles.miniCardHeader}>
            <h3>
              Viewing experience <span>uninterrupted</span>
            </h3>
            <div className={classNames(styles.description, homeStyles.cardsDesc)}>
              Display personalized creatives without commercial breaks.
            </div>
          </div>

          <div
            className={classNames(
              homeStyles.viewingExperienceImgContainer,
              homeStyles.miniCardImgContainer,
              homeStyles.mobileMiniCard
            )}
          >
            <img
              className={classNames(homeStyles.viewingExperienceImg, homeStyles.miniCardImg)}
              src="https://tl-open-content.s3.amazonaws.com/web-assets/sc_card-viewing-experience.jpeg"
              alt="pip ad"
            />
          </div>
        </div>

        <div className={classNames(styles.miniCard, homeStyles.brandExperienceCard, styles.mobileBorderRadius)}>
          <div className={classNames(styles.miniCardHeader, styles.flex, styles.flexCol)}>
            <h3 className={homeStyles.brandExperienceCardTitle}>
              <span>Brand</span> the experience
            </h3>
            <div className={classNames(styles.description, homeStyles.cardsDesc)} style={{ maxWidth: 287 }}>
              Showcase brand assets during highest viewer engagement.
            </div>
          </div>

          <div
            className={classNames(
              homeStyles.brandExperienceImgContainer,
              homeStyles.miniCardImgContainer,
              homeStyles.mobileMiniCard
            )}
          >
            <img
              className={classNames(homeStyles.brandExperienceImg, homeStyles.miniCardImg)}
              src="https://tl-open-content.s3.amazonaws.com/web-assets/sc_card-brand-experience.png"
              alt="squeeze back ad"
            />
          </div>
        </div>

        <div className={classNames(styles.miniCard, homeStyles.spotlightCard, styles.mobileBorderRadius)}>
          <div className={classNames(styles.miniCardHeader, homeStyles.spotlightHeader)}>
            <h3 className={homeStyles.spotlightCardTitle}>
              Spotlight
              <br />
              <span>pivotal moments</span>
            </h3>
            <div className={classNames(styles.description, homeStyles.cardsDesc)} style={{ maxWidth: 319 }}>
              Create inventory using real time in-game event analysis.
            </div>
          </div>

          <div className={classNames(homeStyles.miniCardImgContainer, homeStyles.mobileMiniCard)}>
            <img
              className={classNames(homeStyles.spotlightImg, homeStyles.miniCardImg)}
              src="https://tl-open-content.s3.amazonaws.com/web-assets/sc_card-spotlight.png"
              alt="football headcoach"
            />
          </div>
        </div>
      </div>

      <div className={classNames(styles.mt6, styles.flex, styles.flexCol)}>
        <h2 className={classNames(styles.heading, styles.textCenter)}>Unique creative ad formats</h2>
      </div>
      <div className={classNames(styles.flex, styles.flexCol, homeStyles.adFormatsContainer)} ref={formatsRef}>
        {showShadow && rightShadow ? (
          <div
            className={styles.shadow}
            style={{
              ...rightShadowStyleProps,
              background: `linear-gradient(to right, ${shadowEnd} 0%, ${shadowStart} 100%)`,
              height,
            }}
          />
        ) : null}
        {showShadow && leftShadow ? (
          <div
            className={styles.shadow}
            style={{
              ...leftShadowStyleProps,
              background: `linear-gradient(to left, ${shadowEnd} 0%, ${shadowStart} 100%)`,
              height,
            }}
          />
        ) : null}
        <Formats />
      </div>
    </div>
  );
});

export default ComposerCard;
