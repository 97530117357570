/* eslint-disable max-len */
import React from 'react';

const NielsenLogo = React.memo(() => (
  <svg width="165" height="25" viewBox="0 0 166 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2393_729)">
      <path
        d="M30.3284 4.44826V14.6232C30.33 14.7294 30.3591 14.8334 30.4129 14.9249C30.4667 15.0164 30.5434 15.0922 30.6354 15.1452C30.7274 15.1981 30.8316 15.2262 30.9377 15.2267C31.0439 15.2274 31.1484 15.2002 31.2409 15.1482L39.9909 10.0608C40.0815 10.0063 40.1565 9.92936 40.2085 9.83736C40.2606 9.74536 40.2879 9.64147 40.2879 9.53577C40.2879 9.43007 40.2606 9.32618 40.2085 9.23418C40.1565 9.14218 40.0815 9.06523 39.9909 9.0108L31.2409 3.92328C31.1484 3.87128 31.0439 3.8442 30.9377 3.84473C30.8316 3.84527 30.7274 3.8734 30.6354 3.92633C30.5434 3.97927 30.4667 4.05521 30.4129 4.14671C30.3591 4.23821 30.33 4.34212 30.3284 4.44826Z"
        fill="#782EFF"
      />
      <path
        d="M10.6519 19.7734V9.6108C10.6542 9.50316 10.6275 9.39691 10.5746 9.30315C10.5217 9.20938 10.4445 9.13154 10.3512 9.07785C10.2579 9.02416 10.1518 8.99658 10.0442 8.99798C9.93652 8.99938 9.83123 9.02973 9.73934 9.08583L0.926857 14.1609C0.836414 14.2169 0.761787 14.2951 0.71003 14.3881C0.658272 14.481 0.631104 14.5856 0.631104 14.6921C0.631104 14.7985 0.658272 14.9031 0.71003 14.9961C0.761787 15.0891 0.836414 15.1672 0.926857 15.2234L9.73934 20.2984C9.83123 20.3544 9.93652 20.3847 10.0442 20.3861C10.1518 20.3875 10.2579 20.36 10.3512 20.3062C10.4445 20.2526 10.5217 20.1747 10.5746 20.081C10.6275 19.9872 10.6542 19.881 10.6519 19.7734Z"
        fill="#F40953"
      />
      <path
        d="M15.4651 13.0234V23.1859C15.4666 23.292 15.4957 23.3959 15.5496 23.4874C15.6033 23.5789 15.6801 23.6549 15.7721 23.7077C15.8641 23.7607 15.9682 23.7889 16.0745 23.7894C16.1806 23.7899 16.285 23.7629 16.3776 23.7109L25.1276 18.6234C25.2213 18.5715 25.2996 18.4954 25.354 18.403C25.4083 18.3107 25.4371 18.2055 25.4371 18.0984C25.4371 17.9911 25.4083 17.886 25.354 17.7936C25.2996 17.7012 25.2213 17.6252 25.1276 17.5734L16.3776 12.4859C16.2841 12.4333 16.1785 12.4063 16.0713 12.4074C15.9641 12.4085 15.8591 12.4377 15.7667 12.4921C15.6743 12.5465 15.5978 12.6242 15.545 12.7174C15.492 12.8106 15.4645 12.9161 15.4651 13.0234Z"
        fill="#FF9408"
      />
      <path
        d="M25.5133 11.1857V1.02316C25.5157 0.915524 25.489 0.809238 25.4361 0.715468C25.3832 0.621696 25.306 0.5439 25.2127 0.490209C25.1193 0.436519 25.0133 0.40893 24.9057 0.410329C24.7981 0.411729 24.6927 0.442083 24.6008 0.498181L15.8508 5.57318C15.7573 5.62683 15.6797 5.70421 15.6257 5.79748C15.5717 5.89076 15.5432 5.99662 15.5432 6.10442C15.5432 6.21221 15.5717 6.31811 15.6257 6.41138C15.6797 6.50465 15.7573 6.58201 15.8508 6.63564L24.6008 11.7107C24.6927 11.7668 24.7981 11.7971 24.9057 11.7985C25.0133 11.7999 25.1193 11.7723 25.2127 11.7186C25.306 11.6649 25.3832 11.5871 25.4361 11.4934C25.489 11.3996 25.5157 11.2933 25.5133 11.1857Z"
        fill="#39C4C3"
      />
      <path
        d="M80.5256 23.8104H77.9006C77.7183 23.8104 77.5435 23.7381 77.4145 23.6091C77.2856 23.4802 77.2131 23.3053 77.2131 23.1229V7.52297C77.2131 7.34064 77.2856 7.16576 77.4145 7.03682C77.5435 6.9079 77.7183 6.83545 77.9006 6.83545H80.5256C80.7059 6.83872 80.8775 6.9126 81.0038 7.04117C81.13 7.16975 81.2008 7.34279 81.2006 7.52297V23.1229C81.2008 23.3032 81.13 23.4762 81.0038 23.6047C80.8775 23.7333 80.7059 23.8072 80.5256 23.8104Z"
        fill="#00234A"
      />
      <path
        d="M93.1522 4.86035C98.8897 4.86035 102.14 8.93536 102.14 14.6353V15.0978C102.14 15.1882 102.122 15.2776 102.087 15.3609C102.053 15.4443 102.002 15.5202 101.938 15.5841C101.874 15.6478 101.799 15.6984 101.715 15.7331C101.632 15.7676 101.542 15.7853 101.452 15.7853H88.2897C88.2396 15.7844 88.1899 15.7948 88.1443 15.8156C88.0987 15.8362 88.0582 15.8668 88.0258 15.9052C87.9936 15.9434 87.9702 15.9884 87.9574 16.0369C87.9447 16.0854 87.9428 16.1361 87.9522 16.1853C88.1843 17.4503 88.8697 18.5873 89.8798 19.3833C90.8898 20.1794 92.1555 20.5801 93.4397 20.5103C95.252 20.5186 97.0058 19.8702 98.3772 18.6853C98.4458 18.6258 98.5257 18.5804 98.6121 18.5518C98.6985 18.5233 98.7896 18.5123 98.8803 18.5193C98.971 18.5262 99.0595 18.5512 99.1405 18.5926C99.2215 18.6339 99.2933 18.6909 99.3522 18.7603L100.877 20.5978C100.991 20.7316 101.047 20.9043 101.036 21.0793C101.024 21.2542 100.945 21.4178 100.815 21.5353C98.8158 23.3612 96.1831 24.3344 93.4772 24.2478C87.5522 24.2478 83.8147 20.3353 83.8147 14.6353C83.8147 9.19786 87.4022 4.86035 93.1522 4.86035ZM93.0772 8.42286C91.908 8.33434 90.7477 8.68491 89.8231 9.4061C88.8984 10.1273 88.276 11.1673 88.0772 12.3229C88.0661 12.3736 88.0663 12.4262 88.0781 12.4768C88.0898 12.5274 88.1127 12.5748 88.145 12.6155C88.1773 12.6562 88.2182 12.6892 88.265 12.7121C88.3116 12.735 88.3627 12.7472 88.4147 12.7479H97.4022C97.4523 12.7487 97.502 12.7384 97.5476 12.7177C97.5932 12.697 97.6336 12.6663 97.6659 12.628C97.6983 12.5897 97.7217 12.5447 97.7345 12.4963C97.7472 12.4478 97.7489 12.3971 97.7397 12.3478C97.6318 11.2038 97.0757 10.149 96.1928 9.41358C95.3098 8.67819 94.1718 8.32204 93.0272 8.42286H93.0772Z"
        fill="#00234A"
      />
      <path
        d="M137.291 4.86035C143.028 4.86035 146.278 8.93536 146.278 14.6353V15.0978C146.278 15.2802 146.206 15.4551 146.077 15.5841C145.948 15.7129 145.773 15.7853 145.591 15.7853H132.478C132.427 15.7826 132.376 15.7914 132.328 15.8113C132.282 15.8312 132.24 15.8616 132.206 15.9001C132.172 15.9387 132.147 15.9843 132.135 16.0338C132.121 16.0832 132.118 16.1351 132.128 16.1853C132.366 17.4501 133.055 18.5857 134.066 19.3812C135.077 20.1766 136.343 20.5778 137.628 20.5103C139.441 20.5186 141.195 19.8702 142.566 18.6853C142.633 18.6261 142.712 18.5809 142.797 18.5527C142.883 18.5243 142.973 18.5133 143.063 18.5203C143.152 18.5273 143.24 18.5521 143.32 18.5933C143.4 18.6346 143.471 18.6913 143.528 18.7603L145.066 20.5978C145.187 20.7237 145.255 20.8917 145.255 21.0666C145.255 21.2414 145.187 21.4096 145.066 21.5353C143.063 23.3631 140.426 24.3363 137.716 24.2478C131.803 24.2478 128.066 20.3353 128.066 14.6353C128.003 9.19786 131.591 4.86035 137.291 4.86035ZM137.216 8.42286C136.047 8.33434 134.886 8.68491 133.962 9.4061C133.037 10.1273 132.415 11.1673 132.216 12.3229C132.202 12.3728 132.201 12.4251 132.211 12.4759C132.221 12.5266 132.242 12.5743 132.273 12.6154C132.305 12.6564 132.346 12.6898 132.392 12.7127C132.438 12.7357 132.49 12.7477 132.541 12.7479H141.528C141.578 12.7484 141.628 12.738 141.675 12.7173C141.721 12.6967 141.762 12.6663 141.795 12.6283C141.828 12.5903 141.853 12.5456 141.867 12.4972C141.882 12.4488 141.886 12.3978 141.878 12.3478C141.77 11.2132 141.221 10.1664 140.35 9.43234C139.477 8.69834 138.352 8.33585 137.216 8.42286Z"
        fill="#00234A"
      />
      <path
        d="M108.152 23.8106H105.464C105.374 23.8106 105.284 23.7929 105.201 23.7584C105.118 23.7237 105.042 23.6731 104.978 23.6092C104.914 23.5455 104.863 23.4696 104.829 23.3862C104.794 23.3029 104.777 23.2134 104.777 23.1231V1.18565C104.777 1.09537 104.794 1.00597 104.829 0.922554C104.863 0.839143 104.914 0.763349 104.978 0.699509C105.042 0.635669 105.118 0.585019 105.201 0.550469C105.284 0.515919 105.374 0.498169 105.464 0.498169H108.152C108.334 0.498169 108.509 0.570578 108.638 0.699509C108.767 0.82844 108.839 1.00332 108.839 1.18565V23.1481C108.833 23.3261 108.757 23.4946 108.629 23.6182C108.501 23.7417 108.33 23.8107 108.152 23.8106Z"
        fill="#00234A"
      />
      <path
        d="M118.802 4.86112C120.094 4.78501 121.386 5.00308 122.581 5.49893C123.777 5.99478 124.844 6.75546 125.702 7.72362C125.761 7.79312 125.806 7.87361 125.834 7.96044C125.861 8.04727 125.871 8.13871 125.862 8.22942C125.855 8.32013 125.829 8.40831 125.786 8.48886C125.744 8.56939 125.685 8.64072 125.615 8.69862L123.727 10.2736C123.602 10.3914 123.437 10.457 123.265 10.457C123.093 10.457 122.927 10.3914 122.802 10.2736C122.239 9.71187 121.567 9.27044 120.828 8.97607C120.089 8.68169 119.298 8.54052 118.502 8.56111C116.652 8.56111 115.515 9.23612 115.515 10.4111C115.515 11.5861 116.765 11.9736 119.877 12.7111C123.627 13.5987 126.127 14.6737 126.127 18.4112C126.127 22.1487 123.39 24.2862 118.627 24.2862C117.141 24.343 115.659 24.0775 114.285 23.5079C112.91 22.9383 111.675 22.078 110.665 20.9862C110.563 20.8459 110.516 20.6734 110.532 20.5008C110.548 20.3283 110.626 20.1675 110.752 20.0487L112.615 18.3985C112.753 18.2799 112.933 18.2199 113.115 18.2317C113.297 18.2433 113.467 18.3257 113.59 18.4612C114.265 19.1949 115.093 19.772 116.015 20.152C116.937 20.532 117.931 20.7058 118.927 20.661C120.89 20.661 122.065 19.9987 122.065 18.6237C122.065 17.2487 120.927 16.9612 117.965 16.2987C114.777 15.5987 111.415 14.4862 111.415 10.5611C111.44 7.01111 114.252 4.86112 118.802 4.86112Z"
        fill="#00234A"
      />
      <path
        d="M149.587 5.31075H152.087C152.27 5.31075 152.445 5.38319 152.573 5.51213C152.702 5.64105 152.775 5.81593 152.775 5.99827V7.08577C152.778 7.15525 152.803 7.22207 152.845 7.27768C152.887 7.33329 152.945 7.37515 153.01 7.39795C153.076 7.42077 153.147 7.42347 153.215 7.4057C153.281 7.38794 153.342 7.35057 153.387 7.29828C154.062 6.50759 154.905 5.87815 155.855 5.45632C156.805 5.03448 157.836 4.83098 158.875 4.86077C163.137 4.86077 165.575 7.63578 165.575 12.4483V23.1232C165.575 23.3056 165.503 23.4805 165.373 23.6094C165.245 23.7384 165.07 23.8107 164.887 23.8107H162.187C162.006 23.8107 161.831 23.7384 161.702 23.6094C161.572 23.4805 161.5 23.3056 161.5 23.1232V13.1232C161.5 10.2107 160.1 8.61077 157.475 8.61077C154.475 8.61077 152.887 10.8358 152.887 15.0482V23.0982C152.887 23.2806 152.815 23.4555 152.686 23.5844C152.557 23.7134 152.382 23.7857 152.2 23.7857H149.575C149.393 23.7857 149.218 23.7134 149.09 23.5844C148.96 23.4555 148.887 23.2806 148.887 23.0982V5.97324C148.895 5.79309 148.971 5.62273 149.102 5.49882C149.233 5.3749 149.407 5.30735 149.587 5.31075Z"
        fill="#00234A"
      />
      <path
        d="M76.7167 2.76075C76.7167 2.2663 76.8634 1.78294 77.1381 1.37183C77.4127 0.960702 77.8032 0.640253 78.26 0.451033C78.7169 0.261815 79.2195 0.212321 79.7045 0.308784C80.1894 0.405247 80.6349 0.643357 80.9845 0.992988C81.3341 1.34262 81.5722 1.78805 81.6687 2.273C81.7651 2.75797 81.7156 3.26063 81.5264 3.71744C81.3372 4.17425 81.0167 4.56472 80.6056 4.83943C80.1945 5.11413 79.7112 5.26075 79.2167 5.26075C78.8851 5.27302 78.5546 5.21673 78.2457 5.09543C77.9369 4.97413 77.6564 4.79044 77.4217 4.5558C77.1871 4.32117 77.0034 4.04065 76.882 3.73179C76.7607 3.42293 76.7045 3.09235 76.7167 2.76075Z"
        fill="#00234A"
      />
      <path
        d="M51.0505 0.473145H53.863C54.0581 0.473585 54.2506 0.516268 54.4276 0.598267C54.6046 0.680266 54.7616 0.799638 54.888 0.948151L68.763 16.7856C68.8075 16.8409 68.8684 16.8806 68.9369 16.8994C69.0053 16.9181 69.0779 16.9147 69.1443 16.8899C69.2108 16.865 69.2676 16.8197 69.3069 16.7606C69.3461 16.7015 69.3658 16.6315 69.363 16.5606V1.18565C69.363 1.00332 69.4355 0.82844 69.5644 0.699508C69.6934 0.570577 69.8681 0.49817 70.0505 0.49817H72.9005C73.0829 0.49817 73.2578 0.570577 73.3866 0.699508C73.5156 0.82844 73.588 1.00332 73.588 1.18565V23.1481C73.588 23.3305 73.5156 23.5054 73.3866 23.6344C73.2578 23.7632 73.0829 23.8356 72.9005 23.8356H70.013C69.818 23.8352 69.6254 23.7925 69.4485 23.7105C69.2715 23.6285 69.1145 23.5091 68.988 23.3606L55.1755 7.56067C55.131 7.50542 55.0703 7.46562 55.0018 7.4469C54.9334 7.4282 54.8608 7.43153 54.7943 7.45644C54.7279 7.48135 54.671 7.52659 54.6316 7.58568C54.5924 7.64478 54.5728 7.71475 54.5755 7.78565V23.1481C54.5755 23.3305 54.5031 23.5054 54.3741 23.6344C54.2453 23.7632 54.0704 23.8356 53.888 23.8356H51.0505C50.8683 23.8356 50.6934 23.7632 50.5644 23.6344C50.4355 23.5054 50.363 23.3305 50.363 23.1481V1.18565C50.3596 1.09329 50.375 1.0012 50.408 0.914887C50.441 0.828568 50.4911 0.74977 50.5554 0.683258C50.6195 0.616746 50.6965 0.563873 50.7815 0.527771C50.8666 0.491668 50.9581 0.473083 51.0505 0.473145Z"
        fill="#00234A"
      />
    </g>
    <defs>
      <clipPath id="clip0_2393_729">
        <rect width="165" height="25" fill="white" transform="translate(0.631104 0.260742)" />
      </clipPath>
    </defs>
  </svg>
));

export default NielsenLogo;
