import PropTypes from 'prop-types';

import PipIcon from './svg/pip-icon';
import LbarIcon from './svg/lbar-icon';
import OverlayIcon from './svg/overlay-icon';
import { ENCODE_STATUS } from '../../creative-studio/preview/studio-preview-helper';
import { adFormats } from '../../../utils/content-stream-monitoring-utils';

// ELEMENT IDs
export const CONTENT_BUTTON_ID = 'content_button_id';
export const AD_SAMPLE_BUTTON_ID = 'ad_sample_button_id';
export const AD_BUTTON_ID = 'ad_button_id';
export const BACKGROUND_SKIN_SAMPLE_BUTTON_ID = 'background_skin_sample_button_id';
export const BACKGROUND_SKIN_BUTTON_ID = 'background_skin_button_id';
export const OVERLAY_SAMPLE_BUTTON_ID = 'overlay_sample_button_id';
export const OVERLAY_BUTTON_ID = 'overlay_button_id';
export const DELETE_BACKGROUND_SKIN_BUTTON_ID = 'delete_skin_button_id';
export const DELETE_AD_BUTTON_ID = 'delete_ad_button_id';
export const DELETE_OVERLAY_BUTTON_ID = 'delete_overlay_button_id';

// SAMPLE ENTITIES
export const SAMPLE_AD_URL = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4';
export const SAMPLE_BACKGROUND_SKIN =
  'https://t4.ftcdn.net/jpg/01/66/63/45/360_F_166634519_clNap8GGhEKsHKNxvgpNncpBFcc2fen7.jpg';
export const SAMPLE_OVERLAY =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png';

export const MIN_INSERTION_BOUNDARY = 5;
export const getMaxInsertionBoundary = ({ contentDuration, adDuration }) => {
  return contentDuration - (adDuration + 5);
};

export const AcceptedFileTypes = Object.freeze({
  [BACKGROUND_SKIN_BUTTON_ID]: {
    'image/jpg': true,
    'image/jpeg': true,
    'image/png': true,
    'image/gif': true,
  },
  [AD_BUTTON_ID]: {
    'video/mp4': true,
  },
  [OVERLAY_BUTTON_ID]: {
    'image/jpg': true,
    'image/jpeg': true,
    'image/png': true,
    'image/gif': true,
  },
  [CONTENT_BUTTON_ID]: {
    'video/mp4': true,
  },
});

/**
 * TODO:
 * use adFormats instead here
 */
export const DemoAdFormatsProps = Object.freeze({
  [adFormats.pip]: {
    icon: PipIcon,
    label: 'Picture-In-Picture',
  },
  [adFormats.squeezeBack]: {
    icon: LbarIcon,
    label: 'L-Bar',
  },
  [adFormats.overlay]: {
    icon: OverlayIcon,
    label: 'Overlay',
  },
});

export const defaultAdArgs = {
  adUrl: null,
  adDuration: null,
  skin: null,
  overlay: null,
  adFormat: adFormats.pip,
};

export const defaultEncodeArgs = {
  contentUrl: null,
  contentDuration: null,
  contentWidth: 0,
  contentHeight: 0,
  insertionPoint: null,
  encodeUrl: null,
  encodeStatus: ENCODE_STATUS.none,
  email: null,
  ...defaultAdArgs,
};

const ExtensionDemoPaths = [
  '/demo/create-your-own/upload-content',
  '/demo/create-your-own/choose-format',
  '/demo/create-your-own/select-insertion',
  '/demo/create-your-own/share',
];

export const ExtensionDemoStepsMap = Object.freeze({
  UPLOAD_CONTENT: ExtensionDemoPaths[0],
  CHOOSE_FORMAT: ExtensionDemoPaths[1],
  INSERTION_POINT: ExtensionDemoPaths[2],
  COMPLETE: ExtensionDemoPaths[3],
});

export const steps = Object.freeze({
  [ExtensionDemoStepsMap.UPLOAD_CONTENT]: 'Content',
  [ExtensionDemoStepsMap.CHOOSE_FORMAT]: 'Ad Format',
  [ExtensionDemoStepsMap.INSERTION_POINT]: 'Insertion Point',
  [ExtensionDemoStepsMap.COMPLETE]: 'Share',
});

export const stepsMinRequirements = Object.freeze({
  [ExtensionDemoStepsMap.UPLOAD_CONTENT]: ['contentUrl'],
  [ExtensionDemoStepsMap.CHOOSE_FORMAT]: {
    [adFormats.pip]: ['adUrl'],
    [adFormats.squeezeBack]: ['skin'],
    [adFormats.overlay]: ['overlay'],
  },
  [ExtensionDemoStepsMap.INSERTION_POINT]: ['insertionPoint'],
});

// PROP TYPES
export const encodeArgsType = PropTypes.shape({
  contentUrl: PropTypes.string,
  contentDuration: PropTypes.number,
  contentWidth: PropTypes.number,
  contentHeight: PropTypes.number,
  insertionPoint: PropTypes.number,
  encodeUrl: PropTypes.string,
  encodeStatus: PropTypes.string,
  email: PropTypes.string,
  adUrl: PropTypes.string,
  adDuration: PropTypes.number,
  skin: PropTypes.string,
  overlay: PropTypes.string,
  adFormat: PropTypes.oneOf(Object.values(adFormats)),
});
export const setEncodeArgsType = PropTypes.func;
