import React from 'react';
import { Button, Container, useMediaQuery } from '@mui/material';
import { useDraggable } from 'react-use-draggable-scroll';
import classNames from 'classnames';

import { NorthEast } from '@mui/icons-material';
import homeStyles from '../home/home.module.css';
import styles from '../site.module.css';
import scStyles from './stream-composer.module.css';
import ComposerIcon from '../svg/composer-icon';
import TabbedSection from '../tabbed-section/tabbed-section';
import TabbedSectionContent from '../tabbed-section/tabbed-section-content';

import ReportingIcon from '../svg/reporting-icon';
import ScalingIcon from '../svg/scaling-icon';
import EfficientEncodingIcon from '../svg/efficient-encoding-icon';

import MoreInfoCard from './more-info-card';
import CaseStudyCard from './case-study-card';
import DeveloperReadyIcon from '../svg/developer-ready-icon';

const shadowEnd = 'rgba(205, 205, 205, 0)';
const shadowStart = 'rgba(205, 205, 205, .25)';

const TABS = [
  {
    title: 'Ad Breaks',
    heading: 'Go beyond traditional ad breaks',
    description: 'Discover how Transmit enables you to seamlessly expand ad inventory and better engage your audience.',
    page: 'composer',
    contentImage: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-sc-ad-breaks.png',
  },
  {
    title: 'Ad Formats',
    heading: 'Support all devices and formats',
    description: 'Stream live or VOD in HLS or DASH format, and encode to match all device sizes.',
    page: 'composer',
    contentImage: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-sc-ad-formats.png',
  },
  {
    title: 'Existing Ad Breaks',
    heading: 'Fill existing breaks',
    description: 'Filling traditional ad breaks is simple with our automatic SCTE detection.',
    page: 'composer',
    contentImage: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-sc-existing-ad-breaks.png',
  },
  {
    title: 'Ad Decision Server',
    heading: 'Connect to any Ad Decision Server',
    description: 'Configure streams to use any Ad Decision Server, learn more about the Transmit Marketplace.',
    page: 'composer',
    contentImage: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-sc-ad-decision-server.png',
  },
];

const useShadowProps = () => {
  const sm = useMediaQuery('(max-width: 600px)');
  const md = useMediaQuery('(max-width: 900px)');
  const lg = useMediaQuery('(min-width: 1536px)');

  if (sm) {
    return {
      height: 299,
      leftShadowStyleProps: { left: 24 },
      rightShadowStyleProps: { right: -5 },
    };
  }

  if (md) {
    return {
      height: 288,
      leftShadowStyleProps: { left: 44 },
      rightShadowStyleProps: { right: -5 },
    };
  }

  if (lg) {
    return {
      height: 288,
      leftShadowStyleProps: { left: 0, top: 20 },
      rightShadowStyleProps: { right: -5, top: 20 },
    };
  }

  return {
    height: 288,
    leftShadowStyleProps: { left: 44, top: 20 },
    rightShadowStyleProps: { right: -5, top: 20 },
  };
};

const StreamComposer = () => {
  const [leftShadow, setLeftShadow] = React.useState(false);
  const [rightShadow, setRightShadow] = React.useState(true);
  const moreInfoCardsRef = React.useRef(null);
  const { events } = useDraggable(moreInfoCardsRef);

  const { leftShadowStyleProps, rightShadowStyleProps, height: shadowHeight } = useShadowProps();

  // eslint-disable-next-line
  React.useEffect(() => {
    if (moreInfoCardsRef?.current) {
      const onScroll = (e) => {
        const scrollWidthEnd = e.target.scrollLeft + e.target.offsetWidth === e.target.scrollWidth;
        if (e.target.scrollLeft === 0) {
          setLeftShadow(false);
        } else {
          setLeftShadow(true);
        }

        if (scrollWidthEnd) {
          setRightShadow(false);
        } else {
          setRightShadow(true);
        }
      };
      moreInfoCardsRef.current.addEventListener('scroll', onScroll);

      return () => {
        if (moreInfoCardsRef?.current) {
          moreInfoCardsRef?.current.removeEventListener('scroll', onScroll);
        }
      };
    }
  }, [moreInfoCardsRef.current]);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
    TABS.forEach((tab) => {
      new Image().src = tab.contentImage;
    });
  }, []);

  const tabs = React.useMemo(() => {
    return TABS.map((tab) => {
      return {
        title: tab.title,
        content: (
          <TabbedSectionContent
            key={tab.title}
            tabTitle={tab.title}
            heading={tab.heading}
            description={tab.description}
            page={tab.page}
          >
            <img src={tab.contentImage} alt="yankees-athletics" />
          </TabbedSectionContent>
        ),
      };
    });
  }, []);

  return (
    <div>
      <Container maxWidth="xl" className={classNames(styles.container, scStyles.streamComposer)}>
        <div className={scStyles.header}>
          <div className={scStyles.headerInner}>
            <div>
              <div className={scStyles.composerIconSection}>
                <ComposerIcon />
                <p className={styles.headerIconDesc}>Stream Composer</p>
              </div>
              <h1 className={classNames(styles.heading, scStyles.headerHeading)}>
                Deliver <br />
                <span>personalized</span>
                <br />
                creatives at scale
              </h1>
              <p
                className={classNames(styles.description, homeStyles.cardsDesc, scStyles.headerDesc)}
                style={{ margin: 0 }}
              >
                Server-side ad technology reimagined with ultimate creative flexibility.
              </p>

              <Button
                endIcon={<NorthEast />}
                className={classNames(styles.button, styles.buttonGreen, styles.mt2)}
                href="/request-demo"
              >
                Get started
              </Button>
            </div>
            <div className={scStyles.headerVideo}>
              <video
                // src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/nba-pip.mp4"
                src="https://tl-open-content.s3.amazonaws.com/web-assets/nba-pip-optimized.mp4"
                loop
                muted
                playsInline
                autoPlay
              />
            </div>
          </div>
        </div>

        <div className={classNames(styles.sectionCardContainer, styles.mt12, scStyles.newInventorySection)}>
          <div className={classNames(styles.sectionCard, scStyles.sectionCard)}>
            <div>
              <div className={styles.centerBlock}>
                <h2 className={styles.heading}>Create new inventory</h2>
                <div className={classNames(styles.description, styles.narrow, homeStyles.cardsDesc)}>
                  Generate premium advertising opportunities during peak moments and lulls in action.
                </div>
              </div>

              <div className={styles.mt3}>
                <TabbedSection tabs={tabs} />
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.flex, styles.justifyCenter)}>
          <div className={styles.cardsContainer}>
            <div className={classNames(styles.sectionCardContainer, styles.mt3, scStyles.headingSection)}>
              <h2 className={styles.heading}>Enhanced supply</h2>
              <div
                className={classNames(styles.description, homeStyles.cardsDesc)}
                style={{ maxWidth: 298, textAlign: 'center' }}
              >
                Maximize content monetization and boost brand awareness.
              </div>
            </div>

            <div className={classNames(styles.sectionCardContainer, scStyles.miniCards)}>
              <div className={classNames(styles.miniCard, scStyles.premiumCpmCard, styles.mobileBorderRadius)}>
                <div className={styles.miniCardHeader}>
                  <h3 className={styles.heading}>Premium CPM</h3>
                  <div className={classNames(styles.description, scStyles.description, homeStyles.cardsDesc)}>
                    In stream inventory can be priced at 25%+ above standard advertising.
                  </div>
                </div>

                <img src="https://tl-open-content.s3.amazonaws.com/web-assets/demo-sc-fly-moment.jpg" alt="" />
              </div>

              <div className={classNames(styles.miniCard, scStyles.brandAffinityCard, styles.mobileBorderRadius)}>
                <div className={styles.miniCardHeader}>
                  <h3 className={styles.heading}>Increase Brand Affinity</h3>
                  <div className={classNames(styles.description, scStyles.description, homeStyles.cardsDesc)}>
                    Studies reveal audiences hold a more positive view of brands that avoid interrupting their
                    programming compared to those that do.
                  </div>
                </div>

                <div className={scStyles.brandAffinityContent}>
                  <div className={scStyles.brandAffinityPercentage}>+11.5%</div>
                  <div className={classNames(scStyles.brandAffinityContentDesc, styles.description)}>
                    vs pre-roll and mid-roll
                  </div>
                </div>
              </div>

              <div
                className={classNames(styles.miniCard, scStyles.stayWithActionCard, styles.mobileBorderRadius)}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <div className={classNames(styles.miniCardHeader, scStyles.stayWithActionHeader)}>
                  <h3 className={styles.heading}>Stay with the Action</h3>
                  <div className={classNames(styles.description, scStyles.description, homeStyles.cardsDesc)}>
                    Seamlessly stitch content and ads into a single video stream to deliver targeted and individualized
                    viewing experiences across LIVE and VOD content at scale.
                  </div>
                </div>

                <img src="https://tl-open-content.s3.amazonaws.com/web-assets/demo-sc-action.png" alt="" />
              </div>
            </div>

            <div className={classNames(styles.sectionCardContainer, styles.mt3, scStyles.headingSection)}>
              <h2 className={styles.heading}>And much more</h2>
            </div>

            <div style={{ position: 'relative', overflow: 'hidden' }}>
              {rightShadow ? (
                <div
                  className={styles.shadow}
                  style={{
                    ...rightShadowStyleProps,
                    background: `linear-gradient(to right, ${shadowEnd} 0%, ${shadowStart} 100%)`,
                    height: shadowHeight,
                  }}
                />
              ) : null}
              {leftShadow ? (
                <div
                  className={styles.shadow}
                  style={{
                    ...leftShadowStyleProps,
                    background: `linear-gradient(to left, ${shadowEnd} 0%, ${shadowStart} 100%)`,
                    height: shadowHeight,
                  }}
                />
              ) : null}
              <div className={scStyles.moreInfoCards} ref={moreInfoCardsRef} {...events}>
                <MoreInfoCard
                  icon={<ReportingIcon />}
                  heading="Flexible Reporting"
                  // eslint-disable-next-line max-len
                  description="Quickly and easily understand the heath of your stream, monitor fill rates to maximize yield, and obtain and holistic view of your monetization strategy."
                />
                <MoreInfoCard
                  icon={<ScalingIcon />}
                  heading="Automatic Scaling"
                  // eslint-disable-next-line max-len
                  description="Stream Composer automatically scales to meet the demand of your viewers, ensuring a consistent quality of service throughout."
                />
                <MoreInfoCard
                  icon={<EfficientEncodingIcon />}
                  heading="Efficient Encoding"
                  // eslint-disable-next-line max-len
                  description="We make sure your ads are encoded just in time to maximize fill rates and tracking beacons are fired efficiently and accurately."
                />
                <MoreInfoCard
                  icon={<DeveloperReadyIcon />}
                  heading="Developer Ready"
                  // eslint-disable-next-line max-len
                  description="Use the Developer API to streamline your workflow even further and integrate directly into your tools and processes."
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.mt3, styles.mb3, styles.centerBlock, scStyles.caseStudyWrapper)}>
          <CaseStudyCard />
        </div>
      </Container>
    </div>
  );
};

export default StreamComposer;
