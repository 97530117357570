/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import axios from 'axios';

import Alert from '@mui/material/Alert';

import Cookies from 'js-cookie';
import { Button, TextField } from '@mui/material';

import { loginUser, getAccount, jwtScopes } from '../../services/user-service';
import { defaultContext, isAdmin } from '../../services/account-service';
import { userHasScope } from '../../helpers/composer-helper';
import styles from './login.module.css';
import LoginHeader from './login-header';

const LoginForm = () => {
  /** @type {[Object, React.Dispatch<Object>]} */
  const [errors, setErrors] = React.useState({});
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const renderFormErrors = () => {
    return errors.form && <Alert severity="error">{errors.form}</Alert>;
  };

  const handleFormErrors = () => {
    const err = {
      form: 'Username or password is incorrect',
      email: true,
      password: true,
    };
    setErrors(err);
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();

    loginUser(email, password)
      .then(({ authToken, user }) => {
        axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        return { authToken, user };
      })
      .then(({ authToken, user }) => {
        getAccount()
          .then((account) => {
            const expires = { expires: 90 };
            Cookies.set('authToken', authToken, expires);
            return account;
          })
          .then((account) => {
            const hasComposerAccess = userHasScope(user, jwtScopes.composerRead);
            if (hasComposerAccess) {
              window.location.replace('/dashboard');
            } else {
              const consolePath = isAdmin(account)
                ? '/admin/accounts'
                : `/account/${account._id}/${defaultContext(account)}`;
              window.location.replace(consolePath);
            }
          });
      })
      .catch(() => {
        handleFormErrors();
      });
  };

  return (
    <div className={styles.formContainer}>
      <form className={styles.formFlex} onSubmit={handleLoginSubmit}>
        <LoginHeader headingText="Sign in to the Console" />
        <div>{renderFormErrors()}</div>
        <div>
          <label htmlFor="email" className={styles.textFieldLabel}>
            Email
          </label>
          <TextField
            className={styles.textField}
            error={Boolean(errors['/email'])}
            size="small"
            autoComplete="on"
            type="email"
            id="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="password" className={styles.textFieldLabel}>
            Password
          </label>
          <TextField
            className={styles.textField}
            error={Boolean(errors['/password'])}
            size="small"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            autoComplete="new-password"
            type="password"
            id="password"
            value={password}
          />
        </div>
        <div className={styles.secondaryLink}>
          <a href="/forgot-password">Forgot Password?</a>
        </div>
        <div>
          <Button type="submit" className={styles.button}>
            Sign In
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
