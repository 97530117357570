.csContainer {
  background-color: var(--siteBlack);
  position: relative;
  color: var(--siteWhite);
  padding-bottom: 50px;
}

.csContainer .csHeading {
  color: var(--siteWhite);
}

.csContainer .csDescription {
  color: var(--siteWhite);
  opacity: 0.75;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}

.csContainer .csHeaderOffset {
  padding-top: 175px;
  position: relative;
}

.csHeaderContainer {
  display: flex;
  position: relative;
  max-width: 1113px;
  margin: auto;
  margin-top: 72px;
}

.csHeader {
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
}

.csImg {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
}

.csHeaderOverlayImgs {
  width: 232px;
}

.csHeaderLeft {
  position: absolute;
  left: -52px;
  bottom: 100px;
}

.csHeaderRight {
  top: 119px;
  position: absolute;
  right: -35px;
}

.csImgRight {
  top: 119px;
  position: absolute;
  right: 130px;
}

.csImgLeft {
  position: absolute;
  left: 110px;
  bottom: 215px;
}

.csHeaderContent {
  position: relative;
  z-index: 2;
}

.csHeaderContent span {
  display: inherit;
}

.csHeaderGradient {
  border-radius: 1267px;
  background: #490690;
  filter: blur(187.5px);
  position: absolute;
  height: 951px;
  z-index: 1;
  top: 30vh;
  left: 0;
  right: 0;
}

.csBackgroundLogo {
  position: absolute;
  opacity: 50%;
}

.csBackgroundLogoLeft {
  top: 5vh;
  left: 0px;
}

.csBackgroundLogoRight {
  top: 10vh;
  right: 0px;
}

.csAdFormatsContainer {
  display: flex;
  position: relative;
}

.csAdFormatsHeadings {
  max-width: 300px;
  flex-grow: 1;
}

.csAdFormatsHeadings section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.csAdFormatsHeadings section:last-of-type > div {
  position: sticky;
  top: 100px;
}

.csAdFormatsTopGrad {
  height: 30vh;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 9;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.csAdFormatsBottomGrad {
  height: 30vh;
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  bottom: 0;
  z-index: 9;
}

.csAdFormatImgs {
  flex-grow: 3;
  height: 100vh;
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.csAdFormatsImg {
  width: 768px;
  height: auto;
  transition: opacity 200ms;
  box-shadow: 0 3px 15px rgb(0 0 0 / 20%);
  border-radius: 16px;
  box-shadow: 0px 73px 143px 0px rgba(116, 81, 211, 0.21), 0px 36.536px 71.57px 0px rgba(116, 81, 211, 0.16),
    0px 22.007px 43.11px 0px rgba(116, 81, 211, 0.14), 0px 14.103px 27.627px 0px rgba(116, 81, 211, 0.12),
    0px 9.141px 17.906px 0px rgba(116, 81, 211, 0.1), 0px 5.754px 11.272px 0px rgba(116, 81, 211, 0.09),
    0px 3.306px 6.476px 0px rgba(116, 81, 211, 0.07), 0px 1.455px 2.85px 0px rgba(116, 81, 211, 0.05);
}

.adFormatsContainer {
  gap: 36px;
}

.creativeStudioImg {
  position: absolute;
}

@media (max-width: 1350px) {
  .csHeaderOverlayImgs {
    width: 150px;
  }

  .csHeaderLeft {
    left: -32px;
  }

  .csHeaderRight {
    right: -25px;
  }

  .csImgRight {
    top: 54px;
  }

  .csImgLeft {
    bottom: 150px;
  }
}

@media (max-width: 1200px) {
  .csAdFormatsImg {
    width: 512px;
  }
}

@media (max-width: 1080px) {
  .csBackgroundLogoLeft {
    width: 45%;
  }

  .csBackgroundLogoRight {
    width: 40%;
  }

  .csAdFormatsImg {
    width: 432px;
  }
}

@media (max-width: 900px) {
  .adFormatsContainer {
    gap: 12px;
  }

  .csContainer .csHeaderOffset {
    padding-top: 100px;
  }

  .csHeaderLeft {
    height: 70px;
    bottom: 0;
  }

  .csHeaderRight {
    height: 90px;
    top: 60px;
  }

  .csImgRight {
    top: 24px;
    right: 70px;
    width: 113px;
  }

  .csImgLeft {
    left: 70px;
    bottom: 120px;
    width: 113px;
  }

  .csHeadingLogoContainer img {
    width: 70px;
  }

  .csHeadingLogoContainer .csDescription {
    margin: 5px 0px 10px 0px;
  }

  .csContainer .csHeading {
    font-size: 48px;
  }

  .csContainer .csDescription {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .csAdFormatsImg {
    width: 256px;
  }

  .csHeaderLeft {
    height: 50px;
    bottom: -30px;
  }

  .csHeaderRight {
    height: 70px;
    top: 40px;
  }
}

@media (max-width: 600px) {
  .csContainer .csHeading {
    font-size: 36px;
  }

  .csHeaderOverlayImgs {
    width: unset;
  }

  .csHeaderLeft {
    height: 30px;
    left: -12px;
    bottom: 55px;
    border-radius: 0 !important;
  }

  .csHeaderRight {
    height: 50px;
    right: -12px;
    top: 37px;
    border-radius: 0 !important;
  }

  .csAdFormatsContainer {
    display: flex;
    padding: 24px;
    margin-top: 136px;
    margin-bottom: 72px;
  }

  .csAdFormatsHeadings {
    max-width: unset;
    width: 100%;
  }

  .csAdFormatsHeadings section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: initial;
    margin-top: 60px;
  }

  .csAdFormatsHeadings section:first-of-type {
    margin-top: 0px;
  }

  .csAdFormatsHeadings .csDescription {
    margin: 5px 0px 25px 0px;
  }

  .csAdFormatsImg {
    position: static;
    width: 100%;
  }
  .csAdFormatsHeadings section:last-of-type > div {
    position: static;
  }

  .adFormatSection {
    position: relative;
    z-index: 1;
  }

  .creativeStudioImg {
    position: unset;
  }

  .csImgRight {
    top: 24px;
    right: 40px;
    width: 76px;
    border-radius: 0 !important;
  }

  .csImgLeft {
    left: 40px;
    bottom: 50px;
    width: 76px;
    border-radius: 0 !important;
  }
}

@media (max-width: 500px) {
  .csHeaderLeft {
    height: 30px;
    left: -12px;
  }
}
