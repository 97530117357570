import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import classNames from 'classnames';
import styles from './composer-toast.module.css';

export const toastStatus = {
  success: 'success',
  error: 'error',
  working: 'working',
};

/**
 * @typedef {Object} ToastData
 * @property {boolean=} showStatus
 * @property {String} status
 * @property {String} message
 */

/**
 * @typedef {Object} ToastContext
 * @property {ToastData} toast
 * @property {React.Dispatch<ToastData>} setToast
 */

/** @type {ToastData} */
const initialToastData = {
  showStatus: false,
  status: '',
  message: '',
};

/** @type {ToastContext} */
const initialState = {
  toast: initialToastData,
  setToast: () => {},
};

export const ComposerToastContext = createContext(initialState);

/**
 *
 * @param {Object} data
 * @param {any} data.children
 * @returns
 */
const ComposerToastContextProvider = ({ children }) => {
  const [toast, setToast] = useState(initialToastData);

  const { message, status } = toast || {};
  let { showStatus } = toast || {};

  let formatedMsg = message;
  if (message) {
    formatedMsg = message.charAt(0).toUpperCase() + message.slice(1);
  }

  const onClose = () => {
    setToast({
      showStatus: false,
      status,
      message,
    });
  };

  const classes = [styles.alert];
  let msg = message;
  let icon;
  let autoHideDuration = null;
  let isError = false;
  let closeFunc = onClose;
  let showWorking = false;

  switch (status) {
    case toastStatus.error:
      msg = formatedMsg || 'Errors when saving';
      classes.push(styles.error);
      autoHideDuration = 6000;
      isError = true;
      break;

    case toastStatus.success:
      classes.push(styles.success);
      msg = formatedMsg || 'Successfully saved';
      autoHideDuration = 3000;
      break;

    case toastStatus.working:
      classes.push(styles.success);
      msg = formatedMsg || 'Working...';
      closeFunc = undefined;
      showWorking = true;
      icon = false;
      break;

    default:
      break;
  }

  if (showStatus === undefined && msg) {
    showStatus = true;
  }

  return (
    <ComposerToastContext.Provider
      value={{
        toast,
        setToast,
      }}
    >
      <Snackbar
        open={showStatus}
        autoHideDuration={autoHideDuration}
        onClose={closeFunc}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={isError ? 'error' : 'success'} className={classNames(classes)} icon={icon}>
          {showWorking && (
            <CircularProgress
              size="18px"
              style={{
                marginRight: '16px',
                verticalAlign: 'bottom',
                color: 'white',
              }}
            />
          )}
          {msg}
        </Alert>
      </Snackbar>
      {children}
    </ComposerToastContext.Provider>
  );
};

ComposerToastContextProvider.defaultProps = {
  children: [],
};

ComposerToastContextProvider.propTypes = {
  children: PropTypes.node,
};

export default ComposerToastContextProvider;
