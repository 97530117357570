import React, { useEffect, useState } from 'react';
import { Box, Collapse, Container, Button } from '@mui/material';
import classNames from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import RequestDemoBanner from './request-demo-banner';

import styles from './site.module.css';
import homeStyles from './home/home.module.css';
import careersStyles from './careers.module.css';

const CAREERS_LINK = 'https://tl-open-content.s3.amazonaws.com/web-assets/careers.json';
const SECTIONS = {
  overview: 'Overview',
  responsibilities: 'Responsibilities',
  qualifications: 'Qualifications',
};

const Careers = () => {
  const [careers, setCareers] = useState([]);
  const [expandedCareer, setExpandedCareer] = useState(null);
  const [rotateIcon, setRotateIcon] = useState(null);

  const fetchJobs = async () => {
    const response = await fetch(CAREERS_LINK);
    if (response.status !== 200) return;

    const jsonBody = await response.json();
    setCareers(jsonBody);
  };

  const handleCareerClick = (title) => {
    if (title === expandedCareer) {
      setExpandedCareer(null);
    } else {
      setExpandedCareer(title);
    }

    setRotateIcon(title);
    setTimeout(() => setRotateIcon(null), 250);
  };

  const renderCareers = () =>
    careers.map((career) => {
      const { title } = career;
      const iconStyles =
        title === rotateIcon ? classNames(careersStyles.addIcon, careersStyles.rotateIcon) : careersStyles.addIcon;
      return (
        <div className={careersStyles.careerContainer} key={title}>
          <Box className={careersStyles.careerBox} onClick={() => handleCareerClick(title)}>
            <div className={careersStyles.careerLineItem}>
              <h3 className={careersStyles.careerHeading}>{title}</h3>
              <AddIcon className={iconStyles} />
            </div>
          </Box>
          <Collapse in={title === expandedCareer}>
            <div className={classNames(styles.flex, styles.flexCol, styles.gap, styles.mb1)}>
              {Object.keys(SECTIONS).map((section) => {
                const name = SECTIONS[section];
                const text = career[section];
                const textLines = text.split('\n');
                return (
                  <div key={name} className={classNames(styles.flex, styles.flexCol, styles.gap)}>
                    <h4 className={classNames(homeStyles.cardsDesc)}>{name}</h4>
                    {textLines.map((line, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`line-${i}`} className={classNames(homeStyles.cardsDesc)}>
                        {line}
                      </div>
                    ))}
                  </div>
                );
              })}
              <div className={classNames(styles.flex, styles.flexCol, styles.alignCenter, styles.mt2, styles.mb1)}>
                <a href="mailto:contact@transmit.live">
                  <Button className={classNames(styles.button, styles.buttonPurple)}>Apply Now</Button>
                </a>
              </div>
            </div>
          </Collapse>
        </div>
      );
    });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    fetchJobs();
  }, []);

  return (
    <div>
      <Container maxWidth="xl" className={classNames(styles.container)}>
        <div className={styles.centerContainer}>
          <section className={classNames(styles.flex, styles.flexCol, styles.horizontalPaddingContainer)}>
            <div className={styles.headerOffset}>
              <div className={classNames(styles.gap, styles.roundedContainer)}>
                <div className={classNames(styles.flex, styles.flexCol, styles.gap, styles.alignCenter)}>
                  <h2 className={styles.heading}>Careers</h2>
                  <div className={classNames(styles.sectionDescription, homeStyles.cardsDesc)}>
                    Transmit provides a transformative cross-screen video publishing & monetization solution. Our
                    technology enables media operators to create new inventory supply through innovative in-content ad
                    experiences that sustain viewer attention across all owned & distributed OTT channels. Our
                    end-to-end platform delivers dynamic OTT streaming, experience-oriented advertising powered by
                    cutting-edge creative technology.
                  </div>
                  <div
                    className={classNames(
                      styles.flex,
                      styles.flexCol,
                      styles.alignCenter,
                      careersStyles.careersContainer
                    )}
                  >
                    {renderCareers()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className={classNames(styles.mt3, styles.mb3, styles.centerBlock)}>
          <RequestDemoBanner />
        </div>
      </Container>
    </div>
  );
};

export default Careers;
