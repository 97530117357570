import axios from 'axios';

export const checkResetToken = async (token) => {
  const url = `${SERVICE_URL}/api/v1/reset-password/${token}`;
  return axios.get(url).then((response) => {
    return response.data;
  });
};

export const resetPassword = async (password, token) => {
  const url = `${SERVICE_URL}/api/v1/reset-password/${token}`;
  return axios.put(url, { password }).then((response) => {
    return response.data;
  });
};

export const sendPasswordResetEmail = async (email) => {
  const url = `${SERVICE_URL}/api/v1/forgot-password`;
  return axios.post(url, { email }).then((response) => {
    return response.data;
  });
};
