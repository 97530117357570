import React from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import HomeTopNav from './home-top-nav';
import Footer from './footer';
import styles from './site.module.css';
import { useEventListener } from '../../hooks/useEventListener';

const useDefaultSiteBg = () => {
  const location = useLocation();
  const nonRequiredRoutes = ['/', '/stream-composer', '/stream-extension', '/creative-studio'];

  const isNonRequiredRoute = nonRequiredRoutes.some((r) => {
    return matchPath(
      {
        path: r,
        end: true,
      },
      location.pathname
    );
  });

  return !isNonRequiredRoute;
};

const AppLayout = () => {
  const [scrollY, setScrollY] = React.useState(0);
  const defaultSiteBg = useDefaultSiteBg();

  useEventListener('scroll', () => {
    setScrollY(window.scrollY);
  });

  return (
    <main className={classNames(styles.websiteRoot, defaultSiteBg ? styles.defaultSiteBg : '')}>
      <HomeTopNav scrollY={scrollY} />
      <Outlet context={{ scrollY }} />
      <Footer />
    </main>
  );
};

AppLayout.defaultProps = {};

AppLayout.propTypes = {};

export default AppLayout;
