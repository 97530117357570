import { ENCODE_STATUS } from '../../creative-studio/preview/studio-preview-helper';
import { ExtensionDemoStepsMap, stepsMinRequirements } from './constants';
import { getExtensionDemo } from './services';

const FETCH_INTERVAL_MS = 3000;

export function deriveAdDurationFromContentDuration({ contentDuration }) {
  let adDuration = 15;

  if (contentDuration < 30 && contentDuration >= 15) {
    adDuration = 6;
  }

  return adDuration;
}

export function getHoursMinutesSecondsFromTime(time) {
  const hours = Math.floor(time / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(time % 60)
    .toString()
    .padStart(2, '0');
  return {
    hours,
    minutes,
    seconds,
  };
}

export function pollExtensionDemo({
  encodeArgs,
  setEncodeArgs,
  contentWidth,
  contentHeight,
  startedEncode,
  onEncodeComplete,
}) {
  if (startedEncode) {
    const interval = setInterval(() => {
      getExtensionDemo({
        ...encodeArgs,
        contentWidth,
        contentHeight,
      })
        .then(({ data }) => {
          const { encodeStatus, encodeUrl } = data;
          if (data.encodeStatus === ENCODE_STATUS.complete || data.encodeStatus === ENCODE_STATUS.error) {
            clearInterval(interval);
            if (data.encodeStatus === ENCODE_STATUS.complete) {
              setEncodeArgs({
                ...encodeArgs,
                encodeStatus,
                encodeUrl,
              });

              onEncodeComplete();
            }
            if (data.encodeStatus === ENCODE_STATUS.error) {
              setEncodeArgs({
                ...encodeArgs,
                encodeStatus,
                encodeUrl: null,
              });
            }
          }

          if (data.encodeStatus === ENCODE_STATUS.encoding) {
            setEncodeArgs({
              ...encodeArgs,
              encodeStatus,
            });
          }
        })
        .catch(() => {
          // TODO: add error handling
          clearInterval(interval);
          // setExtensionEncodeInterval(null);
        });
    }, FETCH_INTERVAL_MS);
  }

  return null;
}

export function shouldRedirectToInitialStep({ step, encodeArgs }) {
  const { contentUrl, adFormat, insertionPoint } = encodeArgs;
  const adFormatMinReqs = stepsMinRequirements[ExtensionDemoStepsMap.CHOOSE_FORMAT][adFormat];

  switch (step) {
    case ExtensionDemoStepsMap.UPLOAD_CONTENT:
      return false;

    case ExtensionDemoStepsMap.CHOOSE_FORMAT:
      return !contentUrl;

    case ExtensionDemoStepsMap.INSERTION_POINT:
      return adFormatMinReqs.every((req) => !encodeArgs[req]);

    case ExtensionDemoStepsMap.COMPLETE:
      return !contentUrl || !insertionPoint || adFormatMinReqs.every((req) => !encodeArgs[req]);

    default:
      return false;
  }
}
