import React from 'react';

import homeStyles from './home.module.css';

const partnerLogos = {
  amc: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/amc.png',
    alt: 'AMC',
  },
  andBox: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/and-box.png',
    alt: 'And Box',
  },
  bein: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/bein.png',
    alt: 'beIN Sports',
  },
  bell: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/bell.png',
    alt: 'Bell',
  },
  cbsSports: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/cbs-sports.png',
    alt: 'CBS Sports',
  },
  cheddar: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/cheddar.png',
    alt: 'Cheddar',
  },
  fanDuel: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/fan-duel.png',
    alt: 'FanDuel TV',
  },
  floSports: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/flo-sports.png',
    alt: 'FloSports',
  },
  foxSports: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/fox-sports.png',
    alt: 'Fox Sports',
  },
  lightsOut: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/lights-out.png',
    alt: 'Lights Out Sports',
  },
  liveLive: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/live-live.png',
    alt: 'Live Live',
  },
  mlb: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/mlb.png',
    alt: 'MLB',
  },
  mls: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/mls.png',
    alt: 'MLS',
  },
  msgGo: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/msg-go.png',
    alt: 'MSG Go',
  },
  nbcu: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/nbcu.png',
    alt: 'NBCU',
  },
  nesn: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/nesn.png',
    alt: 'NESN',
  },
  nhl: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/nhl.png',
    alt: 'NHL',
  },
  paramount: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/paramount.png',
    alt: 'Paramount',
  },
  rumble: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/rumble.png',
    alt: 'Rumble',
  },
  sg: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/sg.png',
    alt: 'SG',
  },
  superLeagueGaming: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/super-league-gaming.png',
    alt: 'Super League Gaming',
  },
  tc: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/tennis-channel.png',
    alt: 'Tennis Channel',
  },
  toi: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/toi.png',
    alt: 'TOI',
  },
  tundra: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/tundra.png',
    alt: 'Tundra',
  },
  univision: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/univision.png',
    alt: 'Univision',
  },
  willowSports: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/willow-sports.png',
    alt: 'Willow Sports',
  },
  wpt: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/world-poker-tour.png',
    alt: 'World Poker Tour',
  },
  wsl: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/wsl.png',
    alt: 'WSL',
  },
  yes: {
    src: 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/partnership-icons/yes.png',
    alt: 'YES',
  },
};

const showcaseRows = [
  [
    partnerLogos.cheddar,
    partnerLogos.foxSports,
    partnerLogos.yes,
    partnerLogos.tc,
    partnerLogos.liveLive,
    partnerLogos.cbsSports,
    partnerLogos.nhl,
    partnerLogos.floSports,
  ],
  [
    partnerLogos.msgGo,
    partnerLogos.superLeagueGaming,
    partnerLogos.toi,
    partnerLogos.mlb,
    partnerLogos.rumble,
    partnerLogos.willowSports,
    partnerLogos.fanDuel,
    partnerLogos.sg,
  ],
  [
    partnerLogos.lightsOut,
    partnerLogos.wpt,
    partnerLogos.amc,
    partnerLogos.paramount,
    partnerLogos.univision,
    partnerLogos.bein,
  ],
];

const PartnerShowcase = () => {
  return (
    <div className={homeStyles.partnerShowcase}>
      {showcaseRows.map((row) => {
        return (
          <div key={row[0].alt} className={homeStyles.partnerShowcaseRow}>
            {row.map(({ src, alt }) => {
              return (
                <div
                  key={alt}
                  className={homeStyles.partnerShowcaseIcon}
                  style={{
                    backgroundImage: `url(${src})`,
                  }}
                />
              );
            })}
            {row.length < 9 && <div className={homeStyles.partnerShowcaseIconSpacing} style={{}} />}
          </div>
        );
      })}
    </div>
  );
};

export default PartnerShowcase;
