/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';
import csStyles from '../creative-studio/creative-studio.module.css';

const CsBgLogoRight = React.memo(() => (
  <svg
    className={classNames(csStyles.csBackgroundLogo, csStyles.csBackgroundLogoRight)}
    width="410"
    height="734"
    viewBox="0 0 410 734"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11651 224.065L420.171 1.57812C425.119 -1.0934 431.126 2.48963 431.126 8.11255V174.94L192.495 303.788C187.693 306.381 184.699 311.399 184.699 316.857V558.205L11.6295 651.653C6.6817 654.325 0.675102 650.742 0.675102 645.119V236.54C0.675102 231.331 3.53239 226.541 8.11651 224.065ZM184.699 558.972L11.9502 652.247C6.55266 655.162 0 651.253 0 645.119V236.54C0 231.083 2.99335 226.064 7.79576 223.471L419.851 0.984081C425.248 -1.9303 431.801 1.97845 431.801 8.11255V174.576L604.55 81.3006C609.947 78.3862 616.5 82.2949 616.5 88.429V501.035C616.5 504.012 614.867 506.749 612.248 508.163L196.649 732.564C191.252 735.478 184.699 731.569 184.699 725.435V558.972ZM431.801 175.343L604.871 81.8946C609.818 79.2231 615.825 82.8061 615.825 88.429V501.035C615.825 503.764 614.328 506.273 611.927 507.569L196.329 731.97C191.381 734.641 185.374 731.058 185.374 725.435V558.608L427.549 427.847C430.168 426.432 431.801 423.695 431.801 420.718V175.343ZM431.126 175.707V420.718C431.126 423.447 429.629 425.956 427.228 427.253L185.374 557.841V316.857C185.374 311.647 188.232 306.857 192.816 304.382L431.126 175.707Z"
        fill="white"
      />
    </g>
  </svg>
));

export default CsBgLogoRight;
