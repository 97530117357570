export const PREVIEW_DEVICE = {
  tv: 'tv',
  laptop: 'laptop',
  phone: 'phone',
};

export const ENCODE_STATUS = Object.freeze({
  complete: 'complete',
  encoding: 'encoding',
  error: 'error',
  none: 'none',
  submitted: 'submitted',
});

const PREVIEW_ENCODE_PROGRESS = {
  initial: 0,
  firstBenchmark: 10,
  secondBenchmark: 50,
  complete: 100,
};

export const getEncodeProgress = ({ progress, currentInterval, benchmark }) => {
  let currentProgress = progress;
  if (currentInterval > 120) {
    return { currentProgress: null, benchmark, currentInterval };
  }
  if (!progress) {
    currentInterval += 1;
    currentProgress = Math.min(PREVIEW_ENCODE_PROGRESS.firstBenchmark - 1, currentInterval);
  }
  if (progress === PREVIEW_ENCODE_PROGRESS.firstBenchmark) {
    if (!benchmark) {
      benchmark = PREVIEW_ENCODE_PROGRESS.firstBenchmark;
      currentInterval = 0;
      currentProgress = Math.min(PREVIEW_ENCODE_PROGRESS.secondBenchmark - 1, currentInterval + progress);
    } else {
      currentInterval += 1;
      currentProgress = Math.min(PREVIEW_ENCODE_PROGRESS.secondBenchmark - 1, currentInterval + progress);
    }
  }
  if (progress === PREVIEW_ENCODE_PROGRESS.secondBenchmark) {
    if (benchmark === PREVIEW_ENCODE_PROGRESS.firstBenchmark) {
      benchmark = PREVIEW_ENCODE_PROGRESS.secondBenchmark;
      currentInterval = 0;
      currentProgress = Math.min(PREVIEW_ENCODE_PROGRESS.complete - 1, currentInterval + progress);
    } else {
      currentInterval += 1;
      currentProgress = Math.min(PREVIEW_ENCODE_PROGRESS.complete - 1, currentInterval + progress);
    }
  }
  return { currentProgress, benchmark, currentInterval };
};
