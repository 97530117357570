import React from 'react';
import PropTypes from 'prop-types';

import styles from './login.module.css';

import Logo from '../../images/tl-icon@2x.png';

const LoginHeader = ({ headingText }) => {
  return (
    <>
      <img src={Logo} alt="Transmit.Live" height="40px" />
      <div className={styles.formHeader}>{headingText}</div>
    </>
  );
};

export default LoginHeader;

LoginHeader.propTypes = {
  headingText: PropTypes.string.isRequired,
};
