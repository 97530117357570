import React from 'react';
import { unstable_useBlocker, useFetcher, useOutletContext } from 'react-router-dom';
import { Button } from '@mui/material';
import classNames from 'classnames';

import EncodeStatus from './encode-status';
import DemoFooter from '../demo-footer';
import { ComposerToastContext, toastStatus } from '../../../composer/composer-toast-context';
import { ENCODE_STATUS } from '../../../creative-studio/preview/studio-preview-helper';
import { getExtensionDemo, sendExtensionDemoEmail } from '../services';
import { pollExtensionDemo } from '../utils';

import styles from '../../site.module.css';
import demoStyles from '../extension-demo.module.css';
import ConfirmationDialog from '../../../confirmation-dialog';
import useRedirectToInitialStep from '../hooks/useRedirectToInitialStep';
import { ExtensionDemoStepsMap } from '../constants';

const Share = () => {
  const { setToast } = React.useContext(ComposerToastContext);
  /** @type Object*/
  const [encodeArgs, setEncodeArgs] = useOutletContext();
  const { contentWidth, contentHeight } = encodeArgs;
  const fetcher = useFetcher();

  useRedirectToInitialStep({
    step: ExtensionDemoStepsMap.COMPLETE,
    encodeArgs,
  });

  const encodeStatus = fetcher.data?.encodeStatus || encodeArgs.encodeStatus;
  const disableSubmitButton = encodeStatus === ENCODE_STATUS.submitted || encodeStatus === ENCODE_STATUS.encoding;

  const blocker = unstable_useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation.pathname !== nextLocation.pathname;
  });

  const handleOnEncodeComplete = () => {
    const data = {
      ...encodeArgs,
      contentWidth,
      contentHeight,
    };

    fetcher.submit(data, {
      method: 'POST',
    });
  };

  React.useEffect(() => {
    const startedEncode = !!(
      fetcher.data?.encodeStatus &&
      !(fetcher.data?.encodeStatus === ENCODE_STATUS.complete || fetcher.data?.encodeStatus === ENCODE_STATUS.error)
    );
    if (startedEncode) {
      pollExtensionDemo({
        encodeArgs,
        setEncodeArgs,
        contentWidth,
        contentHeight,
        startedEncode,
        onEncodeComplete: handleOnEncodeComplete,
      });
    }

    if (fetcher.data?.message) {
      const status = encodeStatus === ENCODE_STATUS.complete ? toastStatus.success : toastStatus.error;
      setToast({
        status,
        message: fetcher.data?.message,
      });
    }
  }, [fetcher.data]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('email');

    setEncodeArgs({
      ...encodeArgs,
      email,
    });

    const data = {
      ...encodeArgs,
      contentWidth,
      contentHeight,
      email,
    };

    fetcher.submit(data, {
      method: 'POST',
    });
  };

  return (
    <React.Fragment>
      <div
        className={classNames(styles.flex, styles.flexCol, styles.fullWidth, styles.alignCenter)}
        style={{ gap: 43 }}
      >
        <div className={demoStyles.shareFormContainer}>
          <div
            className={classNames(
              styles.flex,
              styles.flexCol,
              styles.alignCenter,
              styles.justifyCenter,
              styles.fullWidth
            )}
            style={{
              gap: 14,
            }}
          >
            <EncodeStatus status={encodeStatus} />
            <div
              className={classNames(styles.flex, styles.flexCol, styles.alignCenter)}
              style={{
                gap: 10,
                maxWidth: 436,
              }}
            >
              <p className={demoStyles.shareFormTitle}>Who should we send this to?</p>
              <p className={classNames(styles.textCenter, demoStyles.shareFormSubtitle)}>
                We will start the encode process, and send the final result as soon as it is completed.
              </p>
            </div>
          </div>
          <fetcher.Form onSubmit={handleOnSubmit}>
            <div className={demoStyles.shareFormInputContainer}>
              <input className={demoStyles.shareFormInput} name="email" placeholder="Email address" type="email" />
              <Button
                className={classNames(
                  styles.button,
                  styles.buttonPurple,
                  styles.fullWidth,
                  demoStyles.shareFormActionButton
                )}
                disabled={disableSubmitButton}
                type="submit"
              >
                Send
              </Button>
            </div>
          </fetcher.Form>
        </div>
        <div className={classNames(styles.alignCenter, styles.flex, styles.flexCol, styles.fullWidth)}>
          <DemoFooter
            backNavDisabled={false}
            backNavPath="../select-insertion"
            childrenContainerStyles={{
              display: 'flex',
              flexDirection: 'column',
              gap: 19,
            }}
            nextNavVisible={false}
          />
        </div>
      </div>
      {blocker.state === 'blocked' ? (
        <ConfirmationDialog
          contentText={`
            Navigating away will erase all changes, 
            ensure you sent the encode to your email first.
            `}
          onConfirmClick={() => {
            setEncodeArgs({
              ...encodeArgs,
              encodeUrl: null,
              encodeStatus: ENCODE_STATUS.none,
              email: null,
            });
            blocker.proceed?.();
          }}
          onCancelClick={() => blocker.reset?.()}
        />
      ) : null}
    </React.Fragment>
  );
};

Share.defaultProps = {};
Share.propTypes = {};

export default Share;

export const action = async ({ request }) => {
  const formData = await request.formData();
  const { contentWidth, contentHeight, email, ...encodeArgs } = Object.fromEntries(formData);
  const cleanEncodeArgs = Object.entries(encodeArgs).reduce((acc, cur) => {
    const [key, val] = cur;
    if (val === 'null') {
      return acc;
    }
    return { ...acc, [key]: val };
  }, {});

  if (encodeArgs.encodeStatus === ENCODE_STATUS.complete) {
    let message;
    try {
      await sendExtensionDemoEmail({
        email,
        encodeUrl: encodeArgs.encodeUrl,
      });
      message = `Sent URL to ${email}`;
    } catch (e) {
      message = `Couldn't send email, please try again`;
    }

    return {
      encodeStatus: encodeArgs.encodeStatus,
      encodeUrl: encodeArgs.encodeUrl,
      message,
    };
  }

  try {
    const extensionDemo = await getExtensionDemo({
      ...cleanEncodeArgs,
      contentWidth,
      contentHeight,
    });

    if (extensionDemo) {
      const {
        data: { encodeStatus, encodeUrl },
      } = extensionDemo;

      // if after fetching the extensionDemo
      // find its done, send email
      let message;
      if (encodeStatus === ENCODE_STATUS.complete) {
        try {
          await sendExtensionDemoEmail({
            email,
            encodeUrl,
          });
          message = `Sent URL to ${email}`;
        } catch (e) {
          message = `Couldn't send email, please try again`;
        }
      }

      return {
        encodeStatus,
        encodeUrl,
        message,
      };
    }
  } catch (e) {
    // TODO:
    // add error handling
    console.log({ e });
  }

  return {};
};
