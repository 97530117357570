import axios from 'axios';
import PropTypes from 'prop-types';
import framedIcon from '../assets/icons/framed.svg';
import frameOffsetBottomIcon from '../assets/icons/frame-offset.svg';
import frameOffsetTopIcon from '../assets/icons/frame-offset-top.svg';
import frameTopLeftIcon from '../assets/icons/frame-top-left.svg';
import fullscreenIcon from '../assets/icons/fullscreen.svg';
import squeezeBackIcon from '../assets/icons/squeezeback.svg';
import pipIcon from '../assets/icons/pip.svg';
import overlayIcon from '../assets/icons/overlay.svg';
import interstitialIcon from '../assets/icons/interstitial.svg';
import fiftyFiftyIcon from '../assets/icons/even.svg';
import fullSplitIcon from '../assets/icons/full-split.svg';
import overlayBottomLeftIcon from '../assets/icons/overlay-bottomleft.svg';
import overlayBottomRightIcon from '../assets/icons/overlay-bottomright.svg';
import overlayTopLeftIcon from '../assets/icons/overlay-topleft.svg';
import overlayTopRightIcon from '../assets/icons/overlay-topright.svg';
import verticalFullIcon from '../assets/icons/vertical-video-full.svg';
import enhancedVerticalCenter from '../assets/icons/enhanced-frame-vertical-center.svg';
import enhancedVerticalLeft from '../assets/icons/enhanced-frame-vertical-left.svg';

export const transitionTypes = {
  intro: 'intro',
  outro: 'outro',
};

export const verticalAlignment = {
  bottom: 'bottom',
  middle: 'middle',
  top: 'top',
};

export const horizontalAlignment = {
  center: 'center',
  creativeCenter: 'creativeCenter',
  contentCenter: 'contentCenter',
  right: 'right',
  left: 'left',
};

export const CREATIVE_PREVIEW_DEMO_HLS_URL = 'https://tl-open-content.s3.amazonaws.com/football-demo/vod/master.m3u8';
export const DEFAULT_PREVIEW_CONTENT = {
  _id: '63e66d40d1440c2b7fdfedec',
  contentStreamId: '63d961507fc85541a3dcce61',
  hlsUrl: CREATIVE_PREVIEW_DEMO_HLS_URL,
  timeOffsetMs: 0,
  name: 'Default',
  contentType: 'video/hls',
  contentOriginHeaders: {},
  duration: 90,
};

export const AD_FORMATS = {
  pip: {
    key: 'pip',
    displayName: 'Picture-in-Picture',
    desc: 'PIP ads display ad content within an inset window.',
    icon: pipIcon,
    hasContent: true,
  },
  squeezeBack: {
    key: 'squeezeBack',
    displayName: 'Squeeze Back',
    desc: 'These ads reduce the size of the content to allow ads around it.',
    icon: squeezeBackIcon,
    hasContent: true,
  },
  overlay: {
    key: 'overlay',
    displayName: 'Overlay',
    desc: 'A small banner-like ad position on top of the video content.',
    icon: overlayIcon,
    hasContent: true,
  },
  interstitial: {
    key: 'interstitial',
    displayName: 'Full Screen',
    desc: 'A traditional ad with a <VAST> tag, presented full screen.',
    icon: interstitialIcon,
    hasContent: false,
  },
  enhancedCreative: {
    key: 'enhancedCreative',
    displayName: 'Dynamic Full Screen',
    desc: 'A traditional <VAST> ad enhanced with additional creative elements.',
    icon: interstitialIcon,
    hasContent: false,
  },
};

export const SORTED_AD_FORMATS = [
  AD_FORMATS.pip.key,
  AD_FORMATS.squeezeBack.key,
  AD_FORMATS.interstitial.key,
  AD_FORMATS.overlay.key,
];

export const NO_VIDEO_FORMATS = [AD_FORMATS.squeezeBack.key, AD_FORMATS.overlay.key];

export const LIVE_DATA_LAYOUTS = {
  lBarLiveDataBottom: true,
  sixtyFortyLiveDataBottom: true,
  fullSplitLiveDataBottom: true,
  enhancedFrameLiveData: true,
};

export const AD_LAYOUT_ICONS = {
  verticalFull: {
    icon: verticalFullIcon,
  },
  sixtyForty: {
    icon: pipIcon,
  },
  sixtyFortyLiveDataBottom: {
    icon: pipIcon,
  },
  fiftyFifty: {
    icon: fiftyFiftyIcon,
  },
  fullSplit: {
    icon: fullSplitIcon,
  },
  fullSplitLiveDataBottom: {
    icon: fullSplitIcon,
  },
  lBar: {
    icon: squeezeBackIcon,
  },
  lBarLiveDataBottom: {
    icon: squeezeBackIcon,
  },
  frameOffsetBottom: {
    icon: frameOffsetBottomIcon,
  },
  enhancedFrame: {
    icon: frameOffsetBottomIcon,
  },
  enhancedFrameLiveData: {
    icon: frameOffsetBottomIcon,
  },
  enhancedFrameTopLeft: {
    icon: frameTopLeftIcon,
  },
  enhancedFrameLow: {
    icon: frameOffsetTopIcon,
  },
  enhancedFrameVerticalLeft: {
    icon: enhancedVerticalLeft,
  },
  enhancedFrameVerticalCenter: {
    icon: enhancedVerticalCenter,
  },
  overlayBottomLeft: {
    icon: overlayBottomLeftIcon,
  },
  overlayBottomRight: {
    icon: overlayBottomRightIcon,
  },
  overlayTopLeft: {
    icon: overlayTopLeftIcon,
  },
  overlayTopRight: {
    icon: overlayTopRightIcon,
  },
};

export const INITIAL_VIEW = {
  fullscreen: 'fullscreen',
  framed: 'framed',
  frameOffsetBottom: 'frameOffsetBottom',
};

export const INITIAL_VIEW_PARAMS = {
  [INITIAL_VIEW.fullscreen]: {
    initialContentY: 0,
    initialContentX: 0,
    initialContentScale: 1,
  },
  [INITIAL_VIEW.framed]: {
    initialContentY: 0.1,
    initialContentX: 0.1,
    initialContentScale: 0.8,
  },
  [INITIAL_VIEW.frameOffsetBottom]: {
    initialContentY: 0.05,
    initialContentX: 0.17,
    initialContentScale: 0.66,
  },
};

export const INITIAL_VIEW_DISPLAY_NAMES = {
  fullscreen: 'Fullscreen',
  framed: 'Framed',
  frameOffsetBottom: 'Frame (Offset Bottom)',
};

export const INITIAL_VIEW_ICONS = {
  fullscreen: fullscreenIcon,
  framed: framedIcon,
  frameOffsetBottom: frameOffsetBottomIcon,
};

export const getAdLayout = async (accountId) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/ad-layouts`;
  return axios.get(url).then((response) => {
    return response.data;
  });
};

export const AD_LAYOUT_PROP_TYPE = PropTypes.shape({
  name: PropTypes.string.isRequired,
  formatName: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  creativeScalePercent: PropTypes.number,
  creativeMarginTopPercent: PropTypes.number,
  creativeMarginRightPercent: PropTypes.number,
  creativeMarginBottomPercent: PropTypes.number,
  creativeMarginLeftPercent: PropTypes.number,
  contentScalePercent: PropTypes.number,
  contentMarginTopPercent: PropTypes.number,
  contentMarginRightPercent: PropTypes.number,
  contentMarginBottomPercent: PropTypes.number,
  contentMarginLeftPercent: PropTypes.number,
  contentHorizontalAlignment: PropTypes.string,
  contentVerticalAlignment: PropTypes.string,
  creativeHorizontalAlignment: PropTypes.string,
  creativeVerticalAlignment: PropTypes.string,
  logoHorizontalAlignment: PropTypes.string,
  logoMarginPercent: PropTypes.number,
  overlayVerticalAlignment: PropTypes.string,
  overlayHorizontalAlignment: PropTypes.string,
  overlayScalePercent: PropTypes.number,
  initialContentY: PropTypes.number,
  initialContentX: PropTypes.number,
  initialContentScale: PropTypes.number,
});

export const getInitialViewKey = ({ initialContentX, initialContentY, initialContentScale }) => {
  for (const [key, { initialContentX: x, initialContentY: y, initialContentScale: scale }] of Object.entries(
    INITIAL_VIEW_PARAMS
  )) {
    if (x === initialContentX && y === initialContentY && scale === initialContentScale) return key;
  }
  return null;
};

export const DEFAULT_INITIAL_VIEW_PARAMS = INITIAL_VIEW_PARAMS[INITIAL_VIEW.fullscreen];

const getBorderWidth = (resolutionHeight) => {
  if (resolutionHeight < 1440) return 2;
  return 4;
};

export const ASPECT_RATIOS = {
  nineBySixteen: { key: 'nineBySixteen', value: 9 / 16 }, // vertical widescreen
  sixteenByNine: { key: 'sixteenByNine', value: 16 / 9 }, // horizontal widescreen
  fourByThree: { key: 'fourByThree', value: 4 / 3 }, // standard
};

export const determineResolutionWithError = ({ width, height, errorMarginPercent = 3 }) => {
  const { sixteenByNine, fourByThree, nineBySixteen } = ASPECT_RATIOS;

  const initialAspect = width / height;
  const sixteenByNineDelta = Math.abs(initialAspect - sixteenByNine.value);
  const sixteenByNineErrorMargin = sixteenByNine.value * (errorMarginPercent / 100);
  const nineBySixteenDelta = Math.abs(initialAspect - nineBySixteen.value);
  const nineBySixteenErrorMargin = nineBySixteen.value * (errorMarginPercent / 100);
  const fourByThreeDelta = Math.abs(initialAspect - fourByThree.value);
  const fourByThreeErrorMargin = fourByThree.value * (errorMarginPercent / 100);

  if (sixteenByNineDelta <= sixteenByNineErrorMargin) {
    return sixteenByNine;
  }
  if (nineBySixteenDelta <= nineBySixteenErrorMargin) {
    return nineBySixteen;
  }
  if (fourByThreeDelta <= fourByThreeErrorMargin) {
    return fourByThree;
  }
  return {};
};

export const generatePipLayout = ({
  creativeScalePercent = 60,
  contentScalePercent = 40,
  creativeMarginTopPercent = 13.5,
  creativeMarginRightPercent = 2.3,
  creativeMarginBottomPercent = 0,
  creativeMarginLeftPercent = 1.15,
  contentMarginTopPercent = 25.5,
  contentMarginRightPercent = 1.15,
  contentMarginBottomPercent = 0,
  contentMarginLeftPercent = 2.3,
  contentVerticalAlignment = verticalAlignment.top,
  creativeVerticalAlignment = verticalAlignment.top,
  contentHorizontalAlignment = horizontalAlignment.left,
  creativeHorizontalAlignment = horizontalAlignment.right,
  logoHorizontalAlignment = horizontalAlignment.contentCenter,
  logoMarginPercent = 2.3,
  logoWidth = 0,
  logoHeight = 0,
  canvasWidth = 853.33,
  canvasHeight = 480,
  borderColor = null,
  overlayHorizontalAlignment = '',
  overlayVerticalAlignment = '',
  overlayScalePercent = 0,
  overlayWidth = 0,
  overlayHeight = 0,
  liveDataMarginTopPercent = null,
  liveDataMarginLeftPercent = null,
  liveDataWidthPercent = null,
  liveDataHeightPercent = null,
  creativeAspectRatio = ASPECT_RATIOS.sixteenByNine.key,
}) => {
  const aspectRatio = determineResolutionWithError({
    width: canvasWidth,
    height: canvasHeight,
  });

  const aspectRatioScalar = aspectRatio.value;
  if (!aspectRatioScalar) {
    throw new Error('canvas dimensions for pip input are not within acceptable ranges');
  }

  const border = borderColor ? getBorderWidth(canvasHeight) : 0;
  const noCreative = !creativeScalePercent;

  const creativeScaleMultiplier = creativeScalePercent / 100;
  const contentScaleMultiplier = contentScalePercent / 100;

  const creativeMarginTop = canvasHeight * (creativeMarginTopPercent / 100);
  const creativeMarginBottom = canvasHeight * (creativeMarginBottomPercent / 100);
  const creativeMarginLeft = canvasWidth * (creativeMarginLeftPercent / 100);
  const creativeMarginRight = canvasWidth * (creativeMarginRightPercent / 100);

  const creativeWidthCanvas = canvasWidth * creativeScaleMultiplier;
  const adjustedCreativeScaleMultiplier =
    (creativeWidthCanvas - creativeMarginLeft - creativeMarginRight) / canvasWidth;

  const verticalCreative = creativeAspectRatio === ASPECT_RATIOS.nineBySixteen.key;

  let creativeWidth = canvasWidth * adjustedCreativeScaleMultiplier;
  let creativeHeight = verticalCreative ? creativeWidth * aspectRatioScalar : creativeWidth / aspectRatioScalar;

  let creativeHeightWithBorder = creativeHeight + 2 * border;

  if (verticalCreative && creativeHeightWithBorder > canvasHeight) {
    creativeHeightWithBorder = canvasHeight;
    creativeHeight = creativeHeightWithBorder - 2 * border;
    creativeWidth = verticalCreative ? creativeHeight / aspectRatioScalar : creativeHeight * aspectRatioScalar;
  }

  const creativeWidthWithBorder = creativeWidth + 2 * border;

  let creativeX;
  switch (creativeHorizontalAlignment) {
    case horizontalAlignment.center:
      creativeX = canvasWidth / 2 - creativeWidthWithBorder / 2 + creativeMarginLeft;
      break;
    case horizontalAlignment.right:
      creativeX = canvasWidth - (creativeMarginRight + creativeWidthWithBorder);
      break;
    case horizontalAlignment.left:
    default:
      creativeX = creativeMarginLeft;
      break;
  }

  let creativeY;
  switch (creativeVerticalAlignment) {
    case verticalAlignment.middle:
      creativeY = canvasHeight / 2 - creativeHeightWithBorder / 2 + creativeMarginTop;
      break;
    case verticalAlignment.bottom:
      creativeY = canvasHeight - creativeHeightWithBorder + creativeMarginBottom;
      break;
    case verticalAlignment.top:
    default:
      creativeY = creativeMarginTop;
      break;
  }

  const contentMarginTop = canvasHeight * (contentMarginTopPercent / 100);
  const contentMarginBottom = canvasHeight * (contentMarginBottomPercent / 100);
  const contentMarginLeft = canvasWidth * (contentMarginLeftPercent / 100);
  const contentMarginRight = canvasWidth * (contentMarginRightPercent / 100);

  const contentWidthCanvas = canvasWidth * contentScaleMultiplier;

  const adjustedContentScaleMultiplier = noCreative
    ? contentWidthCanvas / canvasWidth
    : (contentWidthCanvas - contentMarginLeft - contentMarginRight) / canvasWidth;

  let contentWidth = canvasWidth * adjustedContentScaleMultiplier;
  let contentHeight = contentWidth / aspectRatioScalar;
  let contentWidthWithBorder = contentWidth + 2 * border;

  if (verticalCreative && contentWidth + border * 3 + creativeWidth > canvasWidth) {
    contentWidthWithBorder = canvasWidth - creativeWidth - border;
    contentWidth = contentWidthWithBorder - 2 * border;
    contentHeight = contentWidth / aspectRatioScalar;
  }

  const contentHeightWithBorder = contentHeight + 2 * border;

  let contentX;
  switch (contentHorizontalAlignment) {
    case horizontalAlignment.center:
      contentX = canvasWidth / 2 - contentWidthWithBorder / 2 + contentMarginLeft;
      break;
    case horizontalAlignment.right:
      contentX = canvasWidth - contentWidthWithBorder;
      break;
    case horizontalAlignment.left:
    default:
      contentX = contentMarginLeft;
      break;
  }

  let contentY;
  switch (contentVerticalAlignment) {
    case verticalAlignment.middle:
      contentY = canvasHeight / 2 - contentHeightWithBorder / 2 + contentMarginTop;
      break;
    case verticalAlignment.bottom:
      contentY = canvasHeight - contentHeightWithBorder + contentMarginBottom;
      break;
    case verticalAlignment.top:
    default:
      contentY = contentMarginTop;
      break;
  }

  const videoContentScaledBounds = {
    x: contentX,
    y: contentY,
    w: contentWidth,
    h: contentHeight,
    border,
  };

  const videoCreativeScaledBounds = {
    x: creativeX,
    y: creativeY,
    w: creativeWidth,
    h: creativeHeight,
    border,
  };

  const creativeBottom = videoCreativeScaledBounds.y + videoCreativeScaledBounds.h;
  const contentBottom = videoContentScaledBounds.y + videoContentScaledBounds.h;

  let relativeLogoBounds = {};
  switch (logoHorizontalAlignment) {
    case horizontalAlignment.center:
      relativeLogoBounds = creativeBottom > contentBottom ? videoCreativeScaledBounds : videoContentScaledBounds;
      break;
    case horizontalAlignment.contentCenter:
      relativeLogoBounds = videoContentScaledBounds;
      break;
    case horizontalAlignment.creativeCenter:
      relativeLogoBounds = videoCreativeScaledBounds;
      break;

    default:
      break;
  }

  const logoMargin = canvasWidth * (logoMarginPercent / 100);
  const logoHContainer =
    canvasHeight - relativeLogoBounds.y - relativeLogoBounds.h - logoMargin * 2 - relativeLogoBounds.border * 2;
  const logoWContainer = relativeLogoBounds.w - logoMargin * 2 - relativeLogoBounds.border * 2;
  let logoY = relativeLogoBounds.y + relativeLogoBounds.h + logoMargin;
  const logoHeightMultiplier = logoHContainer / logoHeight;
  const logoWidthMultiplier = logoWContainer / logoWidth;
  const logoMultiplier = Math.min(logoHeightMultiplier, logoWidthMultiplier);
  let scaledLogoWidth = logoWidth;
  let scaledLogoHeight = logoHeight;

  if (logoMultiplier < 1) {
    scaledLogoWidth *= logoMultiplier;
    scaledLogoHeight *= logoMultiplier;
  }

  let logoX;
  logoY =
    logoMargin +
    relativeLogoBounds.h +
    relativeLogoBounds.y +
    (canvasHeight - relativeLogoBounds.h - relativeLogoBounds.y - scaledLogoHeight - logoMargin * 2) / 2;

  switch (logoHorizontalAlignment) {
    case horizontalAlignment.center:
      logoX = logoMargin + (canvasWidth - scaledLogoWidth - logoMargin * 2) / 2;
      break;
    case horizontalAlignment.contentCenter:
      logoX =
        logoMargin +
        relativeLogoBounds.x +
        relativeLogoBounds.border +
        (contentWidth - scaledLogoWidth - logoMargin * 2) / 2;
      break;
    case horizontalAlignment.creativeCenter:
      logoX =
        logoMargin +
        relativeLogoBounds.x +
        relativeLogoBounds.border +
        (creativeWidth - scaledLogoWidth - logoMargin * 2) / 2;
      break;

    default:
      break;
  }

  const logoScaledBounds = {
    x: logoX,
    y: logoY,
    w: scaledLogoWidth,
    h: scaledLogoHeight,
  };

  // TODO consider margins for overlays
  const initScaledOverlayWidth = canvasWidth * overlayScalePercent * 0.01;
  const initScaledOverlayHeight = canvasHeight * overlayScalePercent * 0.01;
  const widthScaleFactor = initScaledOverlayWidth / overlayWidth;
  const heightScaleFactor = initScaledOverlayHeight / overlayHeight;
  const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);
  const scaledOverlayWidth = scaleFactor * overlayWidth;
  const scaledOverlayHeight = scaleFactor * overlayHeight;

  let overlayX;
  switch (overlayHorizontalAlignment) {
    case horizontalAlignment.right:
      overlayX = canvasWidth - scaledOverlayWidth; // - margin once added
      break;
    case horizontalAlignment.left:
    default:
      overlayX = 0; // + margin once added
      break;
  }

  let overlayY;
  switch (overlayVerticalAlignment) {
    case verticalAlignment.top:
      overlayY = 0; // + margin once added
      break;
    case verticalAlignment.bottom:
    default:
      overlayY = canvasHeight - scaledOverlayHeight; // - margin once added
      break;
  }

  const overlayScaledBounds = {
    x: overlayX,
    y: overlayY,
    w: scaledOverlayWidth,
    h: scaledOverlayHeight,
  };

  let liveDataScaledBounds = {};
  const showLiveData = liveDataHeightPercent > 0 && liveDataWidthPercent > 0;
  if (showLiveData) {
    liveDataScaledBounds = {
      h: canvasHeight * liveDataHeightPercent * 0.01,
      w: canvasWidth * liveDataWidthPercent * 0.01,
      y: canvasHeight * liveDataMarginTopPercent * 0.01,
      x: canvasWidth * liveDataMarginLeftPercent * 0.01,
    };
  }

  return {
    overlayScaledBounds,
    logoScaledBounds,
    videoContentScaledBounds,
    videoCreativeScaledBounds,
    liveDataScaledBounds,
  };
};
