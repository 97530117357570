import { useState, useEffect } from 'react';
import {
  CompressRounded,
  KeyboardDoubleArrowRightRounded,
  SlideshowRounded,
  // AttachFileRounded,
  // ContentCopyRounded,
  // LinkRounded,
  // SyncRounded,
} from '@mui/icons-material';
import { TRANSMIT_AD_SERVER } from '../../../services/creative-service';
import { AD_FORMATS } from '../../../services/layout-service';
import { StudioAdType } from '../hooks/useStudioAdType';

export const SCENE_NAMES = {
  bumper: 'bumper',
  introSlate: 'intro-slate',
  initialView: 'initial-view',
  adContent: 'ad',
  // outroTrans: 'outro-trans',  // uncomment once enabled
  transitionAnimation: 'transition-animation',
  outroSlate: 'outro-slate',
};

export const urlToId = (urlString) => {
  if (!urlString) return null;
  const url = new URL(urlString);
  return url.pathname.split('/').pop()?.split('.')[0];
};

const inBreakAdFormatToScenes = {
  [AD_FORMATS.pip.key]: [
    SCENE_NAMES.bumper,
    SCENE_NAMES.introSlate,
    SCENE_NAMES.transitionAnimation,
    SCENE_NAMES.initialView,
    SCENE_NAMES.adContent,
    SCENE_NAMES.transitionAnimation,
    SCENE_NAMES.outroSlate,
  ],
  [AD_FORMATS.overlay.key]: [
    SCENE_NAMES.bumper,
    SCENE_NAMES.introSlate,
    SCENE_NAMES.transitionAnimation,
    SCENE_NAMES.adContent,
    SCENE_NAMES.transitionAnimation,
    SCENE_NAMES.outroSlate,
  ],
  [AD_FORMATS.squeezeBack.key]: [
    SCENE_NAMES.bumper,
    SCENE_NAMES.introSlate,
    SCENE_NAMES.transitionAnimation,
    SCENE_NAMES.adContent,
    SCENE_NAMES.transitionAnimation,
    SCENE_NAMES.outroSlate,
  ],
  [AD_FORMATS.interstitial.key]: [SCENE_NAMES.adContent],
  [AD_FORMATS.enhancedCreative.key]: [SCENE_NAMES.initialView, SCENE_NAMES.adContent],
};

const inStreamAdFormatToScenes = {
  [AD_FORMATS.pip.key]: [SCENE_NAMES.adContent],
  [AD_FORMATS.overlay.key]: [SCENE_NAMES.adContent],
  [AD_FORMATS.squeezeBack.key]: [SCENE_NAMES.adContent],
  [AD_FORMATS.interstitial.key]: [SCENE_NAMES.adContent],
};

const studioAdTypeAdFormats = {
  [StudioAdType.IN_BREAK]: inBreakAdFormatToScenes,
  [StudioAdType.IN_STREAM]: inStreamAdFormatToScenes,
};

const allScenes = {
  [SCENE_NAMES.bumper]: {
    name: SCENE_NAMES.bumper,
    displayName: 'Bumper',
    icon: CompressRounded,
    enabled: true,
    activeOptions: [
      // {
      //   linkName: 'Change',
      //   icon: SyncRounded,
      //   onClick: () => { },
      // },
      // { // uncomment if/when enabled
      //   linkName: 'Copy to Outro',
      //   icon: ContentCopyRounded,
      //   onClick: () => { },
      // },
    ],
  },
  [SCENE_NAMES.introSlate]: {
    name: SCENE_NAMES.introSlate,
    displayName: 'Intro Slate',
    icon: KeyboardDoubleArrowRightRounded,
    enabled: true,
    activeOptions: [
      // {
      //   linkName: 'Upload File',
      //   icon: AttachFileRounded,
      //   onClick: () => { },
      // },
      // {
      //   linkName: 'Copy to Outro',
      //   icon: ContentCopyRounded,
      //   onClick: () => { },
      // },
    ],
  },
  [SCENE_NAMES.initialView]: {
    name: SCENE_NAMES.initialView,
    displayName: 'Initial View',
    icon: SlideshowRounded,
    enabled: true,
    activeOptions: [
      // {
      //   linkName: 'Change Initial View',
      //   icon: AttachFileRounded,
      //   onClick: () => { },
      // },
    ],
  },
  [SCENE_NAMES.adContent]: {
    name: SCENE_NAMES.adContent,
    displayName: 'Ad Content',
    icon: SlideshowRounded,
    enabled: true,
    activeOptions: [
      // {
      //   linkName: 'Upload File',
      //   icon: AttachFileRounded,
      //   onClick: () => { },
      // },
      // {
      //   linkName: 'VAST Tag',
      //   icon: LinkRounded,
      //   onClick: () => { },
      // },
    ],
  },
  [SCENE_NAMES.outroSlate]: {
    name: SCENE_NAMES.outroSlate,
    displayName: 'Outro Slate',
    icon: KeyboardDoubleArrowRightRounded,
    enabled: true,
    activeOptions: [
      // {
      //   linkName: 'Upload File',
      //   icon: AttachFileRounded,
      //   onClick: () => { },
      // },
      // {
      //   linkName: 'Copy to Intro',
      //   icon: ContentCopyRounded,
      //   onClick: () => { },
      // },
    ],
  },
  [SCENE_NAMES.outroTrans]: {
    name: SCENE_NAMES.outroTrans,
    displayName: 'Outro Transition',
    icon: CompressRounded,
    enabled: false, // make true if/when enabled
    activeOptions: [
      // {
      //   linkName: 'Change',
      //   icon: SyncRounded,
      //   onClick: () => { },
      // },
      // {
      //   linkName: 'Copy to Intro',
      //   icon: ContentCopyRounded,
      //   onClick: () => { },
      // },
    ],
  },
  [SCENE_NAMES.transitionAnimation]: {
    name: SCENE_NAMES.transitionAnimation,
    displayName: 'Transition Animation',
    icon: CompressRounded,
    enabled: true, // make true if/when enabled
    activeOptions: [
      // {
      //   linkName: 'Change',
      //   icon: SyncRounded,
      //   onClick: () => { },
      // },
      // {
      //   linkName: 'Copy to Intro',
      //   icon: ContentCopyRounded,
      //   onClick: () => { },
      // },
    ],
  },
};

export const noTransitionLabels = {
  noAnimationAllowed: 'No transition animation without at least one slate',
  noAnimationSelected: 'No transition animation selected',
  noSlateSelected: 'No transition slate selected',
};

export const createScenes = ({ studioAdType, adFormat }) => {
  if (!studioAdType) {
    return [];
  }

  return studioAdTypeAdFormats[studioAdType][adFormat].map((sceneName) => allScenes[sceneName]);
};

export const useAssetName = ({ type, url = null, id = null, accountId, advId, adServer = null, fetchAsset }) => {
  const noFileMessage = 'No file selected';
  const vastMessage = 'VAST URL';
  const [name, setName] = useState(noFileMessage);

  useEffect(() => {
    const assetId = id || urlToId(url);
    if (assetId) {
      const fetchAdParams = {
        accountId,
        advertiserId: advId,
        assetType: type,
        assetId,
      };
      fetchAsset(fetchAdParams).then((asset) => setName(asset?.name));
    } else if (['overlay', 'video'].includes(type) && adServer && adServer !== TRANSMIT_AD_SERVER) {
      setName(vastMessage);
    } else {
      setName(noFileMessage);
    }
  }, [url, id, accountId, advId, adServer]);
  return name;
};
