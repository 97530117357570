import React, { useEffect, useMemo } from 'react';
import { Box, Button, Container } from '@mui/material';
import classNames from 'classnames';

import { NorthEast } from '@mui/icons-material';
import styles from '../site.module.css';
import reStyles from './reach-extension.module.css';
import homeStyles from '../home/home.module.css';
import ExtensionIcon from '../svg/extension-icon';
import TabbedSectionContent from '../tabbed-section/tabbed-section-content';

import TabbedSection from '../tabbed-section/tabbed-section';

// TODO: move to s3
import reKeyPlays from './re-key-plays.png';
import RequestDemoBanner from '../request-demo-banner';

const TABS = [
  {
    title: 'Creatives',
    heading: 'Use content to build creatives',
    description: 'Discover how Transmit enables you to seamlessly expand ad inventory and better engage your audience.',
    imgSrc: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-se-creatives.png',
    page: 'extension',
  },
  {
    title: 'VAST',
    heading: 'Output Vast 4.0',
    description:
      'VAST-compliant tags for seamless implementation on your owned & operated inventory and in the open marketplace.',
    imgSrc: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-se-vast.png',
    page: 'extension',
  },
  {
    title: 'Multiple Formats',
    heading: 'Support multiple formats',
    description: 'Configure templates to render in variety of formats.',
    imgSrc: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-se-multiple-formats.png',
    page: 'extension',
  },
];

const ReachExtension = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    TABS.forEach((tab) => {
      new Image().src = tab.imgSrc;
    });
  }, []);

  const tabs = useMemo(() => {
    return TABS.map((tab) => {
      return {
        title: tab.title,
        content: (
          <TabbedSectionContent
            key={tab.title}
            tabTitle={tab.title}
            heading={tab.heading}
            description={tab.description}
            page={tab.page}
          >
            <div>
              <img src={tab.imgSrc} alt="" />
            </div>
          </TabbedSectionContent>
        ),
      };
    });
  }, []);

  return (
    <div>
      <Container maxWidth="xl" className={classNames(styles.container, reStyles.reachExtensionContainer)}>
        <div className={classNames(reStyles.reHeaderWrapper)}>
          <div className={classNames(reStyles.reHeaderContainer, styles.xHidden)}>
            <div className={reStyles.reHeadingContainer}>
              <div>
                <div className={reStyles.headingLogoContainer}>
                  <ExtensionIcon />
                  <p className={styles.headerIconDesc}>Stream Extension</p>
                </div>
                <h1 className={styles.heading}>
                  <span>Extend</span> your reach, increase your inventory
                </h1>
                <div className={classNames(styles.description, styles.narrow, homeStyles.cardsDesc)}>
                  Leverage our VAST 4.0 output to quickly integrate Transmit within your ad server.
                </div>

                <Button
                  endIcon={<NorthEast />}
                  className={classNames(styles.button, styles.mt2, reStyles.getStarted)}
                  href="/request-demo"
                >
                  Get Started
                </Button>
              </div>
            </div>

            <div className={classNames(reStyles.reHeader)}>
              <div className={classNames(reStyles.reHeaderGrid)}>
                <video
                  className={classNames(reStyles.reHeaderImg, reStyles.reHeader1)}
                  autoPlay
                  playsInline
                  muted
                  loop
                  src="https://tl-open-content.s3.amazonaws.com/web-assets/optimized.mp4"
                />
                <div className={classNames(reStyles.reHeaderImg, reStyles.reHeader2)} />
                <div className={classNames(reStyles.reHeaderImg, reStyles.reHeader3)} />
                <div className={classNames(reStyles.reHeaderImg, reStyles.reHeaderOffset, reStyles.reHeader4)} />
                <video
                  className={classNames(reStyles.reHeaderImg, reStyles.reHeaderOffset, reStyles.reHeader5)}
                  autoPlay
                  playsInline
                  muted
                  loop
                  src="https://tl-open-content.s3.amazonaws.com/web-assets/nba-pip.mp4"
                />
                <div className={classNames(reStyles.reHeaderImg, reStyles.reHeaderOffset, reStyles.reHeader6)} />
                <video
                  className={classNames(reStyles.reHeaderImg, reStyles.reHeader7)}
                  autoPlay
                  playsInline
                  muted
                  loop
                  src="https://tl-open-content.s3.amazonaws.com/web-assets/demo-extension-next-on.mp4"
                />
                <div className={classNames(reStyles.reHeaderImg, reStyles.reHeader8)} />
                <div className={classNames(reStyles.reHeaderImg, reStyles.reHeader9)} />
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.sectionCardContainer, reStyles.increaseYourSupply)}>
          <div className={classNames(styles.sectionCard, reStyles.increaseSupplyCard)}>
            <div className={styles.centerBlock}>
              <h2 className={styles.heading}>Increase your supply</h2>
              <Box className={classNames(styles.description, styles.narrow, homeStyles.cardsDesc)}>
                Maximize your content&apos;s monetization potential while boosting awareness.
              </Box>
            </div>

            <div>
              <TabbedSection tabs={tabs} />
            </div>
          </div>
        </div>

        <div className={classNames(styles.centerBlock, reStyles.cardsWrapper, reStyles.horizontalGutter)}>
          <div className={styles.cardsContainer}>
            <h2 className={styles.heading}>Promote your content</h2>
            <div className={classNames(styles.flex, styles.justifyCenter)}>
              <Box className={classNames(styles.description, styles.narrow, homeStyles.cardsDesc)}>
                Merge your content and brand advertisments to create unique and compelling monetization opportunities.
              </Box>
            </div>
            <div className={classNames(styles.mt4, reStyles.reFeatureCards, styles.centerBlock)}>
              <div className={classNames(styles.miniCard, reStyles.reFeatureCard, styles.mobileBorderRadius)}>
                <div className={classNames(styles.miniCardHeader, reStyles.reMiniCardHeader)}>
                  <h3 className={styles.heading}>Feature key plays</h3>
                  <div className={classNames(styles.description, homeStyles.cardsDesc)}>
                    Combine game highlights with brand advertisements to build promotional creatives.
                  </div>
                </div>
                <div className={reStyles.featureCardImgContainer}>
                  <img src={reKeyPlays} alt="" />
                </div>
              </div>
              <div
                className={classNames(
                  styles.miniCard,
                  reStyles.reFeatureCard,
                  reStyles.increasedViewershipCard,
                  styles.mobileBorderRadius
                )}
              >
                <div className={classNames(styles.miniCardHeader, reStyles.reMiniCardHeader)}>
                  <h3 className={styles.heading}>Drive increased viewership</h3>
                  <div className={classNames(styles.description, homeStyles.cardsDesc)}>
                    Stream Extension creates more inventory for your content and alerts targeted viewers who may be
                    watching different programming.
                  </div>
                </div>
                <div className={reStyles.increasedViewershipCardImgContainer}>
                  <img src="https://tl-open-content.s3.amazonaws.com/web-assets/increased-viewership-img.jpeg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(styles.centerBlock, homeStyles.requestDemoWrapper, reStyles.requestDemoWrapper)}
          style={{ padding: 0 }}
        >
          <RequestDemoBanner />
        </div>
      </Container>
    </div>
  );
};

export default ReachExtension;
