import React from 'react';
import { useMatch } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';

import styles from './site.module.css';
import TLLink from '../../tl-link';
import TlLogoBlack from './svg/tl-logo-black';
import TlLogoWhite from './svg/tl-logo-white';

const Footer = () => {
  const csRouteActive = useMatch('/creative-studio');
  const smallerScreen = useMediaQuery('(max-width: 900px)');

  return smallerScreen ? (
    <div className={csRouteActive ? styles.blackFooter : ''}>
      <div className={classNames(styles.footer, styles.flexCol)} style={{ gap: 32 }}>
        <div className={classNames(styles.footerCopyright, styles.flexRow, styles.fullWidth, styles.justifyBetween)}>
          <div>
            <TLLink href="/" style={{ height: 44 }}>
              {csRouteActive ? <TlLogoWhite /> : <TlLogoBlack />}
            </TLLink>
          </div>
        </div>
        <div className={classNames(styles.footerLinkGrid, styles.fullWidth)} style={{ margin: 0, rowGap: 41 }}>
          <div>
            <div className={classNames(styles.footerLinkHeader)} style={{ gridColumn: '1 / 3', gridRow: 1 }}>
              Product
            </div>
            <div>
              <TLLink href="/stream-composer">Stream Composer</TLLink>
            </div>
            <div>
              <TLLink href="/stream-extension">Stream Extension</TLLink>
            </div>
            <div>
              <TLLink href="/creative-studio">Creative Studio</TLLink>
            </div>
            <div>
              <TLLink href="/demo/sports">Demos</TLLink>
            </div>
          </div>
          <div className={classNames(styles.flex, styles.flexCol)} style={{ justifyContent: 'end' }}>
            <div>
              <TLLink href="/careers">Careers</TLLink>
            </div>
            <div>
              <TLLink href="/press">Press</TLLink>
            </div>
            <div>
              <TLLink href="/request-demo">Request a Demo</TLLink>
            </div>
            <div>
              <a href="mailto:contact@transmit.live">Contact Us</a>
            </div>
          </div>
          <div>
            <div className={classNames(styles.footerLinkHeader)}>Policies</div>
            <div>
              <TLLink href="/privacy">Privacy Policy</TLLink>
            </div>
            <div>
              <TLLink href="/security">Security Policy</TLLink>
            </div>
          </div>
          <div>
            <div className={classNames(styles.footerLinkHeader)}>Follow Us</div>
            <div>
              <a href="https://twitter.com/Transmit" target="_blank" rel="noreferrer">
                X
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/transmit.live/" target="_blank" rel="noreferrer">
                Instagram
              </a>
            </div>
            <div>
              <a href="https://www.linkedin.com/company/transmitlive/" target="_blank" rel="noreferrer">
                LinkedIn
              </a>
            </div>
          </div>
        </div>
        <div
          className={classNames(styles.footerCopyright, styles.flexCol, styles.fullWidth, styles.alignCenter)}
          style={{ gap: 35 }}
        >
          <div className={classNames(styles.footerIconsGrid, styles.footerGridCenter)}>
            <img
              className={classNames(styles.iabIcon)}
              src={
                csRouteActive
                  ? 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/iab_white.svg'
                  : 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/iab.svg'
              }
              alt="iab Member"
              title="iab Member"
              width="71px"
            />
            <img
              src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/tag.svg"
              alt="Tag Registered"
              title="Tag Registered"
              width="47px"
            />
            <img
              width="44px"
              src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/fast-company@2x.png"
              alt="Fast Company Most Innovative Companies 2022"
              title="Fast Company Most Innovative Companies 2022"
              style={{ borderRadius: 10 }}
            />
          </div>
          <div className={classNames(styles.flex, styles.flexCol, styles.alignCenter)} style={{ gap: 11 }}>
            <span className={classNames(styles.copyrightText)}>
              Site by{' '}
              <a
                className={(styles.copyrightText, styles.wheelhouseLink)}
                href="https://www.wheelhouse.io/"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: 12 }}
              >
                Wheelhouse.
              </a>
            </span>
            <div className={classNames(styles.copyrightText)}>
              © {new Date().getFullYear()} Transmit. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={csRouteActive ? styles.blackFooter : ''}>
      <div className={classNames(styles.footer)}>
        <div className={classNames(styles.flex)}>
          <div className={styles.footerCopyright}>
            <TLLink href="/" style={{ height: 44 }}>
              {csRouteActive ? <TlLogoWhite /> : <TlLogoBlack />}
            </TLLink>
            <div className={classNames(styles.footerIconsGrid, styles.footerGridCenter)}>
              <img
                className={classNames(styles.iabIcon)}
                src={
                  csRouteActive
                    ? 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/iab_white.svg'
                    : 'https://dxgcj7vu1u7nu.cloudfront.net/web-assets/iab.svg'
                }
                alt="iab Member"
                title="iab Member"
                width="60px"
              />
              <img
                src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/tag.svg"
                alt="Tag Registered"
                title="Tag Registered"
                width="40px"
              />
              <img
                width="37px"
                src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/fast-company@2x.png"
                alt="Fast Company Most Innovative Companies 2022"
                title="Fast Company Most Innovative Companies 2022"
              />
            </div>
          </div>
          <div className={styles.footerLinkGrid}>
            <div className={classNames(styles.footerLinkHeader)} style={{ gridColumn: '1 / 3', gridRow: 1 }}>
              Product
            </div>
            <div style={{ gridColumn: 1, gridRow: 2 }}>
              <TLLink href="/stream-composer">Stream Composer</TLLink>
            </div>
            <div style={{ gridColumn: 1, gridRow: 3 }}>
              <TLLink href="/stream-extension">Stream Extension</TLLink>
            </div>
            <div style={{ gridColumn: 1, gridRow: 4 }}>
              <TLLink href="/creative-studio">Creative Studio</TLLink>
            </div>
            <div style={{ gridColumn: 1, gridRow: 5 }}>
              <TLLink href="/demo/sports">Demos</TLLink>
            </div>
            <div style={{ gridColumn: 2, gridRow: 2 }}>
              <TLLink href="/careers">Careers</TLLink>
            </div>
            <div style={{ gridColumn: 2, gridRow: 3 }}>
              <TLLink href="/press">Press</TLLink>
            </div>
            <div style={{ gridColumn: 2, gridRow: 4 }}>
              <TLLink href="/request-demo">Request a Demo</TLLink>
            </div>
            <div style={{ gridColumn: 2, gridRow: 5 }}>
              <a href="mailto:contact@transmit.live">Contact Us</a>
            </div>
            <div className={classNames(styles.footerLinkHeader)} style={{ gridColumn: 3, gridRow: 1 }}>
              Policies
            </div>
            <div style={{ gridColumn: 3, gridRow: 2 }}>
              <TLLink href="/privacy">Privacy Policy</TLLink>
            </div>
            <div style={{ gridColumn: 3, gridRow: 3 }}>
              <TLLink href="/security">Security Policy</TLLink>
            </div>
            <div className={classNames(styles.footerLinkHeader)} style={{ gridColumn: 4, gridRow: 1 }}>
              Follow Us
            </div>
            <div style={{ gridColumn: 4, gridRow: 2 }}>
              <a href="https://twitter.com/Transmit" target="_blank" rel="noreferrer">
                X
              </a>
            </div>
            <div style={{ gridColumn: 4, gridRow: 3 }}>
              <a href="https://www.instagram.com/transmit.live/" target="_blank" rel="noreferrer">
                Instagram
              </a>
            </div>
            <div style={{ gridColumn: 4, gridRow: 4 }}>
              <a href="https://www.linkedin.com/company/transmitlive/" target="_blank" rel="noreferrer">
                LinkedIn
              </a>
            </div>
          </div>
        </div>
        <div className={classNames(styles.flex, styles.justifyBetween)}>
          <span className={classNames(styles.copyrightText)}>
            © {new Date().getFullYear()} Transmit. All Rights Reserved.
          </span>
          <span className={classNames(styles.copyrightText)}>
            Site by{' '}
            <a
              className={(styles.copyrightText, styles.wheelhouseLink)}
              href="https://www.wheelhouse.io/"
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: 12 }}
            >
              Wheelhouse.
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
