import axios from 'axios';
import { DEFAULT_PREVIEW_CONTENT } from './layout-service';

export const getPreviewEncode = async (accountId, params) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/preview-encode`;
  return axios.post(url, params).then((response) => {
    return response.data;
  });
};

export const getAllPreviewInventoryData = async ({ accountId, search, demoAccount = 'false', filters }) => {
  const params = new URLSearchParams({ demoAccount });
  if (search) {
    const searchEntries = new URLSearchParams(search).entries();
    for (const [key, value] of searchEntries) {
      params.append(key, value);
    }
  }
  const { accounts = {}, tags = {} } = filters || {};
  if (Object.keys(accounts).length > 0) {
    params.append('accounts', Object.keys(accounts).join(','));
  }
  if (Object.keys(tags).length > 0) {
    params.append('tags', Object.keys(tags).join(','));
  }

  const url = `${SERVICE_URL}/api/v1/account/${accountId}/preview-inventory?${params.toString()}`;
  return axios.get(url).then((response) => {
    return response.data;
  });
};

export const getPreviewInventoryData = async (accountId, previewInventoryId) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/preview-inventory/${previewInventoryId}`;
  return axios.get(url).then((response) => {
    if (!response.data) return DEFAULT_PREVIEW_CONTENT;
    return response.data;
  });
};

export const getPreviewInventoryFilterOptions = async (accountId, demoAccount = false) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/preview-inventory/filter-options?demoAccount=${demoAccount}`;
  return axios.get(url).then((response) => {
    return response.data;
  });
};
