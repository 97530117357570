import { jwtScopes } from '../../services/user-service';
import { RECEIVE_USER, LOGIN_SUCCESS, LOGOUT } from '../action-types';

const initialState = {};

const processUser = (user) => {
  const { scopes = [], ...rest } = user;

  scopes.forEach((scope) => {
    switch (scope) {
      case jwtScopes.read:
        rest.canRead = true;
        break;
      case jwtScopes.write:
        rest.canWrite = true;
        break;
      case jwtScopes.composerRead:
        rest.canReadComposer = true;
        break;
      case jwtScopes.composerWrite:
        rest.canWriteComposer = true;
        break;
      default:
    }
  });

  return rest;
};

const UserReducer = (state = initialState, { type, user }) => {
  switch (type) {
    case RECEIVE_USER:
      return processUser(user);
    case LOGIN_SUCCESS:
      return processUser(user);
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default UserReducer;
