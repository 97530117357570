import React from 'react';
import { Button } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import classNames from 'classnames';

import styles from './site.module.css';

const RequestDemoBanner = () => {
  return (
    <div className={classNames(styles.requestDemoContainer, styles.white, styles.mobileBorderRadius)}>
      <p className={styles.requestDemoText}>See how Transmit can work for you</p>
      <div className={classNames(styles.flex, styles.justifyCenter, styles.flexCol)}>
        <Button
          className={classNames(styles.requestDemoButton, styles.button, styles.buttonWhite)}
          href="/request-demo"
        >
          Request a demo
          <ArrowOutwardIcon />
        </Button>
      </div>
    </div>
  );
};

RequestDemoBanner.defaultProps = {};

RequestDemoBanner.propTypes = {};

export default RequestDemoBanner;
