import React from 'react';
import { matchPath, unstable_useBlocker, useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import useInputFileHandler from '../hooks/useInputFileHandler';
import FileUploadButton from '../file-upload-button';
import { ComposerToastContext, toastStatus } from '../../../composer/composer-toast-context';

import styles from '../../site.module.css';
import demoStyles from '../extension-demo.module.css';
import DemoFooter from '../demo-footer';
import { AcceptedFileTypes, CONTENT_BUTTON_ID, ExtensionDemoStepsMap } from '../constants';
import RemoveIcon from '../svg/remove-icon';
import ConfirmationDialog from '../../../confirmation-dialog';

export const ContentVideo = ({ controls, videoUrl, autoPlay, loop, videoRef }) => {
  return videoUrl ? (
    <video
      controls={controls}
      className={demoStyles.videoContent}
      autoPlay={autoPlay}
      src={videoUrl}
      muted
      loop={loop}
      ref={videoRef}
    />
  ) : (
    <div className={demoStyles.videoContent} />
  );
};

ContentVideo.defaultProps = {
  autoPlay: true,
  controls: true,
  loop: true,
  videoRef: null,
  videoUrl: '',
};
ContentVideo.propTypes = {
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  videoRef: PropTypes.shape({}),
  videoUrl: PropTypes.string,
};

const UploadContent = () => {
  /** @type Object*/
  const [encodeArgs, setEncodeArgs] = useOutletContext();
  const { setToast } = React.useContext(ComposerToastContext);
  const { inputFileHandler, uploading } = useInputFileHandler({
    encodeArgs,
    setEncodeArgs,
  });
  const uploadedContentVideo = encodeArgs?.contentUrl;

  const blocker = unstable_useBlocker(({ currentLocation, nextLocation }) => {
    const nextLocationChooseFormat = matchPath(
      {
        path: ExtensionDemoStepsMap.CHOOSE_FORMAT,
        end: false,
      },
      nextLocation.pathname
    );
    return uploadedContentVideo && !nextLocationChooseFormat && currentLocation.pathname !== nextLocation.pathname;
  });

  const handleOnSampleButtonClick = () => {
    setEncodeArgs({
      ...encodeArgs,
      contentUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
      contentDuration: 596,
      contentWidth: 1280,
      contentHeight: 720,
    });
    setToast({ status: toastStatus.success, message: 'Successfully set content video!' });
  };

  const handleOnRemoveButtonClick = () => {
    setEncodeArgs({
      ...encodeArgs,
      contentUrl: null,
      contentDuration: null,
      insertionPoint: null,
      contentWidth: 0,
      contentHeight: 0,
    });
    setToast({ status: toastStatus.success, message: 'Successfully removed content video!' });
  };

  return (
    <React.Fragment>
      <div
        className={classNames(styles.flex, styles.flexCol, styles.fullWidth, styles.alignCenter)}
        style={{ gap: 43 }}
      >
        <ContentVideo videoUrl={encodeArgs.contentUrl} controls={false} />
        <div className={classNames(styles.alignCenter, styles.flex, styles.flexCol, styles.fullWidth)}>
          <DemoFooter
            backNavVisible={false}
            childrenContainerStyles={{
              display: 'flex',
              flexDirection: 'column',
              gap: 19,
            }}
            nextNavVisible
            nextNavDisabled={uploading || !uploadedContentVideo}
            nextNavPath="../choose-format"
          >
            <div className={classNames(styles.flex)} style={{ columnGap: 16 }}>
              {uploadedContentVideo ? (
                <LoadingButton
                  className={classNames(styles.button, styles.buttonPurple)}
                  onClick={handleOnRemoveButtonClick}
                  startIcon={<RemoveIcon />}
                >
                  Remove content video
                </LoadingButton>
              ) : (
                <FileUploadButton
                  acceptedTypes={Object.keys(AcceptedFileTypes[CONTENT_BUTTON_ID]).join(',')}
                  buttonId={CONTENT_BUTTON_ID}
                  isUploadingFile={uploading}
                  handleOnChange={inputFileHandler}
                  uploadedFile={!!encodeArgs.contentUrl}
                  label="Upload content video"
                />
              )}
              <Button
                className={classNames(styles.button, demoStyles.sampleContentButton)}
                disabled={uploading || !!uploadedContentVideo}
                onClick={handleOnSampleButtonClick}
              >
                Use sample content video
              </Button>
            </div>
          </DemoFooter>
        </div>
      </div>
      {blocker.state === 'blocked' ? (
        <ConfirmationDialog
          contentText={`
            Navigating away will erase any changes and uploads made.
            `}
          onConfirmClick={() => {
            setEncodeArgs({
              ...encodeArgs,
              contentUrl: null,
              contentDuration: null,
              contentWidth: 0,
              contentHeight: 0,
            });
            blocker.proceed?.();
          }}
          onCancelClick={() => blocker.reset?.()}
        />
      ) : null}
    </React.Fragment>
  );
};

UploadContent.defaultProps = {};
UploadContent.propTypes = {};

export default UploadContent;
