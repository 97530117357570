import Cookies from 'js-cookie';
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from '../action-types';

const initialState = {
  authToken: Cookies.get('authToken'),
};

const AuthReducer = (previousState = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const { authToken } = action;
      const expires = { expires: 90 };
      Cookies.set('authToken', authToken, expires);
      return { authToken: action.authToken };
    }
    case LOGOUT: {
      Cookies.remove('authToken');
      return initialState;
    }
    case LOGIN_FAILURE:
      return { message: action.message };
    default:
      return previousState;
  }
};

export default AuthReducer;
