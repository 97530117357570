.composerColor {
  color: var(--siteGreen);
}

.extensionColor {
  color: var(--siteBlue);
}

.tabbedSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.tabButtons {
  display: flex;
  justify-content: space-around;
  gap: 24px;
  flex-shrink: 0;
  padding: 5px;
  background-color: var(--siteGrey);
  border-radius: 100px;
  flex-wrap: wrap;
}

.tabButtons button {
  background: transparent;
  box-shadow: none;
  color: black;
}

.tabButtons button:hover {
  background: var(--siteWhite);
  box-shadow: none;
  color: black;
}

button.activeTabBtn {
  background-color: var(--siteWhite);
}

.tabSectionContent {
  display: flex;
  align-items: center;
  gap: 40px;
}

.tabContentHeader {
  max-width: 450px;
}

.tabContentChildren {
  display: flex;
}

.tabContentChildren img {
  border-radius: 20px;
  max-width: 699px;
  max-height: 393px;
}

.headerAndText {
  align-self: flex-start;
  padding-top: 36px;
}

.tabHeading {
  color: #232833;
  font-family: Selecta;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 112.5% */
}

@media (max-width: 1200px) {
  .tabContentChildren img {
    width: 512px;
    height: auto;
  }
}

@media (max-width: 900px) {
  .tabSectionContent {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .headerAndText {
    justify-content: center;
  }

  .tabButtons {
    gap: 6px;
  }
}

@media (max-width: 900px) {
  .headerAndText {
    display: flex;
  }
}

@media (max-width: 700px) {
  .tabbedSection {
    align-items: unset;
    overflow: scroll;
    padding: 0 18px;
  }

  .tabbedSection::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  .tabButtons::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  .tabButtons {
    justify-content: unset;
    flex-wrap: nowrap;
    min-width: max-content;
    overflow: scroll;
  }

  .tabHeading {
    font-size: 24px;
  }

  .tabContent {
    padding: 0 18px;
  }

  .tabDescription {
    margin-top: 12px;
  }
}

@media (max-width: 600px) {
  .tabContentChildren img {
    width: 384px;
  }
}

@media (max-width: 400px) {
  .tabContentChildren img {
    width: 339px;
  }
}
