/* eslint-disable max-len */
import React from 'react';

const FailureIcon = React.memo(() => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={`
            M13 1.5C9.20312 1.5 5.78125 3.51562 3.90625 6.75C1.98438 10.0312 1.98438 14.0156 3.90625 17.25C5.78125 20.5312 9.20312 22.5 13 22.5C16.75 22.5 20.1719 20.5312 22.0469 17.25C23.9688 14.0156 23.9688 10.0312 22.0469 6.75C20.1719 3.51562 16.75 1.5 13 1.5ZM13 24C8.6875 24 4.75 21.75 2.59375 18C0.4375 14.2969 0.4375 9.75 2.59375 6C4.75 2.29688 8.6875 0 13 0C17.2656 0 21.2031 2.29688 23.3594 6C25.5156 9.75 25.5156 14.2969 23.3594 18C21.2031 21.75 17.2656 24 13 24ZM9.4375 8.48438C9.71875 8.20312 10.2344 8.20312 10.5156 8.48438L13 10.9688L15.4375 8.48438C15.7188 8.20312 16.2344 8.20312 16.5156 8.48438C16.7969 8.76562 16.7969 9.28125 16.5156 9.5625L14.0312 12L16.5156 14.4844C16.7969 14.7656 16.7969 15.2812 16.5156 15.5625C16.2344 15.8438 15.7188 15.8438 15.4375 15.5625L13 13.0781L10.5156 15.5625C10.2344 15.8438 9.71875 15.8438 9.4375 15.5625C9.15625 15.2812 9.15625 14.7656 9.4375 14.4844L11.9219 12L9.4375 9.5625C9.15625 9.28125 9.15625 8.76562 9.4375 8.48438Z
            `}
        fill="#C63838"
      />
    </svg>
  );
});

export default FailureIcon;
