import React from 'react';

const CompleteIcon = React.memo(() => {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={`
            M13.5 1.5C9.70312 1.5 6.28125 3.51562 4.40625 6.75C2.48438 10.0312 2.48438 14.0156 4.40625
            17.25C6.28125 20.5312 9.70312 22.5 13.5 22.5C17.25 22.5 20.6719 20.5312 22.5469 17.25C24.4688
            14.0156 24.4688 10.0312 22.5469 6.75C20.6719 3.51562 17.25 1.5 13.5 1.5ZM13.5 24C9.1875 24
            5.25 21.75 3.09375 18C0.9375 14.2969 0.9375 9.75 3.09375 6C5.25 2.29688 9.1875 0 13.5 0C17.7656
            0 21.7031 2.29688 23.8594 6C26.0156 9.75 26.0156 14.2969 23.8594 18C21.7031 21.75 17.7656 24
            13.5 24ZM18.5156 9.5625L12.5156 15.5625C12.2344 15.8438 11.7188 15.8438 11.4375
            15.5625L8.4375 12.5625C8.15625 12.2812 8.15625 11.7656 8.4375 11.4844C8.71875 11.2031
            9.23438 11.2031 9.51562 11.4844L12 13.9688L17.4375 8.48438C17.7188 8.20312 18.2344 8.20312 18.5156
            8.48438C18.7969 8.76562 18.7969 9.28125 18.5156 9.5625Z
            `}
        fill="#3CB094"
      />
    </svg>
  );
});

export default CompleteIcon;
