import { init, FullStory } from '@fullstory/browser';

const FULL_STORY_ORG_ID = 'o-1CECYV-na1';

export const AnalyticEventTypes = Object.freeze({
  /** api call succeded */
  SUCCESS_SAVED_ENTITY: (entityName) => `success_saved_${entityName}`,
  /** api call failed */
  FAILURE_SAVED_ENTITY: (entityName) => `failure_saved_${entityName}`,
  /** "create"/"save" click */
  SAVE_ENTITY_CLICK: (entityName) => `save_${entityName}_click`,
  /** closed dialog */
  CLOSE_SAVE_DIALOG: (entityName) => `closed_${entityName}_dialog`,
});

export const FullStoryEvents = Object.freeze({
  SET_IDENTITY: 'setIdentity',
  SET_PROPERTIES: 'setProperties',
  TRACK_EVENT: 'trackEvent',
});

export const PropertyTypes = Object.freeze({
  PAGE: 'page',
  USER: 'user',
});

export function initFullStory() {
  if (process.env.NODE_ENV !== 'production') return;

  init({
    orgId: FULL_STORY_ORG_ID,
  });
}

export function captureAnalyticEvent({ eventName, eventProperties = {} }) {
  if (process.env.NODE_ENV !== 'production') return;

  FullStory(FullStoryEvents.TRACK_EVENT, {
    name: eventName,
    properties: {
      ...eventProperties,
    },
  });
}

export function setProperties({ propertiesType, pageName = undefined, ...rest }) {
  if (process.env.NODE_ENV !== 'production') return;

  FullStory(FullStoryEvents.SET_PROPERTIES, {
    type: propertiesType,
    properties: {
      pageName,
      ...rest,
    },
  });
}

export function identifyUser({ userId, ...rest }) {
  if (process.env.NODE_ENV !== 'production') return;

  FullStory(FullStoryEvents.SET_IDENTITY, {
    uid: userId,
    properties: {
      ...rest,
    },
  });
}

export function setPageAnalytics({ pageProperties, userId, userProperties }) {
  if (process.env.NODE_ENV !== 'production') return;

  if (pageProperties && Object.keys(pageProperties).length) {
    setProperties({
      propertiesType: PropertyTypes.PAGE,
      properties: {
        ...pageProperties,
      },
    });
  }

  if (userId) {
    identifyUser({
      userId,
      ...userProperties,
    });
  }
}
