export const UPDATE_ACTIVE_SCENE = 'UPDATE_ACTIVE_SCENE';
export const TOGGLE_PREVIEW_OPEN = 'TOGGLE_PREVIEW_OPEN';
export const UPDATE_PREVIEW = 'UPDATE_PREVIEW';
export const TOGGLE_STANDALONE_PREVIEW = 'TOGGLE_STANDALONE_PREVIEW';
export const RESET_PREVIEW_PROPS = 'RESET_PREVIEW_PROPS';
export const UPDATE_AD_AND_PREVIEW = 'UPDATE_AD_AND_PREVIEW';
export const UPDATE_PREVIEW_DEVICE = 'UPDATE_PREVIEW_DEVICE';
export const UPDATE_AD = 'UPDATE_AD';
export const SET_INITIAL_AD_FORMAT = 'SET_INITIAL_AD_FORMAT';
export const UPDATE_ASSETS = 'UPDATE_ASSETS';
export const SET_ASSETS_ERRORS = 'SET_ASSETS_ERRORS';
export const SET_AD_ERRORS = 'SET_AD_ERRORS';
export const SET_PREVIEW_ERRORS = 'SET_PREVIEW_ERRORS';
export const ADD_ASSET_TO_THUMBNAIL_QUEUE = 'ADD_ASSET_TO_THUMBNAIL_QUEUE';
export const REMOVE_ASSET_FROM_THUMBNAIL_QUEUE = 'REMOVE_ASSET_FROM_THUMBNAIL_QUEUE';
export const UPDATE_VIDEO_THUMBNAIL_MAP = 'UPDATE_VIDEO_THUMBNAIL_MAP';
export const UPDATE_AD_FETCHED = 'UPDATE_AD_FETCHED';
export const SET_AD_LAYOUTS = 'SET_AD_LAYOUTS';
export const UPDATE_DEBUG_ENCODE = 'UPDATE_DEBUG_ENCODE';
export const TOGGLE_DEBUG_MODAL = 'TOGGLE_DEBUG_MODAL';
export const TOGGLE_TRANSITION_SCENE_CARDS_HOVER = 'TOGGLE_TRANSITION_SCENE_CARDS_HOVER';
export const UPDATE_CONTENT_PREVIEW = 'UPDATE_CONTENT_PREVIEW';
export const RECEIVE_PREVIEW = 'RECEIVE_PREVIEW';
export const SET_STUDIO_AD_TYPE_PARAMS = 'SET_STUDIO_AD_TYPE_PARAMS';
export const RESET_STUDIO_PROPS = 'RESET_STUDIO_PROPS';
