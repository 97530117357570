import axios from 'axios';

export const getExtensionDemo = async (params) => {
  const url = `${SERVICE_URL}/api/v1/extension-demo`;
  return axios.post(url, params).then((response) => {
    return response;
  });
};

export const sendExtensionDemoEmail = async ({ email, encodeUrl }) => {
  const url = `${SERVICE_URL}/api/v1/send-extension-demo-email`;
  return axios.post(url, { email, encodeUrl }).then((response) => {
    return response;
  });
};
