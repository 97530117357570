import React from 'react';

const PipIcon = React.memo(() => {
  return (
    <svg width="35" height="23" viewBox="0 0 35 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="33" height="21" rx="3" stroke="#5A24FB" strokeWidth="2" />
      <rect x="4" y="9" width="10" height="7" rx="1" stroke="#5A24FB" strokeWidth="2" />
      <rect x="17" y="7" width="14" height="9" rx="1" stroke="#5A24FB" strokeWidth="2" />
    </svg>
  );
});

export default PipIcon;
