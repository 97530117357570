/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';
import csStyles from '../creative-studio/creative-studio.module.css';

const CsBgLogoLeft = React.memo(() => (
  <svg
    className={classNames(csStyles.csBackgroundLogo, csStyles.csBackgroundLogoLeft)}
    width="541"
    height="734"
    viewBox="0 0 541 734"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-67.8835 224.065L344.171 1.57812C349.119 -1.0934 355.126 2.48963 355.126 8.11255V174.94L116.495 303.788C111.693 306.381 108.699 311.399 108.699 316.857V558.205L-64.3705 651.653C-69.3183 654.325 -75.3249 650.742 -75.3249 645.119V236.54C-75.3249 231.331 -72.4676 226.541 -67.8835 224.065ZM108.699 558.972L-64.0498 652.247C-69.4473 655.162 -76 651.253 -76 645.119V236.54C-76 231.083 -73.0067 226.064 -68.2042 223.471L343.851 0.984081C349.248 -1.9303 355.801 1.97845 355.801 8.11255V174.576L528.55 81.3006C533.947 78.3862 540.5 82.2949 540.5 88.429V501.035C540.5 504.012 538.867 506.749 536.248 508.163L120.649 732.564C115.252 735.478 108.699 731.569 108.699 725.435V558.972ZM355.801 175.343L528.871 81.8946C533.818 79.2231 539.825 82.8061 539.825 88.429V501.035C539.825 503.764 538.328 506.273 535.927 507.569L120.329 731.97C115.381 734.641 109.374 731.058 109.374 725.435V558.608L351.549 427.847C354.168 426.432 355.801 423.695 355.801 420.718V175.343ZM355.126 175.707V420.718C355.126 423.447 353.629 425.956 351.228 427.253L109.374 557.841V316.857C109.374 311.647 112.232 306.857 116.816 304.382L355.126 175.707Z"
        fill="white"
      />
    </g>
  </svg>
));

export default CsBgLogoLeft;
