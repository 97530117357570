import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import classNames from 'classnames';
import theme from '../../styles/theme';
import variables from '../../styles/variables';
import styles from './site.module.css';

const useStyles = makeStyles(() => ({
  root: {
    '& .highlight': {
      color: variables.composerMarketingPurple,
    },
  },
  container: {
    maxWidth: 1080,
    padding: '0 45px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    minHeight: '100vh',

    [theme.breakpoints.down('md')]: {
      padding: '0 18px',
    },
  },

  header: {
    fontSize: '54px',
    fontWeight: 800,
    lineHeight: '64px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: variables.composerMarketingPurple,

    [theme.breakpoints.down('md')]: {
      fontSize: '52px',
      lineHeight: '52px',
    },
  },

  policy: {
    margin: '20px 0 0 0',
    '& p': {
      display: 'inline-block',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      // textIndent: 10,
      paddingLeft: 10,
    },
    '& b': {
      fontWeight: 800,
    },
    '& i': {
      fontStyle: 'italic',
    },
  },
}));

const Security = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classNames(styles.container)}>
        <div className={styles.centerContainer}>
          <section className={classNames(styles.flex, styles.flexCol, styles.horizontalPaddingContainer)}>
            <div className={classNames(styles.headerOffset)}>
              <div className={classNames(styles.gap, styles.roundedContainer)}>
                <div className={classNames(styles.flex, styles.flexCol, styles.gap, styles.alignCenter)}>
                  <h2 className={styles.heading}>Security Policy</h2>

                  <Box className={classes.policy}>
                    Effective Date: August 9, 2022
                    <br />
                    <br />
                    <b>Organizational Security</b>
                    <br />
                    <br />
                    <p>
                      ‍ <i>Information Security Program.</i> We have an Information Security Program in place that is
                      communicated throughout the organization. Our Information Security Program follows the criteria
                      set forth by the SOC 2 Framework. SOC 2 is a widely known information security auditing procedure
                      created by the American Institute of Certified Public Accountants.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Third-Party Audits.</i> We have an Information Security Program in place that is communicated
                      throughout the organization. Our Information Security Program follows the criteria set forth by
                      the SOC 2 Framework. SOC 2 is a widely known information security auditing procedure created by
                      the American Institute of Certified Public Accountants.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Third-Party Penetration Testing.</i> We perform an independent third-party penetration at least
                      annually to ensure that the security posture of our services is uncompromised.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Roles and Responsibilities.</i> Roles and responsibilities related to our Information Security
                      Program and the protection of our customer’s data are well defined and documented. Our team
                      members are required to review and accept all of the security policies.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Security Awareness Training.</i> Our team members are required to go through employee security
                      awareness training covering industry standard practices and information security topics such as
                      phishing and password management.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Confidentiality.</i> All team members are required to sign and adhere to an industry standard
                      confidentiality agreement prior to their first day of work.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Background Checks.</i> We perform background checks on all new team members in accordance with
                      local laws.
                    </p>
                    <br />
                    <br />
                    <b>Cloud Security</b>
                    <br />
                    <br />
                    <p>
                      <i>Cloud Infrastructure Security.</i> All of our services are hosted with Amazon Web Services
                      (AWS). They employ a robust security program with multiple certifications. For more information on
                      our provider’s security processes, please visit AWS Security.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Data Hosting Security.</i> All of our data is hosted on Amazon Web Services (AWS) databases.
                      These databases are all located in the [United States]. Please reference the above vendor specific
                      documentation linked above for more information.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Encryption at Rest.</i> All databases are encrypted at rest.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Encryption in Transit.</i> Our applications encrypt in transit with TLS/SSL only.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Vulnerability Scanning.</i> We perform vulnerability scanning and actively monitor for threats.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Logging and Monitoring.</i> We actively monitor and log various cloud services.
                    </p>
                    <br />
                    <br />‍
                    <p>
                      <i>Business Continuity and Disaster Recovery.</i> We use our data hosting provider’s backup
                      services to reduce any risk of data loss in the event of a hardware failure. We utilize monitoring
                      services to alert the team in the event of any failures affecting users.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Incident Response.</i> We have a process for handling information security events which
                      includes escalation procedures, rapid mitigation and communication.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Information Security Program.</i> We have an Information Security Program in place that is
                      communicated throughout the organization. Our Information Security Program follows the criteria
                      set forth by the SOC 2 Framework. SOC 2 is a widely known information security auditing procedure
                      created by the American Institute of Certified Public Accountants.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Vulnerability Scanning.</i> We perform vulnerability scanning and actively monitor for threats.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Logging and Monitoring.</i> We actively monitor and log various cloud services.
                    </p>
                    <br />
                    <br />‍
                    <p>
                      <i>Business Continuity and Disaster Recovery.</i> We use our data hosting provider’s backup
                      services to reduce any risk of data loss in the event of a hardware failure. We utilize monitoring
                      services to alert the team in the event of any failures affecting users.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Incident Response.</i> We have a process for handling information security events which
                      includes escalation procedures, rapid mitigation and communication.
                    </p>
                    <br />
                    <br />
                    <b>Access Security</b>
                    <br />
                    <br />
                    <p>
                      <i>Permissions and Authentication.</i> Access to cloud infrastructure and other sensitive tools
                      are limited to authorized employees who require it for their role. Where available we have Single
                      Sign-on (SSO), 2-factor authentication (2FA) and strong password policies to ensure access to
                      cloud services are protected.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Least Privilege Access Control.</i> We follow the principle of least privilege with respect to
                      identity and access management.
                    </p>
                    ‍
                    <br />
                    <br />
                    <p>
                      <i>Quarterly Access Reviews.</i> We perform quarterly access reviews of all team members with
                      access to sensitive systems.
                    </p>
                    ‍
                    <br />
                    <br />
                    <p>
                      <i>Password Requirements.</i> All team members are required to adhere to a minimum set of password
                      requirements and complexity for access.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Password Managers.</i> All company issued laptops utilize a password manager for team members
                      to manage passwords and maintain password complexity.
                    </p>
                    <br />
                    <br />
                    <b>Vendor and Risk Management</b>
                    <br />
                    <br />
                    <p>
                      <i>Annual Risk Assessments.</i> We undergo at least annual risk assessments to identify
                      anypotential threats, including considerations for fraud.
                    </p>
                    <br />
                    <br />
                    <p>
                      <i>Vendor Risk Management.</i> Vendor risk is determined and the appropriate vendor reviews are
                      performed prior to authorizing a new vendor.
                    </p>
                    <br />
                    <br />
                    <b>Contact Us</b>
                    <br />
                    <br />‍
                    <p>
                      If you have any questions, comments or concerns or if you wish to report a potential security
                      issue, please contact:
                    </p>
                    <p>
                      <a href="mailto:admin@transmit.live">admin@transmit.live</a>
                    </p>
                  </Box>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Container>
    </div>
  );
};

export default Security;
