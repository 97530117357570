import React from 'react';

import { Button } from '@mui/material';
import classNames from 'classnames';

import styles from '../site.module.css';
import homeStyles from './home.module.css';
import csStyles from '../creative-studio/creative-studio.module.css';
import CreativeStudioShowcase from '../creative-studio/creative-studio-showcase';

const CreativeStudioCard = () => {
  return (
    <div className={classNames(styles.sectionCard, homeStyles.creativeStudioCard)}>
      <div
        className={classNames(csStyles.csHeaderGradient, homeStyles.creativeStudioGrad)}
        style={{ WebkitTransform: 'translateZ(0)' }}
      />
      <div className={csStyles.csHeaderContent}>
        <div className={styles.centerBlock}>
          <div className={classNames(styles.flex, styles.flexCol, styles.alignCenter)}>
            <div className={csStyles.csHeadingLogoContainer}>
              <img src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/cs-logo.svg" alt="creative studio logo" />
              <div className={classNames(styles.description, styles.medium, csStyles.csDescription)}>
                Creative Studio
              </div>
            </div>
            <h2 className={classNames(styles.heading, styles.medium, csStyles.csHeading)} style={{ maxWidth: 'unset' }}>
              Create <span style={{ display: 'inline' }}>custom units</span>{' '}
              <span style={{ display: 'inline', color: '#ffffff' }}>instantly</span>
            </h2>
            <div className={classNames(styles.description, homeStyles.csDescription)}>
              Build assets for new in-stream ad formats.
            </div>
            <a href="/creative-studio">
              <Button className={classNames(styles.button, styles.buttonWhite, styles.mt2)}>
                Explore Creative Studio
              </Button>
            </a>
          </div>
        </div>
      </div>

      <CreativeStudioShowcase />
    </div>
  );
};

export default CreativeStudioCard;
