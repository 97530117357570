import React from 'react';

const OverlayIcon = React.memo(() => {
  return (
    <svg width="35" height="23" viewBox="0 0 35 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="33" height="21" rx="3" stroke="#5A24FB" strokeWidth="2" />
      <rect x="6" y="12" width="24" height="6" rx="2" stroke="#5A24FB" strokeWidth="2" />
    </svg>
  );
});

export default OverlayIcon;
