import React from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import styles from '../site.module.css';
import demoStyles from './extension-demo.module.css';

const DemoFooter = ({
  children,
  childrenContainerStyles,
  backNavDisabled,
  backNavVisible,
  nextNavDisabled,
  nextNavVisible,
  backNavPath,
  nextNavPath,
}) => {
  return (
    <div className={classNames(styles.textCenter, styles.flex, styles.flexRow, demoStyles.footerContainer)}>
      <NavLink
        className={classNames(
          styles.button,
          demoStyles.footerNavButton,
          (backNavDisabled && demoStyles.disabledFooterNavButton) || ''
        )}
        style={{ visibility: backNavVisible ? 'visible' : 'hidden' }}
        to={backNavPath}
      >
        <NavigateBeforeIcon />
        Back
      </NavLink>
      <div style={childrenContainerStyles}>{children}</div>
      <NavLink
        className={classNames(
          styles.button,
          demoStyles.footerNavButton,
          (nextNavDisabled && demoStyles.disabledFooterNavButton) || ''
        )}
        style={{ visibility: nextNavVisible ? 'visible' : 'hidden' }}
        to={nextNavPath}
      >
        Next
        <NavigateNextIcon />
      </NavLink>
    </div>
  );
};

DemoFooter.defaultProps = {
  children: null,
  childrenContainerStyles: {},
  backNavDisabled: true,
  backNavVisible: true,
  nextNavDisabled: true,
  nextNavVisible: true,
  backNavPath: '.',
  nextNavPath: '.',
};

DemoFooter.propTypes = {
  children: PropTypes.node,
  childrenContainerStyles: PropTypes.shape({}),
  backNavDisabled: PropTypes.bool,
  backNavVisible: PropTypes.bool,
  nextNavDisabled: PropTypes.bool,
  nextNavVisible: PropTypes.bool,
  backNavPath: PropTypes.string,
  nextNavPath: PropTypes.string,
};

export default DemoFooter;
