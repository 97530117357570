import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import colorVariables from '../styles/variables';
import { baseButton, keyButton, smallButton, secondaryButton } from '../styles/styles';
import NotFoundLottie from '../assets/animations/errors/animation_lmpqxftz.lottie';
import UnauthorizedLottie from '../assets/animations/errors/animation_lmpqwzkn.lottie';

const TITLE_FONT_WEIGHT = 700;
const ACTION_BUTTON_FONT_WEIGHT = 600;
const LottieAnimationSources = {
  404: NotFoundLottie,
  403: UnauthorizedLottie,
};

/** @type {Object<string, import('@mui/styles').CSSProperties>} */
const styles = {
  baseButton,
  keyButton,
  smallButton,
  secondaryButton,

  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  errorCode: {
    color: colorVariables.purple900,
    fontSize: '1rem',
  },

  title: {
    fontSize: '3rem',
    fontWeight: TITLE_FONT_WEIGHT,
    marginTop: '1rem',
  },

  subtitle: {
    fontSize: '1rem',
    marginTop: '1.5rem',
    color: colorVariables.textGrey,
  },

  buttonContainer: {
    display: 'flex',
    gap: '1.5rem',
    marginTop: '2.5rem',
  },

  actionButton: {
    margin: 0,
    fontWeight: ACTION_BUTTON_FONT_WEIGHT,
  },
};

const useStyles = makeStyles(styles);

const ErrorPage = ({ errorCode, description }) => {
  const classes = useStyles();

  const lottiePlayerRef = useRef();

  return (
    <Box className={classes.container}>
      <dotlottie-player
        autoplay
        loop
        ref={lottiePlayerRef}
        src={LottieAnimationSources[errorCode]}
        style={{ height: 600 }}
      />

      <Typography className={classes.subtitle} variant="body1">
        {description}
      </Typography>
      <Box className={classes.buttonContainer}>
        <Button
          className={classNames(classes.baseButton, classes.secondaryButton, classes.actionButton)}
          onClick={() => window.history.back()}
        >
          Go back
        </Button>
        <Button
          className={classNames(classes.baseButton, classes.keyButton, classes.smallButton, classes.actionButton)}
          onClick={() => {
            document.location = '/';
          }}
        >
          Go home
        </Button>
      </Box>
    </Box>
  );
};

ErrorPage.defaultProps = {
  description: '',
};

ErrorPage.propTypes = {
  errorCode: PropTypes.number.isRequired,
  description: PropTypes.string,
};

export default ErrorPage;
