import React from 'react';
import classNames from 'classnames';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';

import {
  AcceptedFileTypes,
  BACKGROUND_SKIN_BUTTON_ID,
  BACKGROUND_SKIN_SAMPLE_BUTTON_ID,
  DELETE_BACKGROUND_SKIN_BUTTON_ID,
  encodeArgsType,
  SAMPLE_BACKGROUND_SKIN,
  setEncodeArgsType,
} from '../constants';
import { deriveAdDurationFromContentDuration } from '../utils';
import { ComposerToastContext, toastStatus } from '../../../composer/composer-toast-context';
import useInputFileHandler from '../hooks/useInputFileHandler';
import DemoFooter from '../demo-footer';
import FileUploadButton from '../file-upload-button';
import RemoveIcon from '../svg/remove-icon';

import styles from '../../site.module.css';
import demoStyles from '../extension-demo.module.css';

const LbarFooter = ({ encodeArgs, setEncodeArgs }) => {
  const { setToast } = React.useContext(ComposerToastContext);
  const { inputFileHandler, uploading } = useInputFileHandler({
    encodeArgs,
    setEncodeArgs,
  });
  const { contentDuration, skin: uploadedBgSkin } = encodeArgs;

  const handleOnDelete = (e) => {
    const currentTargetId = e.currentTarget.id;
    switch (currentTargetId) {
      case DELETE_BACKGROUND_SKIN_BUTTON_ID:
        setEncodeArgs({
          ...encodeArgs,
          skin: null,
        });
        setToast({ status: toastStatus.success, message: 'Successfully removed background!' });
        break;

      default:
        break;
    }
  };

  const handleOnSampleButtonClick = (e) => {
    const currentTargetId = e.currentTarget.id;
    const adDuration = deriveAdDurationFromContentDuration({ contentDuration });
    switch (currentTargetId) {
      case BACKGROUND_SKIN_SAMPLE_BUTTON_ID:
        setEncodeArgs({
          ...encodeArgs,
          skin: SAMPLE_BACKGROUND_SKIN,
          adDuration,
        });
        setToast({ status: toastStatus.success, message: 'Successfully set sample background!' });
        break;

      default:
        break;
    }
  };

  return (
    <DemoFooter
      childrenContainerStyles={{
        display: 'flex',
        gap: 19,
      }}
      backNavDisabled={false}
      backNavPath="../upload-content"
      nextNavVisible
      nextNavDisabled={uploading || !uploadedBgSkin}
      nextNavPath="../select-insertion"
    >
      <div className={demoStyles.pipButtonContainer}>
        <div className={classNames(styles.flex)} style={{ columnGap: 16 }}>
          {uploadedBgSkin ? (
            <LoadingButton
              className={classNames(styles.button, styles.buttonPurple)}
              id={DELETE_BACKGROUND_SKIN_BUTTON_ID}
              onClick={handleOnDelete}
              startIcon={<RemoveIcon />}
            >
              Remove background
            </LoadingButton>
          ) : (
            <FileUploadButton
              acceptedTypes={Object.keys(AcceptedFileTypes[BACKGROUND_SKIN_BUTTON_ID]).join(',')}
              buttonId={BACKGROUND_SKIN_BUTTON_ID}
              isUploadingFile={uploading}
              handleOnChange={inputFileHandler}
              uploadedFile={!!uploadedBgSkin}
              label="Upload background"
            />
          )}
          <Button
            className={classNames(styles.button, demoStyles.sampleContentButton)}
            disabled={uploading || !!uploadedBgSkin}
            id={BACKGROUND_SKIN_SAMPLE_BUTTON_ID}
            onClick={handleOnSampleButtonClick}
          >
            Use sample background
          </Button>
        </div>
      </div>
    </DemoFooter>
  );
};

LbarFooter.defaultProps = {};
LbarFooter.propTypes = {
  encodeArgs: encodeArgsType.isRequired,
  setEncodeArgs: setEncodeArgsType.isRequired,
};

export default LbarFooter;
