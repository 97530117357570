/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';
import homeStyles from '../home/home.module.css';

const HomeBgLogoLeft = React.memo(() => (
  <svg
    className={classNames(homeStyles.backgroundLogo, homeStyles.backgroundLogoLeft)}
    width="409"
    height="673"
    viewBox="0 0 409 673"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-199.883 163.065L212.171 -59.4219C217.119 -62.0934 223.126 -58.5104 223.126 -52.8874V113.94L-15.505 242.788C-20.3074 245.381 -23.3008 250.399 -23.3008 255.857V497.205L-196.371 590.653C-201.318 593.325 -207.325 589.742 -207.325 584.119V175.54C-207.325 170.331 -204.468 165.541 -199.883 163.065ZM-23.3008 497.972L-196.05 591.247C-201.447 594.162 -208 590.253 -208 584.119V175.54C-208 170.083 -205.007 165.064 -200.204 162.471L211.851 -60.0159C217.248 -62.9303 223.801 -59.0215 223.801 -52.8874V113.576L396.55 20.3006C401.947 17.3862 408.5 21.2949 408.5 27.429V440.035C408.5 443.012 406.867 445.749 404.248 447.163L-11.3506 671.564C-16.7481 674.478 -23.3008 670.569 -23.3008 664.435V497.972ZM223.801 114.343L396.871 20.8946C401.818 18.2231 407.825 21.8061 407.825 27.429V440.035C407.825 442.764 406.328 445.273 403.927 446.569L-11.6713 670.97C-16.6191 673.641 -22.6257 670.058 -22.6257 664.435V497.608L219.549 366.847C222.168 365.432 223.801 362.695 223.801 359.718V114.343ZM223.126 114.707V359.718C223.126 362.447 221.629 364.956 219.228 366.253L-22.6257 496.841V255.857C-22.6257 250.647 -19.7684 245.857 -15.1843 243.382L223.126 114.707Z"
      fill="#5A24FB"
    />
  </svg>
));

export default HomeBgLogoLeft;
