import React from 'react';
import PropTypes from 'prop-types';

const InProgressIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`
        M2.9375 4.875C2.75 5.25 2.3125 5.40625 1.9375 5.25C1.5625 5.09375 1.375 4.65625 1.53125 
        4.28125C2.59375 1.78125 5.09375 0 8 0C9.625 0 11.1875 0.65625 12.3438 1.8125L14 3.46875V1.75C14 
        1.34375 14.3125 1 14.75 1C15.1562 1 15.5 1.34375 15.5 1.75V5.25C15.5 5.6875 15.1562 6 14.75 6H11.25C10.8125 
        6 10.5 5.6875 10.5 5.25C10.5 4.84375 10.8125 4.5 11.25 4.5H12.9375L11.2812 2.875C10.4062 
        2 9.21875 1.5 8 1.5C5.71875 1.5 3.75 2.90625 2.9375 4.875ZM14.4375 9.78125C13.3438 12.25 10.875 
        14 8 14C6.34375 14 4.78125 13.375 3.625 12.1875L2 10.5625V12.25C2 12.6875 1.65625 13 
        1.25 13C0.8125 13 0.5 12.6875 0.5 12.25V8.75C0.5 8.34375 0.8125 8 1.25 8H4.75C5.15625 8 5.5 
        8.34375 5.5 8.75C5.5 9.1875 5.15625 9.5 4.75 9.5H3.03125L4.6875 11.1562C5.5625 12.0312 6.75 
        12.5 8 12.5C10.25 12.5 12.2188 11.1562 13.0312 9.1875C13.2188 8.78125 13.6562 8.625 14.0312 
        8.78125C14.4062 8.9375 14.5938 9.375 14.4375 9.78125Z
        `}
        fill="#5A24FB"
      />
    </svg>
  );
};

InProgressIcon.defaultProps = {
  className: '',
};
InProgressIcon.propTypes = {
  className: PropTypes.string,
};

export default React.memo(InProgressIcon);
