.reachExtensionContainer {
  padding-bottom: 50px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(239, 243, 255, 1) 55%,
    rgba(214, 231, 255, 1) 60%,
    rgba(255, 255, 255, 1) 100%
  );
  padding-top: 175px;
}

.reachExtensionContainer span {
  color: var(--siteBlue);
}

.reachExtensionContainer a span {
  color: white;
}

.reHeaderContainer {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.reHeadingContainer {
  flex-shrink: 2;
  padding-left: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 475px;
  max-height: 512px;
}

.reHeadingContainer button {
  margin-left: 10px;
}

.reHeadingContainer button svg {
  color: white;
}

.headingLogoContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.headingLogoContainer svg {
  width: 28px;
}

.reHeader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.reMiniCardHeader {
  text-align: start;
}

.reHeaderGrid {
  width: 100%;
  display: grid;
  grid: auto / repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  padding-bottom: 72px;
}

.reHeaderOffset {
  position: relative;
  left: calc(25% + 16px);
}

/* Keyframes for the fade-in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.reHeaderImg {
  width: 340px;
  height: 190px;
  background-color: #eceff5;
  border-radius: 20px;
  background-size: cover;
  box-shadow: 0px 30px 81.645px 0px rgba(0, 0, 0, 0.07), 0px 42.637px 34.109px 0px rgba(0, 0, 0, 0.05),
    0px 22.796px 18px 0px rgba(0, 0, 0, 0.04), 0px 12.779px 10.223px 0px rgba(0, 0, 0, 0.04),
    0px 6.787px 5px 0px rgba(0, 0, 0, 0.03), 0px 2.824px 2.259px 0px rgba(0, 0, 0, 0.02);
  opacity: 0;
  -webkit-animation: fadeIn 1;
  -moz-animation: fadeIn 1;
  animation: fadeIn 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.25s;
  -moz-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.reHeader1 {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.reHeader2 {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
  background-image: url('https://dxgcj7vu1u7nu.cloudfront.net/web-assets/re-header-3%402x.jpg');
}

.reHeader3 {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
  background-image: url('https://dxgcj7vu1u7nu.cloudfront.net/web-assets/re-header-5%402x.jpg');
}

.reHeader4 {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
  background-image: url('https://dxgcj7vu1u7nu.cloudfront.net/web-assets/re-header-4%402x.jpg');
}

.reHeader5 {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.reHeader6 {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
  background-image: url('https://dxgcj7vu1u7nu.cloudfront.net/web-assets/re-header-6%402x.jpg');
}

.reHeader7 {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.reHeader8 {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
  background-image: url('https://dxgcj7vu1u7nu.cloudfront.net/web-assets/re-header-1%402x.jpg');
}

.reHeader9 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
  background-image: url('https://dxgcj7vu1u7nu.cloudfront.net/web-assets/re-header-7%402x.jpg');
}

.increaseSupplyCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.reFeatureCards {
  display: grid;
  grid: auto / repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
  padding: 0px;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.reFeatureCard {
  background-color: var(--extensionBlue);
  /* padding: 44px 24px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  /* height: 444px; */
  min-height: 519px;
}

.reFeatureCard img {
  width: 100%;
}

.featureCardImgContainer {
  padding: 0 40px 40px 40px;
  max-width: 552px;
  max-height: 278px;
}

.increasedViewershipCardImgContainer {
  display: flex;
  padding: 0 40px 40px 40px;
  max-width: 552px;
  max-height: 278px;
}

.increasedViewershipCard img {
  border-radius: 24px;
}

.cardsWrapper {
  margin-top: 129px;
}

.getStarted {
  box-shadow: unset;
  border-radius: 100px;
  background: var(--siteBlue);
  color: var(--siteWhite);
}

.getStarted:hover {
  background: var(--siteBlueLight);
}

@media (min-width: 1536px) {
  .reHeaderWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    overflow: hidden;
  }

  .reHeaderContainer {
    max-width: 1448px;
    width: 100%;
    overflow: visible;
  }

  .reHeadingContainer {
    padding: 0;
  }

  .reachExtensionContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .requestDemoWrapper {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .requestDemoWrapper {
    margin: 0 44px 30px;
  }
  .reachExtensionContainer {
    padding-top: 0;
    padding-bottom: 0;
  }

  .reHeadingContainer {
    padding-left: 44px;
    display: unset;
    min-width: unset;
    max-height: unset;
  }

  .reHeaderContainer {
    flex-direction: column;
    padding-top: 100px;
    justify-content: unset;
    min-height: unset;
  }

  .reHeaderGrid {
    position: unset;
    min-height: unset;
  }

  .increaseSupplyCard img {
    height: unset;
  }

  .reFeatureCards {
    grid: auto / 1fr;
  }

  .horizontalGutter {
    padding: 0 44px;
  }

  .increaseYourSupply {
    padding: 44px;
  }

  .cardsWrapper {
    margin-top: 39px;
  }

  .featureCardImgContainer {
    max-width: unset;
    max-height: unset;
  }

  .increasedViewershipCardImgContainer {
    max-width: unset;
    max-height: unset;
  }
}

@media (max-width: 600px) {
  .requestDemoWrapper {
    margin: 0 24px 30px;
  }

  .reHeadingContainer {
    padding-left: 0;
  }
  .reHeaderContainer {
    padding-left: 24px;
    overflow: hidden;
    min-height: unset;
  }

  .reHeaderGrid {
    row-gap: 16px;
    column-gap: 16px;
    width: 978px;
  }

  .reHeader {
    height: 340px;
  }

  .reHeaderOffset {
    position: relative;
    left: calc(50% + 8px);
  }

  .reHeaderImg {
    border-radius: 10px;
    width: 158px;
    height: 85px;
  }

  .increaseSupplyCard {
    align-items: unset;
    padding: 35px 0;
  }

  .horizontalGutter {
    padding: 0 24px;
  }

  .increaseYourSupply {
    padding: 0;
  }

  .reFeatureCard {
    padding: 0 40px 40px;
    justify-content: unset;
    height: unset;
    min-height: unset;
  }

  .reFeatureCard img {
    scale: unset;
  }

  .reMiniCardHeader {
    padding: 40px 0 0 0;
  }

  .increasedViewershipCardImgContainer {
    border: none;
    background: unset;
    display: flex;
    overflow: hidden;
    padding: 0;
  }

  .featureCardImgContainer {
    padding: 0;
  }
}
