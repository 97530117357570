.formContainer {
  padding: 100px 0 0 0;
  max-width: 300px;
  margin: 0 auto;
}

.formContainer a {
  color: #6b1aff;
}

.formContainer a:visited {
  color: #6b1aff;
}

.formHeader {
  font-size: 24px;
  padding: 8px 0 16px 0;
}

.errorBox {
  margin: 20px 0 0 0;
  width: 300px;
  text-align: center;
  color: red;
}

.secondaryLink {
  text-align: right;
  font-size: 12px;
  margin: 5px 0 0;
}

.backLink {
  text-align: center;
  padding-top: 16px;
}

.textField {
  width: 300px;
}

.textFieldLabel {
  font-size: 14px;
  margin: 12px 0 4px 0;
  display: block;
}

.button {
  font-size: 14px;
  background-color: #6b1aff;
  color: white;
  text-transform: none;
  width: 100%;
  border-radius: 8px;
  height: 40px;
  margin-top: 16px;
}

.button:hover {
  background-color: #8c4dff;
}

.secondaryButton {
  font-size: 14px;
  color: #000;
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 8px;
  height: 40px;
  margin-top: 16px;
  text-transform: none;
}

.secondaryButton:hover {
  background-color: #e0e0e0;
}
