import axios from 'axios';
import { deformatAdDuration } from '../utils/format';

export const getSupplyRoutes = async (accountId, search) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/supply-routes${search}`;
  return axios.get(url).then((response) => response.data);
};

export const postSupplyRouteIdsByAccount = async (accountId, supplyRouteIds, search) => {
  const idQuery = { ids: supplyRouteIds };
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/supply-routes/by_ids${search}`;
  return axios.post(url, idQuery).then((response) => response.data);
};

export const getSupplyRoute = async ({ accountId, supplyRouteId }) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/supply-route/${supplyRouteId}`;
  return axios.get(url).then((response) => response.data);
};

export const updateSupplyRoute = async ({ accountId, supplyRoute, reencode = false }) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/supply-route?reencode=${reencode}`;
  return axios.put(url, supplyRoute).then((response) => response.data);
};

export const archiveSupplyRoute = async ({ accountId, supplyRoute, archive }) => {
  const archivedSupplyRoute = { ...supplyRoute, duration: deformatAdDuration(supplyRoute.duration), archived: archive };
  return updateSupplyRoute({ accountId, supplyRoute: archivedSupplyRoute });
};

/**
 * Fetches the Supply Route by ID to ensure we have the latest data; then duplicates it.
 * - For the new supply route, we set the archived property to FALSE
 * - No error handling done here; an error could be returned from the fetch or the upsert.
 * @param {String} accountId id of the account the supply route belongs to
 * @param {String} supplyRouteId id of the original supply route to be duplicated
 * @returns {Promise<Object>} returns the promise for the upsert API call for the new, duplicated supply route
 */
export const duplicateSupplyRoute = async (accountId, supplyRouteId) => {
  const supplyRoute = await getSupplyRoute({ accountId, supplyRouteId });

  const { _id, name, archived, ...rest } = supplyRoute;

  const newSupplyRoute = {
    ...rest,
    name: `${name} Copy`,
    archived: false,
  };

  return updateSupplyRoute({ accountId, supplyRoute: newSupplyRoute, reencode: false });
};
