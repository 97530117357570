import { ADD_TOAST, REMOVE_TOAST } from '../action-types';

const initialState = { status: null, message: '', showStatus: false };

const ToastReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOAST:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE_TOAST:
      return {
        ...state,
        showStatus: false,
      };

    default:
      return state;
  }
};

export default ToastReducer;
