import React from 'react';
import PropTypes from 'prop-types';

import { MIN_INSERTION_BOUNDARY } from '../constants';

import demoStyles from '../extension-demo.module.css';

const AvailableInsertionSpace = ({ maxInsertionBoundary, minInsertionBoundary, totalDuration }) => {
  const availableInsertionTime = maxInsertionBoundary - MIN_INSERTION_BOUNDARY;
  const containerWidthPercentage = (availableInsertionTime * 100) / totalDuration;
  const containerLeftPos = (minInsertionBoundary * 100) / totalDuration;

  return (
    <div
      className={demoStyles.availableInsertionSpace}
      style={{
        width: `${containerWidthPercentage}%`,
        left: `${containerLeftPos}%`,
      }}
    />
  );
};

AvailableInsertionSpace.propTypes = {
  maxInsertionBoundary: PropTypes.number.isRequired,
  minInsertionBoundary: PropTypes.number.isRequired,
  totalDuration: PropTypes.number.isRequired,
};

const InsertionPointSlider = ({
  contentDuration,
  minInsertionBoundary,
  maxInsertionBoundary,
  insertionPoint,
  onChange,
}) => {
  const value = insertionPoint || minInsertionBoundary;
  return (
    <>
      <input value={Math.floor(value)} max={Math.floor(contentDuration)} onChange={onChange} type="range" />
      <AvailableInsertionSpace
        maxInsertionBoundary={maxInsertionBoundary}
        minInsertionBoundary={minInsertionBoundary}
        totalDuration={contentDuration}
      />
    </>
  );
};

InsertionPointSlider.defaultProps = {
  insertionPoint: MIN_INSERTION_BOUNDARY,
};

InsertionPointSlider.propTypes = {
  contentDuration: PropTypes.number.isRequired,
  insertionPoint: PropTypes.number,
  maxInsertionBoundary: PropTypes.number.isRequired,
  minInsertionBoundary: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InsertionPointSlider;
