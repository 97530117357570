import { RECEIVE_ACCOUNT, LOGOUT } from '../action-types';

const initialState = {};

const AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ACCOUNT:
      return action.account;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default AccountReducer;
