export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const RECEIVE_USER = 'RECEIVE_USER';

export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT';
export const RECEIVE_CURRENT_ACCOUNT = 'RECEIVE_CURRENT_ACCOUNT';
export const RECEIVE_SELECTED_ACCOUNT = 'RECEIVE_SELECTED_ACCOUNT';
export const SWITCH_SELECTED_ACCOUNT = 'SWITCH_SELECTED_ACCOUNT';

export const RECEIVE_BREADCRUMB = 'RECEIVE_BREADCRUMB';
export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB';
export const RECEIVE_PATHNAME = 'RECEIVE_PATHNAME';

export const RECEIVE_NAVLINKS = 'RECEIVE_NAVLINKS';
export const UPDATE_ACTIVE_NAVLINK = 'UPDATE_ACTIVE_NAVLINK';

export const LOGOUT = 'LOGOUT';
export const ERROR = 'ERROR';
export const WARNING = 'WARNING';
export const INFO = 'INFO';
export const SUCCESS = 'SUCCESS';
export const UNSET = 'UNSET';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const UPDATE_CONTENT_STREAM = 'UPDATE_CONTENT_STREAM';
export const RESET_CONTENT_STREAM = 'RESET_CONTENT_STREAM';
