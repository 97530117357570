import React from 'react';
import classNames from 'classnames';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';

import {
  AcceptedFileTypes,
  BACKGROUND_SKIN_BUTTON_ID,
  DELETE_OVERLAY_BUTTON_ID,
  encodeArgsType,
  OVERLAY_BUTTON_ID,
  OVERLAY_SAMPLE_BUTTON_ID,
  SAMPLE_OVERLAY,
  setEncodeArgsType,
} from '../constants';
import { ComposerToastContext, toastStatus } from '../../../composer/composer-toast-context';
import useInputFileHandler from '../hooks/useInputFileHandler';
import DemoFooter from '../demo-footer';

import styles from '../../site.module.css';
import demoStyles from '../extension-demo.module.css';
import FileUploadButton from '../file-upload-button';
import RemoveIcon from '../svg/remove-icon';
import { deriveAdDurationFromContentDuration } from '../utils';

const OverlayFooter = ({ encodeArgs, setEncodeArgs }) => {
  const { setToast } = React.useContext(ComposerToastContext);
  const { inputFileHandler, uploading } = useInputFileHandler({
    encodeArgs,
    setEncodeArgs,
  });
  const { overlay: uploadedOverlay, contentDuration } = encodeArgs;

  const handleOnDelete = (e) => {
    const currentTargetId = e.currentTarget.id;
    switch (currentTargetId) {
      case DELETE_OVERLAY_BUTTON_ID:
        setEncodeArgs({
          ...encodeArgs,
          overlay: null,
        });
        setToast({ status: toastStatus.success, message: 'Successfully removed overlay!' });
        break;

      default:
        break;
    }
  };

  const handleOnSampleButtonClick = (e) => {
    const currentTargetId = e.currentTarget.id;
    const adDuration = deriveAdDurationFromContentDuration({ contentDuration });
    switch (currentTargetId) {
      case OVERLAY_SAMPLE_BUTTON_ID:
        setEncodeArgs({
          ...encodeArgs,
          adDuration,
          overlay: SAMPLE_OVERLAY,
        });
        setToast({ status: toastStatus.success, message: 'Successfully set sample overlay!' });
        break;

      default:
        break;
    }
  };

  return (
    <DemoFooter
      childrenContainerStyles={{
        display: 'flex',
        gap: 19,
      }}
      backNavDisabled={false}
      backNavPath="../upload-content"
      nextNavVisible
      nextNavDisabled={uploading || !uploadedOverlay}
      nextNavPath="../select-insertion"
    >
      <div className={demoStyles.pipButtonContainer}>
        <div className={classNames(styles.flex)} style={{ columnGap: 16 }}>
          {uploadedOverlay ? (
            <LoadingButton
              className={classNames(styles.button, styles.buttonPurple)}
              id={DELETE_OVERLAY_BUTTON_ID}
              onClick={handleOnDelete}
              startIcon={<RemoveIcon />}
            >
              Remove overlay
            </LoadingButton>
          ) : (
            <FileUploadButton
              acceptedTypes={Object.keys(AcceptedFileTypes[OVERLAY_BUTTON_ID]).join(',')}
              buttonId={BACKGROUND_SKIN_BUTTON_ID}
              isUploadingFile={uploading}
              handleOnChange={inputFileHandler}
              uploadedFile={!!uploadedOverlay}
              label="Upload overlay"
            />
          )}
          <Button
            className={classNames(styles.button, demoStyles.sampleContentButton)}
            disabled={uploading || !!uploadedOverlay}
            id={OVERLAY_SAMPLE_BUTTON_ID}
            onClick={handleOnSampleButtonClick}
          >
            Use sample overlay
          </Button>
        </div>
      </div>
    </DemoFooter>
  );
};

OverlayFooter.defaultProps = {};
OverlayFooter.propTypes = {
  encodeArgs: encodeArgsType.isRequired,
  setEncodeArgs: setEncodeArgsType.isRequired,
};

export default OverlayFooter;
