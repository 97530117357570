import { isDate } from 'date-fns';

export const adFormats = {
  interstitial: 'interstitial',
  pip: 'pip',
  squeezeBack: 'squeezeBack',
  overlay: 'overlay',
  enhancedCreative: 'enhancedCreative',
};

export const inStreamAdFormats = {
  [adFormats.overlay]: true,
  [adFormats.pip]: true,
  [adFormats.squeezeBack]: true,
};

export const noVideoAdFormats = {
  [adFormats.overlay]: true,
  [adFormats.squeezeBack]: true,
};

export const breakInsertionTypes = {
  interval: 'interval',
  manual: 'manual',
  preRoll: 'preRoll',
  scte: 'scte',
};

export const liveStreamStatColumnTypes = {
  string: 'string',
  number: 'number',
  date: 'date',
  percentage: 'percentage',
};

export const liveStreamStatColumnMap = {
  breakId: {
    name: 'Break Id',
    dataType: liveStreamStatColumnTypes.string,
    filterOptions: [],
  },
  scteId: {
    name: 'Scte Id',
    dataType: liveStreamStatColumnTypes.string,
    filterOptions: [],
  },
  breakTimestamp: {
    name: 'Time',
    dataType: 'date',
    filterOptions: [],
  },
  breakInsertionType: {
    name: 'Type',
    dataType: liveStreamStatColumnTypes.string,
    filterOptions: Object.keys(breakInsertionTypes).map((key) => ({
      label: key,
      filtered: key !== breakInsertionTypes.scte,
      ...(key === breakInsertionTypes.scte ? { checked: true } : {}),
    })),
  },
  adFormat: {
    name: 'Format',
    dataType: liveStreamStatColumnTypes.string,
    filterOptions: Object.keys(adFormats).map((key) => ({
      label: key,
      filtered: false,
    })),
  },
  peopleInBreak: {
    name: 'Concurrent Sessions',
    dataType: liveStreamStatColumnTypes.number,
    filterOptions: [],
  },
  breakDurationSec: {
    name: 'Planned Break Duration',
    dataType: liveStreamStatColumnTypes.number,
    filterOptions: [],
  },
  creativeDurationSec: {
    name: 'Ad Time Returned',
    dataType: liveStreamStatColumnTypes.number,
    filterOptions: [],
  },
  percentAvail: {
    name: 'Ad Server Coverage',
    dataType: liveStreamStatColumnTypes.percentage,
    filterOptions: [],
  },
  // breakEncodeDurationSec: {
  //   name: 'Ad Time Encoded',
  //   dataType: liveStreamStatColumnTypes.number,
  //   filterOptions: [],
  // },
  replaceableBreakDurationSec: {
    name: 'Actual Break Duration',
    dataType: liveStreamStatColumnTypes.number,
    filterOptions: [],
  },
  adTimeServed: {
    name: 'Ad Time Served',
    dataType: liveStreamStatColumnTypes.number,
    filterOptions: [],
  },
  fillRate: {
    name: 'Fill Rate',
    dataType: liveStreamStatColumnTypes.percentage,
    filterOptions: [],
  },
};

// TODO: for v4 additional session details
export const liveStreamAdditionalStatColumnMap = {
  vastCreativeIds: {
    name: 'Fill Rate',
    dataType: liveStreamStatColumnTypes.number,
    filterOptions: [],
  },
  selectedVastCreativeIds: {
    name: 'Fill Rate',
    dataType: liveStreamStatColumnTypes.number,
    filterOptions: [],
  },
  vastCreativeInfo: {
    name: 'Fill Rate',
    dataType: liveStreamStatColumnTypes.number,
    filterOptions: [],
  },
  deviceType: {
    name: 'Fill Rate',
    dataType: liveStreamStatColumnTypes.number,
    filterOptions: [],
  },
};

export const monitoringPathParams = {
  breakIdTable: 'break-id-table',
  monitoring: 'monitoring',
  barGraph: 'bar-graph',
  breakIdChart: 'break-id-chart',
  graphs: 'graphs',
};

export const monitoringTabTitles = {
  breakIdTable: 'Activity by Break ID',
  statsChart: 'Activity by Time',
  sessionIdTable: 'Activity by Session ID',
  breakIdChart: 'SCTE Activity by Break ID',
  graphs: 'Activity',
};

export const getParsedStatsResponse = (statsResponse) => {
  return statsResponse.map((stat) => {
    return {
      ...(stat.breakId ? { breakId: stat.breakId } : {}),
      ...(stat.scteId ? { scteId: stat.scteId } : {}),
      ...(stat.breakInsertionType ? { breakInsertionType: stat.breakInsertionType } : {}),
      ...(stat.adFormat ? { adFormat: stat.adFormat } : {}),
      ...(isDate(new Date(stat.breakTimestamp)) ? { breakTimestamp: new Date(stat.breakTimestamp) } : {}),
      ...(Number.isInteger(Number.parseInt(stat.peopleInBreak, 10))
        ? { peopleInBreak: Number.parseInt(stat.peopleInBreak, 10) }
        : {}),
      ...(typeof stat.creativeDurationSec === 'number'
        ? { creativeDurationSec: Number.parseFloat(stat.creativeDurationSec) }
        : {}),
      ...(typeof stat.breakDurationSec === 'number'
        ? { breakDurationSec: Number.parseFloat(stat.breakDurationSec) }
        : {}),
      ...(typeof stat.percentAvail === 'number' ? { percentAvail: Number.parseFloat(stat.percentAvail) } : {}),
      ...(typeof stat.breakEncodeDurationSec === 'number'
        ? { breakEncodeDurationSec: Number.parseFloat(stat.breakEncodeDurationSec) }
        : {}),
      ...(typeof stat.replaceableBreakDurationSec === 'number'
        ? { replaceableBreakDurationSec: Number.parseFloat(stat.replaceableBreakDurationSec) }
        : {}),
      ...(typeof stat.fillRate === 'number' ? { fillRate: Number.parseFloat(stat.fillRate) } : {}),
      ...(typeof stat.adTimeServed === 'number' ? { adTimeServed: Number.parseFloat(stat.adTimeServed) } : {}),
    };
  });
};
