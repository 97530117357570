import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import demoStyles from './extension-demo.module.css';
import styles from '../site.module.css';

const UnsupportedDevice = () => {
  return (
    <div className={demoStyles.unsupportedDeviceScreen}>
      <div className={demoStyles.unsupportedDeviceTextContainer}>
        <p className={demoStyles.unsupportedDeviceText}>Extension demo is not available for mobile devices.</p>
        <p className={demoStyles.unsupportedDeviceText}>Please visit on a desktop to use this feature.</p>
      </div>
      <Link
        className={classNames(styles.button, styles.buttonPurple)}
        style={{ width: 125, textAlign: 'center' }}
        to="/"
      >
        Return home
      </Link>
    </div>
  );
};

UnsupportedDevice.defaultProps = {};
UnsupportedDevice.propTypes = {};

export default UnsupportedDevice;
