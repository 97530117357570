import React from 'react';
import { Button } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import classNames from 'classnames';

import styles from '../site.module.css';
import scStyles from './stream-composer.module.css';
import YesLogo from '../svg/yes-logo';

const CaseStudyCard = () => {
  return (
    <div className={classNames(styles.white, scStyles.caseStudyCard, styles.mobileBorderRadius)}>
      <div className={scStyles.caseStudyContent}>
        <YesLogo />
        <p>See how the YES Network is working with Transmit to deploy new ad formats on streaming content.</p>
      </div>
      <div className={classNames(styles.flex, styles.justifyCenter, styles.flexCol, styles.alignCenter)}>
        <Button
          href="https://www.sportsbusinessjournal.com/Articles/2023/11/30/yes-network-transmit"
          target="_blank"
          className={classNames(styles.requestDemoButton, styles.button, styles.buttonWhite)}
        >
          <p>Read the Case Study</p>
          <ArrowOutwardIcon />
        </Button>
      </div>
    </div>
  );
};

CaseStudyCard.defaultProps = {};

CaseStudyCard.propTypes = {};

export default CaseStudyCard;
