import React from 'react';
import PropTypes from 'prop-types';

export const tlNavigate = (href) => {
  window.history.pushState({}, '', href);
  const navEvent = new PopStateEvent('popstate');
  window.dispatchEvent(navEvent);
};

/**
 *
 * @param {Object} data
 * @param {String} data.href
 * @param {any} data.children
 * @param {String=} data.className
 * @param {Object=} data.style
 * @param {Function=} data.onClick
 * @returns
 */
const TLLink = ({ href, children, className, style, onClick: onClickParam }) => {
  const onClick = (e) => {
    if (onClickParam) {
      onClickParam(e);
      return;
    }
    if (e.metaKey || e.ctrlKey) {
      return;
    }
    e.preventDefault();
    tlNavigate(href);
  };

  return (
    <a className={className} href={href} style={style} onClick={onClick}>
      {children}
    </a>
  );
};

TLLink.defaultProps = {
  href: '',
  children: [],
  className: '',
  style: undefined,
  onClick: undefined,
};

TLLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

export default TLLink;
