import { adFormatLabels } from '../components/composer/helper';
import { horizontalAlignment, verticalAlignment } from '../services/layout-service';
import { noAuthGet } from '../utils/common';

export const adFormatPlaceholder = '__AD_FORMAT__';

export const sampleHlsStream = 'https://tl-open-content-staging.s3.amazonaws.com/live-one-hour/stream.m3u8';

// speeds up testing; use these for the "try a sample stream" link
export const sampleHlsStreams = {
  basic: sampleHlsStream,
  drmSingleKeyCMAF:
    'https://cf98fa7b2ee4450e.mediapackage.us-east-1.amazonaws.com/out/v1/2fbd7c2ca8e8480a935b09b391a46876/encrypted-cmaf/index.m3u8',
  drmRotatingKeysCMAF:
    'https://cf98fa7b2ee4450e.mediapackage.us-east-1.amazonaws.com/out/v1/635a81120d9448798f929af8cf8cbfa7/encrypted-cmaf/index.m3u8',
  drmClearCMAF1:
    'https://cf98fa7b2ee4450e.mediapackage.us-east-1.amazonaws.com/out/v1/9be777b2f4cd46d5bcd7748c269eac9c/clear-cmaf/index.m3u8',
  drmClearCMAF2:
    'https://cf98fa7b2ee4450e.mediapackage.us-east-1.amazonaws.com/out/v1/b7250b6b589f4071907d11d89352325d/clear-cmaf-2/index-2.m3u8',
  drmDash:
    'https://cf98fa7b2ee4450e.mediapackage.us-east-1.amazonaws.com/out/v1/a308e786e43d4d6ea4fa06bafc36d245/index.mpd',
  drmClearDash:
    'https://cf98fa7b2ee4450e.mediapackage.us-east-1.amazonaws.com/out/v1/e60fc13fc58a4614b8f6a47bba15ea0b/index.mpd',
  drmViewLift:
    'https://ccf3786b925ee51c.mediapackage.us-east-1.amazonaws.com/out/v1/5e69f9d95bda48da839059bad9520c4a/index.m3u8', // fps only
  viewliftDash:
    'https://vl-msn-mm-trafficmanager-01.vos360.video/variant/v1/prod/DASH_DASH_ENC/Live/channel(cfbb0b9b-024c-b7e4-4712-7bf529332e04)/manifest.mpd',
  viewliftFairplay:
    'https://vl-msn-mm-trafficmanager-01.vos360.video/variant/v1/prod/HLS/Live/channel(cfbb0b9b-024c-b7e4-4712-7bf529332e04)/index.m3u8',
};

export const defaultConfiguration = {
  name: '',
  accountId: '',
  adTagGroups: [],
  adTags: [],
  sourceUrl: '',
  created: 0,
  updated: 0,
  vmapUrlTemplate: '',
};

export const serviceViews = {
  dashboard: 'dashboard',
  composer: 'composer/:accountSubdomain/streams',
  studio: 'studio',
  settings: 'settings',
};

export const serviceViewLabels = {
  dashboard: 'Dashboard',
  composer: 'Stream Composer',
  studio: 'Creative Studio',
  settings: 'Settings',
};

export const adTagAssetTypes = {
  video: 'video',
  background: 'background',
};

export const adTagAssetTypeDescription = {
  video: 'Video',
  background: 'Background',
};

export const CONFIG_TABLE_COLUMN_WIDTHS = {
  format: '15%',
  enabled: '10%',
  adTags: '55%',
  slate: '10%',
  prefetch: '10%',
};

/**
 *
 * @param {import('../user-context').User} user
 * @param {string} scope
 */
export const userHasScope = (user, scope) => {
  return user && user.scopes.includes(scope);
};

export const getVastMediaUrl = async (url) => {
  return noAuthGet(url)
    .then((res) => {
      if (!res.data) {
        return null;
      }
      const parser = new DOMParser();
      const doc = parser.parseFromString(res.data, 'application/xml');

      // VAST > Ad > InLine > Creatives > Creative > Linear > MediaFiles > MediaFile
      const creative = doc.querySelector('Creative');
      if (!creative) {
        return null;
      }
      const mediaFile = creative.querySelector('MediaFile');
      if (!mediaFile) {
        return null;
      }
      const mediaUrl = mediaFile.innerHTML.replace('<![CDATA[', '').replace(']]>', '');
      return mediaUrl;
    })
    .catch((err) => {
      console.log('getVastMediaUrl error', err);
      return null;
    });
};

export const formatDuration = (dur) => {
  let minutes = 0;
  if (dur > 59) {
    minutes = Math.floor(dur / 60);
  }
  const seconds = dur - minutes * 60;
  return `${Math.round(minutes).toString().padStart(2, '0')}:${Math.round(seconds).toString().padStart(2, '0')}`;
};

export const defaultAdTag = (format, background, video, adTag) => {
  let creativeScalePercent = 60;
  let contentScalePercent = 40;
  let creativeMarginTopPercent = 0;
  let creativeMarginRightPercent = 0;
  let creativeMarginBottomPercent = 0;
  let creativeMarginLeftPercent = 0;
  let contentMarginTopPercent = 0;
  let contentMarginRightPercent = 0;
  let contentMarginBottomPercent = 0;
  let contentMarginLeftPercent = 0;
  let contentHorizontalAlignment = horizontalAlignment.left;
  const contentVerticalAlignment = verticalAlignment.top;
  const creativeHorizontalAlignment = horizontalAlignment.right;
  const creativeVerticalAlignment = verticalAlignment.top;

  switch (format) {
    case 'pip':
      creativeMarginTopPercent = 13.5;
      creativeMarginRightPercent = 2.3;
      creativeMarginBottomPercent = 0;
      creativeMarginLeftPercent = 1.15;
      contentMarginTopPercent = 25.5;
      contentMarginRightPercent = 1.15;
      contentMarginBottomPercent = 0;
      contentMarginLeftPercent = 2.3;
      break;

    case 'squeezeBack':
      contentScalePercent = 80;
      creativeScalePercent = 0;

      creativeMarginTopPercent = 0;
      creativeMarginRightPercent = 0;
      creativeMarginBottomPercent = 0;
      creativeMarginLeftPercent = 0;
      contentMarginTopPercent = 0;
      contentMarginRightPercent = 0;
      contentMarginBottomPercent = 0;
      contentMarginLeftPercent = 0;

      contentHorizontalAlignment = horizontalAlignment.right;
      break;

    case 'overlay':
      contentScalePercent = 100;
      creativeScalePercent = 0;

      creativeMarginTopPercent = 0;
      creativeMarginRightPercent = 0;
      creativeMarginBottomPercent = 0;
      creativeMarginLeftPercent = 0;
      contentMarginTopPercent = 0;
      contentMarginRightPercent = 0;
      contentMarginBottomPercent = 0;
      contentMarginLeftPercent = 0;
      break;

    default:
      contentScalePercent = 0;
      creativeScalePercent = 100;

      creativeMarginTopPercent = 0;
      creativeMarginRightPercent = 0;
      creativeMarginBottomPercent = 0;
      creativeMarginLeftPercent = 0;
      contentMarginTopPercent = 0;
      contentMarginRightPercent = 0;
      contentMarginBottomPercent = 0;
      contentMarginLeftPercent = 0;
      break;
  }
  let backgroundId;
  let backgroundUrl;
  if (background) {
    ({ _id: backgroundId, url: backgroundUrl } = background);
  }
  let videoId;
  let videoUrl;
  if (video) {
    ({ _id: videoId, url: videoUrl } = video);
  }
  return {
    backgroundColor: '000000',
    borderColor: 'ffffff',
    adServer: 'External',
    rotating: true,
    ...adTag,
    format,
    backgroundId,
    backgroundUrl,
    videoId,
    videoUrl,
    layout: {
      formatName: format,
      creativeScalePercent,
      contentScalePercent,
      creativeMarginTopPercent,
      creativeMarginRightPercent,
      creativeMarginBottomPercent,
      creativeMarginLeftPercent,
      contentMarginTopPercent,
      contentMarginRightPercent,
      contentMarginBottomPercent,
      contentMarginLeftPercent,
      contentVerticalAlignment,
      contentHorizontalAlignment,
      creativeHorizontalAlignment,
      creativeVerticalAlignment,
      logoHorizontalAlignment: horizontalAlignment.left,
      logoMarginPercent: 0,
      logoWidth: 0,
      logoHeight: 0,
    },
  };
};

export const trimConfigurationForSave = (configuration) => {
  return {
    _id: configuration._id,
    accountId: configuration.accountId,
    name: configuration.name,
    sourceUrl: configuration.sourceUrl,
    slateAd: configuration.slateAd,
    adTagGroups: configuration.adTagGroups,
    pip: configuration.pip,
    squeezeBack: configuration.squeezeBack,
    overlay: configuration.overlay,
    interstitial: configuration.interstitial,
    inheritQuery: configuration.inheritQuery,
    streamAuth: configuration.streamAuth,
    delay: configuration.delay,
    createdAt: configuration.createdAt,
    updatedAt: configuration.updatedAt,
    drm: configuration.drm,
    overrides: Object.keys(configuration.overrides || {}).reduce((acc, key) => {
      acc[key] = {
        _id: configuration.overrides[key]._id,
        configurationId: configuration.overrides[key].configurationId,
        path: configuration.overrides[key].path,
        adTagGroups: configuration.overrides[key].adTagGroups,
        pip: configuration.overrides[key].pip,
        squeezeBack: configuration.overrides[key].squeezeBack,
        overlay: configuration.overrides[key].overlay,
        interstitial: configuration.overrides[key].interstitial,
        slateAd: configuration.overrides[key].slateAd,
      };
      return acc;
    }, {}),
    streamPrefetch: Object.keys(configuration.streamPrefetch || {}).reduce((acc, key) => {
      acc[key] = {
        _id: configuration.streamPrefetch[key]._id,
        configurationId: configuration.streamPrefetch[key].configurationId,
        path: configuration.streamPrefetch[key].path,
        prefetchVastUrl: configuration.streamPrefetch[key].prefetchVastUrl,
      };
      return acc;
    }, {}),
    streamAudio: configuration.streamAudio,
  };
};

export const createTiersFromFormatFilteredAdTags = (adTags) => {
  const tiers = {};

  for (let i = 0; i < adTags.length; i++) {
    const adTag = adTags[i];
    if (tiers[adTag.tier]) {
      tiers[adTag.tier].push(adTag);
      continue;
    }
    tiers[adTag.tier] = [adTag];
  }

  return tiers;
};

export const getAdTagTiersByFormat = (adTags, adTagGroups) => {
  const tags = adTags || [];
  const tagGroups = adTagGroups || [];

  const mongoTagMap = tags.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {});
  const mergedTags = tagGroups.map((tag) => {
    return {
      ...tag,
      ...mongoTagMap[tag.adTagId],
    };
  });

  const byFormat = {};
  for (const key of Object.keys(adFormatLabels)) {
    const filtered = mergedTags.filter((s) => s.format === key);
    if (filtered.length) {
      byFormat[key] = createTiersFromFormatFilteredAdTags(filtered);
    }
  }
  return byFormat;
};

export const redistributeWeight = (items, currentWeight, index) => {
  if (currentWeight >= 100) {
    let overAmount = currentWeight - 100;
    let dividedWeight = Math.floor(overAmount / index);
    for (let i = 0; i < index; i += 1) {
      if (items[i].weight >= dividedWeight) {
        overAmount -= dividedWeight;
        items[i].weight -= dividedWeight;
      } else {
        overAmount -= items[i].weight;
        dividedWeight = Math.floor(overAmount / (index - i));
        items[i].weight = 0;
      }
    }
    if (overAmount > 0) {
      for (let i = 0; i < index; i += 1) {
        if (items[i].weight >= overAmount) {
          items[i].weight -= overAmount;
          overAmount = 0;
        } else {
          overAmount -= items[i].weight;
          items[i].weight = 0;
        }
      }
    }
    for (let i = index + 1; i < items.length; i += 1) {
      items[i].weight = 0;
    }
  } else {
    let remainingWeight = 100 - currentWeight;
    if ([items.length, items.length - 1].includes(index)) {
      const dividedWeight = Math.floor(remainingWeight / index);
      for (let i = 0; i < index; i += 1) {
        remainingWeight -= dividedWeight;
        items[i].weight += dividedWeight;
      }
    } else {
      const dividedWeight = Math.floor(remainingWeight / (items.length - index - 1));
      for (let i = index + 1; i < items.length; i += 1) {
        remainingWeight -= dividedWeight;
        items[i].weight = dividedWeight;
      }
    }

    if (remainingWeight > 0 && items.length > 0) items[items.length - 1].weight += remainingWeight;
  }

  return items;
};

export const distributeGroupWeightsEqually = (adTagGroup) => {
  const group = [...adTagGroup];
  const evenWeight = Math.floor(100 / group.length);
  let weightSum = 0;
  for (let i = 0; i < group.length; i += 1) {
    group[i].weight = evenWeight;
    weightSum += evenWeight;
  }
  if (weightSum < 100) group[group.length - 1].weight += 100 - weightSum;
  return group;
};

export const distributeDeletedWeight = (adTagGroup) => {
  let currentWeight = 0;
  for (let i = 0; i < adTagGroup.length; i += 1) {
    currentWeight += adTagGroup[i].weight;
  }

  return redistributeWeight(adTagGroup, currentWeight, adTagGroup.length);
};

export const distributeRemainingWeight = (adTagGroup, index) => {
  let currentWeight = 0;
  for (let i = 0; i < index + 1; i += 1) {
    adTagGroup[i].weight = adTagGroup[i].weight ? adTagGroup[i].weight : 0;
    currentWeight += adTagGroup[i].weight;
  }

  return redistributeWeight(adTagGroup, currentWeight, index);
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};
