import { Button } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import styles from '../site.module.css';
import ExtensionIcon from '../svg/extension-icon';
import homeStyles from './home.module.css';

const ExtensionCard = React.memo(() => {
  return (
    <div className={classNames(styles.sectionCard, homeStyles.extensionCard)}>
      <div className={homeStyles.extensionCardHeader}>
        <video
          className={homeStyles.extensionCardHeaderVideo}
          src="https://tl-open-content.s3.amazonaws.com/web-assets/se-card-paramount-plus.mp4"
          width="100%"
          height="100%"
          loop
          muted
          playsInline
          autoPlay
        />

        <div className={classNames(styles.leftAlign, homeStyles.extensionCardHeaderDesc)}>
          <ExtensionIcon />
          <div className={styles.mt2} style={{ marginLeft: 3 }}>
            Stream Extension
          </div>
          <h2 className={classNames(styles.heading, styles.center, homeStyles.extensionHeader)}>
            <span>Boost</span> your audience
          </h2>
          <p className={classNames(styles.description, homeStyles.cardsDesc)}>
            Unlock the monetization potential of your promotional content, driving awareness alongside brand
            advertisers.
          </p>
          <div className={styles.mt2}>
            <Button className={classNames(styles.button, styles.buttonBlue)} href="/stream-extension">
              Explore Stream Extension
            </Button>
          </div>
        </div>
      </div>

      <div className={classNames(homeStyles.extensionMiniCards)}>
        <div className={classNames(styles.miniCard, homeStyles.extensionMiniCard, styles.mobileBorderRadius)}>
          <div className={styles.miniCardHeader}>
            <h3>
              Grab <span>attention</span>
            </h3>
            <div className={classNames(styles.description, homeStyles.cardsDesc)}>
              Power captivating content moments fueling business growth.
            </div>
          </div>

          <div className={classNames(homeStyles.extensionMiniCardImg, homeStyles.miniCardImgContainer)}>
            <img
              className={homeStyles.miniCardImg}
              src="https://tl-open-content.s3.amazonaws.com/web-assets/extension-grab-attention.png"
              alt=""
            />
          </div>
        </div>

        <div className={classNames(styles.miniCard, homeStyles.extensionMiniCard, styles.mobileBorderRadius)}>
          <div className={styles.miniCardHeader}>
            <h3>
              Promote <span>content</span>
            </h3>
            <div className={classNames(styles.description, homeStyles.cardsDesc)}>
              Engage customers with their favorite brand advertisers.
            </div>
          </div>

          <div className={classNames(homeStyles.extensionMiniCardImg, homeStyles.miniCardImgContainer)}>
            <img
              className={homeStyles.miniCardImg}
              src="https://tl-open-content.s3.amazonaws.com/web-assets/demo-promote-content.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ExtensionCard;
