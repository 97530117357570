import { matchPath, useLocation } from 'react-router-dom';

const DELIVERY_CREATIVE_STUDIO_ROUTES = [
  '/account/:accountId/delivery/creative/:creativeId',
  '/account/:accountId/delivery/advertiser/:advertiserId/creative/:creativeId',
];

export const StudioAdType = Object.freeze({
  IN_STREAM: 'inStream',
  IN_BREAK: 'inBreak',
});

export default function useStudioAdType() {
  const { pathname } = useLocation();

  const isContentSupplyRoute = matchPath(
    {
      path: '/account/:accountId/content-stream/supply-routes/:supplyRouteId',
      end: false,
    },
    pathname
  );
  const [isDeliveryRoute] = DELIVERY_CREATIVE_STUDIO_ROUTES.map((route) => {
    return matchPath({ path: route, end: false }, pathname);
  }).filter(Boolean);

  // eslint-disable-next-line
  return isContentSupplyRoute ? StudioAdType.IN_STREAM : isDeliveryRoute ? StudioAdType.IN_BREAK : undefined;
}
