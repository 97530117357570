export const NEW_ID_PARAM = 'new';

export const getPaletteImageUrl = (url) => {
  return url && url.startsWith('https') ? url?.replace('https', 'http') : url;
};

export const inBreakBaseUrl = ({ accountId, advertiserId }) => {
  return advertiserId
    ? `/account/${accountId}/delivery/advertiser/${advertiserId}/creative`
    : `/account/${accountId}/delivery/creative`;
};

export const inStreamBaseUrl = ({ accountId }) => `/account/${accountId}/content-stream/supply-routes`;
