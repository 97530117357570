import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAccount, getUser, isLoggedIn } from './services/user-service';

/**
 * @typedef {Object} User
 * @property {string} _id
 * @property {string} email
 * @property {string} first_name
 * @property {string} last_name
 * @property {Array<string>} scopes
 */

/**
 * @typedef {Object} Account
 * @property {string} _id
 * @property {string} name
 * @property {string} advertiserScope
 * @property {string} subdomain
 */

/**
 * @typedef {Object} UserData
 * @property {User|null} user
 * @property {React.Dispatch<User>} setUser
 * @property {Account} account
 * @property {React.Dispatch<Account>} setAccount
 * @property {Account} selectedAccount
 * @property {React.Dispatch<Account>} setSelectedAccount
 */

/** @type {UserData} */
const initialState = {
  user: null,
  setUser: () => {},
  account: null,
  setAccount: () => {},
  selectedAccount: null,
  setSelectedAccount: () => {},
};

export const UserContext = createContext(initialState);

export const selectedAccountKey = 'selectedAccount';

/**
 *
 * @param {Object} data
 * @param {any} data.children
 * @returns
 */
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const getLocalSelectedAccount = () => {
    return window.localStorage.getItem(selectedAccountKey);
  };

  const setLocalSelectedAccount = (localSelectedAccount) => {
    if (!localSelectedAccount) {
      return;
    }
    window.localStorage.setItem(selectedAccountKey, JSON.stringify(localSelectedAccount));
  };

  useEffect(() => {
    setLocalSelectedAccount(selectedAccount);
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedAccount) {
      return;
    }
    const localSelctedAccount = getLocalSelectedAccount();
    if (!localSelctedAccount) {
      setLocalSelectedAccount(account);
    }
  }, [account]);

  useEffect(() => {
    const localSelctedAccount = getLocalSelectedAccount();
    if (localSelctedAccount) {
      setSelectedAccount(JSON.parse(localSelctedAccount));
    }

    if (isLoggedIn()) {
      getUser()
        .then((u) => {
          getAccount().then((a) => {
            setUser(u);
            setAccount(a);
          });
        })
        .catch(() => {
          if (window.location.pathname !== '/login') {
            window.location.replace('/login');
          }
        });
    }
  }, []);

  const selectedOrCurrentAccount = selectedAccount || account;

  return (
    <UserContext.Provider
      value={{ user, setUser, account, setAccount, selectedAccount: selectedOrCurrentAccount, setSelectedAccount }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.defaultProps = {
  children: [],
};

UserContextProvider.propTypes = {
  children: PropTypes.node,
};

export default UserContextProvider;
