import axios from 'axios';
import Cookies from 'js-cookie';

export const jwtScopes = {
  read: 'read',
  write: 'write',
  composerRead: 'composer-read',
  composerWrite: 'composer-write',
  externalRead: 'external-read',
  externalWrite: 'external-write',
};

export const loginUser = async (email, password) => {
  const url = `${SERVICE_URL}/api/v1/signIn`;
  return axios.post(url, { email, password }).then((response) => {
    return response.data;
  });
};

export const emailLoginRequest = async (email) => {
  const url = `${SERVICE_URL}/api/v1/email-login-request`;
  return axios.post(url, { email }).then((response) => {
    return response.data;
  });
};

export const getUser = async () => {
  const url = `${SERVICE_URL}/api/v1/user`;
  return axios.get(url).then((response) => response.data);
};

export const getAccount = async () => {
  const url = `${SERVICE_URL}/api/v1/user/account`;
  return axios.get(url).then((response) => response.data);
};

// for updating the current user's info. no user create/upsert (admin does that).
export const updateUser = async (user = {}) => {
  const { _id: userId, email, first_name, last_name } = user;

  const url = `${SERVICE_URL}/api/v1/user/${userId}`;
  return axios
    .put(url, {
      email,
      first_name,
      last_name,
    })
    .then((response) => response.data);
};

export const isLoaded = ({ account, currentAccount, user }) => {
  return Object.keys(user).length && Object.keys(account).length && Object.keys(currentAccount).length;
};

export const isLoggedIn = () => {
  return Boolean(Cookies.get('authToken'));
};
