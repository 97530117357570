/* eslint-disable max-len */
import React from 'react';

const EfficientEncodingIcon = React.memo(() => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 14C1 17.4478 2.36964 20.7544 4.80761 23.1924C7.24559 25.6304 10.5522 27 14 27C17.4478 27 20.7544 25.6304 23.1924 23.1924C25.6304 20.7544 27 17.4478 27 14C27 10.5522 25.6304 7.24559 23.1924 4.80761C20.7544 2.36964 17.4478 1 14 1C10.5522 1 7.24559 2.36964 4.80761 4.80761C2.36964 7.24559 1 10.5522 1 14Z"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 14.0001V9.35721" stroke="#3CB094" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 14L19.803 19.8042" stroke="#3CB094" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
));

export default EfficientEncodingIcon;
