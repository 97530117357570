.streamComposer {
  padding-top: 175px;
  padding-bottom: 50px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 55%,
    rgba(221, 245, 238, 1) 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.streamComposer span {
  color: var(--siteGreen);
}

.header {
  display: flex;
  margin: 0px 44px;
}

.miniCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;
  gap: 30px;
  margin: 48px 0;
}

.headingSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 45px;
  min-height: 200px;
  margin-top: 36px;
}

.caseStudyCard {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--sitePurpleDark);
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding: 79px 107px;
  border-radius: 24px;
  box-shadow: 0px 73px 143px 0px rgba(116, 81, 211, 0.21), 0px 36.536px 71.57px 0px rgba(116, 81, 211, 0.16),
    0px 22.007px 43.11px 0px rgba(116, 81, 211, 0.14), 0px 14.103px 27.627px 0px rgba(116, 81, 211, 0.12),
    0px 9.141px 17.906px 0px rgba(116, 81, 211, 0.1), 0px 5.754px 11.272px 0px rgba(116, 81, 211, 0.09),
    0px 3.306px 6.476px 0px rgba(116, 81, 211, 0.07), 0px 1.455px 2.85px 0px rgba(116, 81, 211, 0.05);
}

.moreInfoCards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-left: 44px;
}

.headerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.headerHeading {
  margin: 10px 0px;
  max-width: 476px;
}

.headerDesc {
  max-width: 354px;
}

.composerIconSection {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 2px;
}
.composerIconSection svg {
  width: 28px;
}

.headerVideo video {
  border-radius: 24px;
}

.miniCard {
  padding: 44px 24px;
}

.premiumCpmCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: var(--siteGreenLighter);
  padding-right: 0;
  padding-bottom: 0;
}

.premiumCpmCard img {
  align-self: flex-end;
  width: calc(100% - 40px);
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
}

.brandAffinityCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: var(--siteGreenLighter);
}

.brandAffinityCard img {
  align-self: flex-end;
  width: calc(100% - 20px);
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
}

.brandAffinityContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  color: var(--siteGreen);
  gap: 10px;
}

.brandAffinityPercentage {
  font-size: 90px;
  font-weight: 600;
  line-height: normal;
}

.brandAffinityContentDesc {
  color: var(--siteGreen);
  margin: 0px;
  font-weight: 600;
}

.stayWithActionCard {
  display: grid;
  grid-template: 1fr / 1fr 2fr;
  gap: 10px;
  background-color: var(--siteGreenLighter);
  grid-column: span 2;
  padding-right: 0px;
}

.stayWithActionHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stayWithActionCard img {
  width: 100%;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
}

.moreInfoCards::-webkit-scrollbar {
  display: none;
}

.moreInfoCard {
  min-height: 288px;
  min-width: 538px;
  background-color: var(--siteGreyLight);
  border-radius: 24px;
  padding: 51px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.moreInfoCard h3 {
  line-height: normal;
}

.moreInfoIcon svg {
  height: 25px;
  width: 25px;
}

.caseStudyContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 684px;
}
.caseStudyContent svg {
  height: 45px;
}
.caseStudyContent p {
  font-size: 41px;
  line-height: normal;
  text-align: left;
}

.caseStudyCard button {
  width: 100%;
  text-wrap: nowrap;
  padding: 17px 20px;
  color: var(--sitePurpleDark);
  gap: 14px;
  font-size: 20px;
  font-weight: 500;
  width: 251px;
  > svg {
    color: var(--sitePurpleDark);
  }
}

.headerInner video {
  max-width: 814px;
  width: 100%;
  height: 100%;
}

.sectionCard {
  display: flex;
  justify-content: center;
}

@media (min-width: 1536px) {
  .header {
    max-width: 1448px;
  }

  .streamComposer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .miniCards {
    margin: 0;
  }

  .headingSection {
    padding: 0;
  }

  .caseStudyCard {
    max-width: 1448px;
  }

  .moreInfoCards {
    margin: 0;
  }

  .caseStudyWrapper {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .streamComposer {
    padding-top: 100px;
  }

  .description {
    margin-top: 10px;
  }

  .caseStudyCard {
    flex-direction: column;
    padding: 39px 53px;
  }

  .headerInner {
    flex-direction: column;
    align-items: unset;
  }

  .stayWithActionCard {
    grid-template: 1fr / 1fr;
  }

  .miniCards {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: unset;
    row-gap: 15px;
    margin: 0;
    padding: 0 44px;
  }

  .miniCard {
    padding: 0;
  }

  .premiumCpmCard {
    align-items: unset;
  }

  .premiumCpmCard img {
    width: calc(100% - 80px);
    border-radius: 12px;
    margin-bottom: 30px;
    align-self: unset;
    padding: 0 40px;
    border-radius: 12px;
  }

  .brandAffinityCard {
    grid-row-start: 2;
  }

  .headingSection {
    min-height: unset;
    margin: 61px 0 27px 0;
  }

  .moreInfoCards {
    padding: 0;
  }

  .newInventorySection {
    padding: 0 44px;
  }

  .caseStudyWrapper {
    padding: 0 44px;
  }
}

@media (max-width: 600px) {
  .miniCards {
    padding: 0 24px;
    grid-template-rows: unset;
  }

  .premiumCpmCard img {
    margin-bottom: 40px;
  }

  .header {
    margin: 0px 24px;
  }

  .newInventorySection {
    margin-top: 56px;
    padding: 0;
  }

  .sectionCard {
    padding: 35px 0;
    display: block;
    justify-content: unset;
  }

  .caseStudyContent p {
    font-size: 24px;
  }

  .caseStudyCard {
    gap: 23px;
    padding: 30px 24px;
  }

  .moreInfoCards {
    margin-left: 24px;
  }

  .moreInfoCard {
    min-width: 375px;
  }

  .caseStudyWrapper {
    padding: 0 24px;
  }

  .brandAffinityContent {
    margin-bottom: 40px;
  }
}
