import { ASSET_TYPES, MIN_PREVIEW_LOGO_WIDTH } from '../components/creative-studio/studio-sidebar/assets/assets-helper';
import { ASPECT_RATIOS, determineResolutionWithError } from '../services/layout-service';

/* fileSizeLimit in kb */
export const fileSizeLimits = {
  logo: 1000,
  overlay: 10000,
  skin: 25000,
  video: 1000000,
  transition: 10000,
};

export const fileDurationLimits = {
  overlay: 45,
  skin: 45,
};

export const acceptContentTypes = (assetType) => {
  switch (assetType) {
    case 'video':
      return 'video/mp4';
    case 'transition':
      return 'image/gif,image/jpeg';
    case 'overlay':
    default:
      return 'image/gif,image/jpeg,image/png';
  }
};

const validateDimensionAssetTypes = {
  [ASSET_TYPES.skin.key]: true,
  [ASSET_TYPES.transition.key]: true,
  [ASSET_TYPES.video.key]: true,
};

export const getImageMetadata = async ({ url }) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      const { width: imageWidth, height: imageHeight } = image;
      resolve({ width: imageWidth, height: imageHeight, url });
    };
    image.onerror = () => {
      reject(new Error('Image is invalid.'));
    };
  });
};

export const isUploadAssetValid = async ({ file, assetType }) => {
  const { size } = file;
  let validFile = true;
  let width;
  let height;
  let duration;
  let imageMetaData;
  let videoMetaData;
  const validationErrors = [];

  try {
    if (assetType === ASSET_TYPES.video.key) {
      videoMetaData = await new Promise((resolve, reject) => {
        const url = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.src = url;
        video.addEventListener('loadedmetadata', () => {
          resolve({ duration: video.duration, width: video.videoWidth, height: video.videoHeight });
        });
        video.onerror = () => {
          reject(new Error(`The file ${file.name} is invalid.`));
        };
      });

      duration = videoMetaData.duration || 0;
      width = videoMetaData.width || 0;
      height = videoMetaData.height || 0;
    } else if (file.type === 'image/gif') {
      imageMetaData = await new Promise((resolve, reject) => {
        const url = URL.createObjectURL(file);
        const image = new Image();
        image.src = url;
        image.onload = () => {
          const { width: imageWidth, height: imageHeight } = image;

          const getGifDuration = (ab) => {
            const d = new Uint8Array(ab);
            let gifDuration = 0;
            for (let i = 0; i < d.length; i += 1) {
              // Find a Graphic Control Extension hex(21F904__ ____ __00)
              if (d[i] === 0x21 && d[i + 1] === 0xf9 && d[i + 2] === 0x04 && d[i + 7] === 0x00) {
                // Swap 5th and 6th bytes to get the delay per frame
                // eslint-disable-next-line no-bitwise
                const delay = (d[i + 5] << 8) | (d[i + 4] & 0xff);

                // Should be aware browsers have a minimum frame delay
                // e.g. 6ms for IE, 2ms modern browsers (50fps)
                gifDuration += delay < 2 ? 10 : delay;
              }
            }

            return gifDuration / 100;
          };

          file.arrayBuffer().then((ab) => {
            const gifDurationSeconds = getGifDuration(ab);
            if (gifDurationSeconds > fileDurationLimits[assetType]) {
              reject(new Error(`The GIF ${file.name} can't be longer than ${fileDurationLimits[assetType]} seconds`));
              return;
            }

            resolve({ width: imageWidth, height: imageHeight, url });
          });
        };
        image.onerror = () => {
          reject(new Error(`The file ${file.name} is invalid.`));
        };
      });

      width = imageMetaData.width || 0;
      height = imageMetaData.height || 0;
    } else {
      imageMetaData = await new Promise((resolve, reject) => {
        const url = URL.createObjectURL(file);
        const image = new Image();
        image.src = url;
        image.onload = () => {
          const { width: imageWidth, height: imageHeight } = image;
          resolve({ width: imageWidth, height: imageHeight, url });
        };
        image.onerror = () => {
          reject(new Error(`The file ${file.name} is invalid.`));
        };
      });

      width = imageMetaData.width || 0;
      height = imageMetaData.height || 0;
    }
  } catch (e) {
    validFile = false;
    validationErrors.push(e);
  }

  // check for valid types (needed for bulk uploads)
  if (!acceptContentTypes(assetType).includes(file.type)) {
    validFile = false;
    validationErrors.push(`${file.name} must be one of the following: ${acceptContentTypes(assetType)}`);
  }

  if (size > fileSizeLimits[assetType] * 1000) {
    validFile = false;
    validationErrors.push(`${file.name} exceeds file size limit of ${fileSizeLimits[assetType]}kb for ${assetType}s.`);
  }

  if (validateDimensionAssetTypes[assetType]) {
    const { key: ratio } = determineResolutionWithError({ width, height, errorMarginPercent: 10 });
    if (![ASPECT_RATIOS.sixteenByNine.key, ASPECT_RATIOS.nineBySixteen.key].includes(ratio)) {
      URL.revokeObjectURL(imageMetaData?.url);
      validFile = false;
      validationErrors.push(`${file.name} must have 16:9 or 9:16 aspect ratio`);
    }
  }

  if (assetType === ASSET_TYPES.previewLogo.key && width < MIN_PREVIEW_LOGO_WIDTH) {
    validFile = false;
    validationErrors.push(`${file.name} must be at least 250px wide`);
  }

  return {
    validFile,
    width,
    height,
    duration,
    validationErrors: validFile ? {} : { [`/${assetType}_id`]: validationErrors.join(' ') },
  };
};
