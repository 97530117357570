import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import classNames from 'classnames';

import styles from '../site.module.css';
import tabbedStyles from './tabbed-section.module.css';

const TabbedSection = ({ tabs }) => {
  const [activeTab, setActiveTab] = React.useState(tabs[0]);
  const [tabbedSectionOverflowing, setTabbedSectionOverflowing] = React.useState(false);
  const tabbedSectionRef = React.useRef(null);

  // eslint-disable-next-line
  React.useEffect(() => {
    if (tabbedSectionRef?.current) {
      const observer = new ResizeObserver(() => {
        const element = tabbedSectionRef?.current;
        const hasOverflowingChildren = element.offsetWidth < element.scrollWidth;
        setTabbedSectionOverflowing(hasOverflowingChildren);
      });
      observer.observe(tabbedSectionRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [tabbedSectionRef.current]);

  const renderTabButton = (tab, index) => {
    const isActive = tab.title === activeTab.title;
    const { title } = tab;

    return (
      <Button
        variant="contained"
        key={`${title}-${index}`}
        className={classNames(styles.button, isActive && tabbedStyles.activeTabBtn)}
        onClick={() => setActiveTab(tab)}
      >
        {title}
      </Button>
    );
  };

  return (
    <>
      <div
        className={tabbedStyles.tabbedSection}
        ref={tabbedSectionRef}
        style={{
          ...((!tabbedSectionOverflowing && { alignItems: 'center' }) || {}),
        }}
      >
        <div className={tabbedStyles.tabButtons}>{tabs.map(renderTabButton)}</div>
      </div>
      <div className={classNames(tabbedStyles.tabContent, styles.mt3)}>{activeTab.content}</div>
    </>
  );
};

TabbedSection.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
    })
  ).isRequired,
};

export default TabbedSection;
