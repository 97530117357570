/* eslint-disable max-len */
import React from 'react';

const ExtensionIcon = React.memo(() => (
  <svg viewBox="0 0 73 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.631104" y="0.260742" width="72" height="70" rx="24" fill="#C7E4FC" />
    <path
      d="M20.7004 51.3719L33.1171 38.9552"
      stroke="#3490E0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3228 38.9552H33.1172V43.9219"
      stroke="#3490E0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.4617 51.3718L47.1895 35.644"
      stroke="#3490E0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.9116 35.644H47.1894V43.9218"
      stroke="#3490E0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.4198 41.6041C54.5626 40.1141 55.8811 37.7963 55.8811 34.9819C55.8811 30.5118 52.2549 26.8696 47.8048 26.8696C46.1565 26.8696 44.5083 27.3663 43.1898 28.3596C42.036 22.5652 37.0914 18.2607 30.9928 18.2607C24.2351 18.2607 18.6311 23.8896 18.6311 30.6774C18.6311 34.1541 20.1145 37.2996 22.422 39.6174"
      stroke="#3490E0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default ExtensionIcon;
