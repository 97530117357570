import { ExpandMoreOutlined } from '@mui/icons-material';
import { createTheme } from '@mui/material';
import { studioPrimaryColor } from './creative-studio/studio-variables';

const theme = createTheme({
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Roboto', 'sans-serif'].join(','),
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.MuiCheckbox-root': {
            color: studioPrimaryColor,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.MuiRadio-root': {
            color: studioPrimaryColor,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreOutlined,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
