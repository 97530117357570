import { useContext, useState } from 'react';

import { ComposerToastContext, toastStatus } from '../../../composer/composer-toast-context';
import { postAssetS3SignedUrl } from '../../../../services/asset-service';
import { isUploadAssetValid } from '../../../../utils/asset-utils';
import { noAuthPut } from '../../../../utils/common';
import { ASSET_TYPES } from '../../../creative-studio/studio-sidebar/assets/assets-helper';
import {
  AcceptedFileTypes,
  AD_BUTTON_ID,
  BACKGROUND_SKIN_BUTTON_ID,
  CONTENT_BUTTON_ID,
  OVERLAY_BUTTON_ID,
} from '../constants';
import { deriveAdDurationFromContentDuration } from '../utils';

const useInputFileHandler = ({ encodeArgs, setEncodeArgs }) => {
  const { setToast } = useContext(ComposerToastContext);
  const [uploading, setUploading] = useState(false);
  const [targetId, setTargetId] = useState(null);

  const inputFileHandler = async (e) => {
    const files = e.target.files || e.dataTransfer.files;
    if (files) {
      const currentTargetId = e.currentTarget.id;
      setTargetId(currentTargetId);
      const isContentFileUpload = currentTargetId === CONTENT_BUTTON_ID;
      /* eslint-disable */
      const assetType =
        currentTargetId === BACKGROUND_SKIN_BUTTON_ID
          ? ASSET_TYPES.skin.key
          : currentTargetId === OVERLAY_BUTTON_ID
          ? ASSET_TYPES.overlay.key
          : ASSET_TYPES.video.key;
      const [file] = Array.from(files);
      const { type } = file;

      if (AcceptedFileTypes[currentTargetId][type]) {
        const { duration, validFile, validationErrors, height, width } = await isUploadAssetValid({
          file,
          assetType,
        });

        if (validationErrors && validationErrors['/video_id']) {
          setToast({ status: toastStatus.error, message: validationErrors['/video_id'] });

          return null;
        }

        if (validFile) {
          let signedS3Url;
          let url;
          if (isContentFileUpload) {
            // prevent upload of files with length lesser than 15 seconds
            if (duration < 15) {
              setToast({
                status: toastStatus.error,
                message: 'Please ensure your content video is longer than 15 seconds.',
              });
              return null;
            }

            // prevent upload of files with length greater than 11 minutes
            if (duration > 660) {
              setToast({
                status: toastStatus.error,
                message: 'Please ensure your content video is shorter than 11 minutes.',
              });
              return null;
            }

            try {
              const assetUrlResponse = await postAssetS3SignedUrl(
                { asset: { type }, folder: 'extension-demo-tool' },
                assetType
              );
              signedS3Url = assetUrlResponse.signedS3Url;
              url = assetUrlResponse.url;
            } catch (error) {
              setToast({
                status: toastStatus.error,
                message: 'Failed to upload the file. Please try again',
              });
              return null;
            }

            noAuthPut(signedS3Url, file, {
              headers: {
                'Content-Type': type,
              },
              onUploadProgress: () => {
                setUploading(true);
              },
            }).then(async () => {
              setUploading(false);
              setEncodeArgs({
                ...encodeArgs,
                contentUrl: url,
                contentDuration: duration,
                contentWidth: width,
                contentHeight: height,
              });
              setToast({
                status: toastStatus.success,
                message: 'Successfully uploaded!',
              });
            });
          } else {
            // prevent upload of ad with length greater than content
            if (currentTargetId === AD_BUTTON_ID && duration > encodeArgs.contentDuration) {
              setToast({
                status: toastStatus.error,
                message: 'Please ensure your ad video is not longer than content video.',
              });
              return null;
            }

            try {
              const assetUrlResponse = await postAssetS3SignedUrl(
                { asset: { type }, folder: 'extension-demo-tool' },
                assetType
              );
              signedS3Url = assetUrlResponse.signedS3Url;
              url = assetUrlResponse.url;
            } catch (error) {
              setToast({
                status: toastStatus.error,
                message: 'Failed to upload the file. Please try again',
              });
              return null;
            }

            noAuthPut(signedS3Url, file, {
              headers: {
                'Content-Type': type,
              },
              onUploadProgress: () => {
                setUploading(true);
              },
            })
              .then(async () => {
                setUploading(false);
                setEncodeArgs({
                  ...encodeArgs,
                  adUrl: currentTargetId === AD_BUTTON_ID ? url : encodeArgs.adUrl,
                  adDuration:
                    currentTargetId === AD_BUTTON_ID
                      ? duration
                      : deriveAdDurationFromContentDuration({ contentDuration: encodeArgs.contentDuration }),
                  skin: currentTargetId === BACKGROUND_SKIN_BUTTON_ID ? url : encodeArgs.skin,
                });
                setToast({
                  status: toastStatus.success,
                  message: 'Successfully uploaded!',
                });
              })
              .catch(() => {
                setUploading(false);
                setToast({
                  status: toastStatus.error,
                  message: 'Failed to upload the file. Please try again',
                });
              });
          }

          return { ok: true };
        }
      }

      return null;
    }

    return null;
  };

  return {
    inputFileHandler,
    uploading,
    targetId,
  };
};

export default useInputFileHandler;
