import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import homeStyles from './home.module.css';
import styles from '../site.module.css';

const NielsenMetric = ({ title, description, percentage }) => {
  return (
    <div className={homeStyles.nielsenMetric}>
      <div className={classNames(homeStyles.nielsenMetricPercentage, styles.mobileBorderRadius)}>
        <span>{percentage}</span>
        <span>%</span>
      </div>
      <div>
        <h3>{title}</h3>
        <p className={homeStyles.nielsenMetricsDesc}>{description}</p>
      </div>
    </div>
  );
};

NielsenMetric.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default NielsenMetric;
