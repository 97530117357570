.pressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  margin-bottom: 48px;
}

.articleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 365px;
  min-height: 275px;
  border-radius: 24px;
  background-color: #ffffff;
  gap: 12px;
  padding: 25px;
}

.iconContainer {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
}

.favIcon {
  width: 38px;
  height: 38px;
}

.publicationName {
  color: #232833;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.articleTitle {
  color: #232833;
  font-family: Selecta;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.articleLink {
  color: #232833;
  font-family: Selecta;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.articlesContainer {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 700px) {
  .articleTitle {
    font-size: 24px;
  }

  .articlesContainer {
    justify-content: unset;
  }

  .articleContainer {
    width: 100%;
    height: 206px;
    padding: 18px;
  }
}
