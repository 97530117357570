/* eslint-disable import/no-extraneous-dependencies */
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import RootReducer from './reducers';
import { studioInitialState } from './creative-studio/studio-reducer';

const initialState = {
  studio: studioInitialState,
};

const middlewares = [thunk, promise];

/*eslint-disable */
if (DEVELOPMENT && process.env.NODE_ENV !== 'test') {
  const logger = require('redux-logger').createLogger({
    collapsed: true,
    diff: true,
  });
  middlewares.push(logger);
}
/*eslint-enable */

const store = createStore(RootReducer, initialState, applyMiddleware(...middlewares));

export default store;
