import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ENCODE_STATUS } from '../../../creative-studio/preview/studio-preview-helper';
import InProgressIcon from '../svg/in-progress-icon';
import FailureIcon from '../svg/failure-icon';
import CompleteIcon from '../svg/complete-icon';

import styles from '../../site.module.css';
import demoStyles from '../extension-demo.module.css';

const getStatusProps = (status) => {
  switch (status) {
    case ENCODE_STATUS.none:
      return {
        icon: <InProgressIcon className={demoStyles.shareFormEncodeStatusIcon} />,
        label: <p className={demoStyles.shareFormEncodeStatus}>Encode not started</p>,
      };

    case ENCODE_STATUS.submitted:
    case ENCODE_STATUS.encoding:
      return {
        icon: (
          <InProgressIcon
            className={classNames(demoStyles.shareFormEncodeStatusIcon, demoStyles.inProgressEncodeStatus)}
          />
        ),
        label: <p className={demoStyles.shareFormEncodeStatus}>Encode in progress</p>,
      };

    case ENCODE_STATUS.error:
      return {
        icon: <FailureIcon />,
        label: <p className={demoStyles.shareFormFailedEncodeStatus}>Encode failed</p>,
      };

    case ENCODE_STATUS.complete:
      return {
        icon: <CompleteIcon />,
        label: <p className={demoStyles.shareFormCompletedEncodeStatus}>Encode completed</p>,
      };

    default:
      return {};
  }
};

const EncodeStatus = ({ status }) => {
  const { icon, label } = getStatusProps(status);

  return (
    <div
      className={classNames(styles.flex, styles.flexCol, styles.alignCenter)}
      style={{
        gap: 6,
      }}
    >
      {icon}
      {label}
    </div>
  );
};

EncodeStatus.defaultProps = {
  status: ENCODE_STATUS.none,
};
EncodeStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(ENCODE_STATUS)),
};

export default EncodeStatus;
