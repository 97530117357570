import React from 'react';

const LbarIcon = React.memo(() => {
  return (
    <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="33" height="22" rx="3" stroke="#5A24FB" strokeWidth="2" />
      <path d="M6 1H31C32.6569 1 34 2.34315 34 4V19H9C7.34315 19 6 17.6569 6 16V1Z" stroke="#5A24FB" strokeWidth="2" />
    </svg>
  );
});

export default LbarIcon;
