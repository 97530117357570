import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import classNames from 'classnames';
import variables from '../../../styles/variables';
import styles from '../site.module.css';
import homeStyles from './home.module.css';

const useStyles = makeStyles(() => ({
  formatHeader: {
    fontFamily: 'Selecta, sans-serif',
    lineHeight: 'normal',
  },
  formats: {
    display: 'grid',
    grid: 'auto / repeat(4, 1fr)',
    rowGap: 16,
    columnGap: 16,
    '& .format': {
      backgroundColor: variables.white,
      padding: '32px 25px',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',

      '&.pip-in-viewport': {
        '& .content': {
          animation: '$pip 1.2s ease-in-out forwards',
        },
      },

      '&.vertical-in-viewport': {
        '& .content': {
          animation: '$vertical 1.2s ease-in-out forwards',
        },
      },

      '&.reveal-in-viewport': {
        '& .content': {
          animation: '$reveal 1.2s ease-in-out 0s forwards',
        },
      },

      '&.overlay-in-viewport': {
        '& .creative': {
          animation: '$overlay 1.2s ease-in-out 0s forwards',
        },
      },

      '&.reveal': {
        '& .content': {
          transformOrigin: '270px top',
        },
      },

      '&.vertical': {
        '& .creative': {
          width: '81px',
          height: '153px',
          fontSize: '13px',
          position: 'absolute',
          left: '0px',
          top: '0px',
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        },

        '& .content': {
          transformOrigin: 'right top',
          width: 'calc(100% - 3px)',
        },

        '& .desc': {
          right: 45,
          bottom: 15,
          left: 'initial',
        },
      },

      '&.overlay': {
        '& .creative': {
          zIndex: 200,
          opacity: 0,
          width: '247px',
          height: '31px',
          fontSize: '13px',
          position: 'absolute',
          left: '17px',
          top: '118px',
        },
      },
      '& .screen': {
        margin: '30px 0px 0 0',
        width: '280px',
        height: '157px',
        border: `1px solid ${variables.composerBorderGrey}`,
        borderRadius: '10px',
        position: 'relative',
        background: `repeating-linear-gradient(-45deg, transparent 0 5px,${variables.composerLightPurple} 5px 6px)`,
        boxShadow: '0px -20px 48px -35px rgb(90 36 251 / 50%)',

        '& .content': {
          display: 'flex',
          border: `2px solid ${variables.composerBlack}`,
          borderRadius: '10px',
          width: '278px',
          height: '155px',
          position: 'relative',
          backgroundColor: variables.white,
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '16px',
          fontWeight: '500',
          zIndex: 100,
        },

        '& .creative': {
          display: 'flex',
          border: `2px solid ${variables.composerMarketingPurple}`,
          borderRadius: '10px',
          width: '149px',
          height: '84px',
          position: 'absolute',
          top: '29px',
          left: '120px',
          backgroundColor: variables.composerVeryLightPurple,
          color: variables.composerMarketingPurple,
          fontSize: '13px',
          fontWeight: '500',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& .desc': {
          position: 'absolute',
          bottom: 10,
          left: 75,
          fontSize: '13px',
          fontWeight: '500',
          color: variables.composerMarketingPurple,
        },
      },
    },
    '& .last': {
      margin: '0 0px 0 0',
    },
  },

  '@keyframes pip': {
    '0%': {
      transform: 'scale(1.0)',
      transformOrigin: '6% 45%',
    },
    '10%': {
      transform: 'scale(0.8 0.66)',
    },
    '25%': {
      transform: 'scale(0.7 0.6)',
    },
    '50%': {
      transform: 'scale(0.65 0.45)',
    },
    '75%': {
      transform: 'scale(0.54 0.4)',
    },
    '100%': {
      transform: 'scale(0.36)',
      transformOrigin: '6% 45%',
      borderWidth: '5px',
      borderRadius: '28px',
      fontSize: '32px',
    },
  },

  '@keyframes vertical': {
    '0%': {
      transform: 'scale(1.0)',
    },
    '100%': {
      transform: 'scale(0.7)',
      borderRadius: '0px 10px 0px 0px',
      fontSize: '22px',
    },
  },

  '@keyframes reveal': {
    '0%': {
      transform: 'scale(1.0)',
    },
    '100%': {
      transform: 'scaleX(0.8) scaleY(0.7)',
      borderRadius: '0px 10px 0px 10px',
      fontSize: '22px',
    },
  },

  '@keyframes overlay': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));

const Formats = () => {
  const classes = useStyles();

  const pipTarget = useRef(null);
  const verticalTarget = useRef(null);
  const revealTarget = useRef(null);
  const overlayTarget = useRef(null);

  const getFormat = (entry) => {
    let format = null;
    if (entry.target.classList.contains('reveal')) {
      format = 'reveal';
    } else if (entry.target.classList.contains('pip')) {
      format = 'pip';
    } else if (entry.target.classList.contains('overlay')) {
      format = 'overlay';
    } else if (entry.target.classList.contains('vertical')) {
      format = 'vertical';
    }

    return format;
  };

  const handleIntersection = (entries) => {
    entries.map((entry) => {
      const format = getFormat(entry);

      if (entry.isIntersecting) {
        entry.target.classList.add(`${format}-in-viewport`);
      }

      return null;
    });
  };

  const handleOutOfView = (entries) => {
    entries.map((entry) => {
      const format = getFormat(entry);

      if (!entry.isIntersecting) {
        entry.target.classList.remove(`${format}-in-viewport`);
      }
      return null;
    });
  };

  useEffect(() => {
    const currentPipTarget = pipTarget.current;
    const currentVerticalTarget = verticalTarget.current;
    const currentRevealTarget = revealTarget.current;
    const currentOverlayTarget = overlayTarget.current;

    const observer = new IntersectionObserver(handleIntersection, { threshold: 1 });
    observer.observe(currentPipTarget);
    observer.observe(currentVerticalTarget);
    observer.observe(currentRevealTarget);
    observer.observe(currentOverlayTarget);

    const outOfViewObserver = new IntersectionObserver(handleOutOfView, { threshold: 0.5 });
    outOfViewObserver.observe(currentPipTarget);
    outOfViewObserver.observe(currentVerticalTarget);
    outOfViewObserver.observe(currentRevealTarget);
    outOfViewObserver.observe(currentOverlayTarget);

    return () => {
      observer.unobserve(currentPipTarget);
      observer.unobserve(currentVerticalTarget);
      observer.unobserve(currentRevealTarget);
      observer.unobserve(currentOverlayTarget);

      outOfViewObserver.unobserve(currentPipTarget);
      outOfViewObserver.unobserve(currentVerticalTarget);
      outOfViewObserver.unobserve(currentRevealTarget);
      outOfViewObserver.unobserve(currentOverlayTarget);
    };
  }, []);

  return (
    <Box className={homeStyles.formatsContainer}>
      <Box className={classes.formats}>
        <Box className={classNames('format', 'pip', styles.mobileBorderRadius)} ref={pipTarget}>
          <Box>
            <h3 className={classNames(styles.heading, classes.formatHeader)}>Picture-in-Picture</h3>
            <Box className={classNames(styles.description, homeStyles.cardsDesc)}>
              Scale down content side-by-side with a video ad, complemented by branded background graphics.
            </Box>
          </Box>
          <Box className="screen">
            <Box className="creative">Creative</Box>
            <Box className="content">Content</Box>
            <Box className="desc">Branded background</Box>
          </Box>
        </Box>
        <Box className={classNames('format', 'vertical', styles.mobileBorderRadius)} ref={verticalTarget}>
          <Box>
            <h3 className={classNames(styles.heading, classes.formatHeader)}>Vertical Video</h3>
            <Box className={classNames(styles.description, homeStyles.cardsDesc)}>
              Repurpose an ad produced for social media channels to partner alongside content.
            </Box>
          </Box>
          <Box className="screen">
            <Box className="creative">Creative</Box>
            <Box className="content">Content</Box>
            <Box className="desc">Branded background</Box>
          </Box>
        </Box>
        <Box className={classNames('format', 'reveal', styles.mobileBorderRadius)} ref={revealTarget}>
          <Box>
            <h3 className={classNames(styles.heading, classes.formatHeader)}>Squeeze Back</h3>
            <Box className={classNames(styles.description, homeStyles.cardsDesc)}>
              Introduce a personalized L-Bar canvas that can be customized and animated.
            </Box>
          </Box>
          <Box className="screen">
            <Box className="content">Content</Box>
            <Box className="desc">Branded background</Box>
          </Box>
        </Box>
        <Box className={classNames('format', 'overlay', 'last', styles.mobileBorderRadius)} ref={overlayTarget}>
          <Box>
            <h3 className={classNames(styles.heading, classes.formatHeader)}>Overlay</h3>
            <Box className={classNames(styles.description, homeStyles.cardsDesc)}>
              Feature a branded graphic directly over the primary content stream.
            </Box>
          </Box>
          <Box className="screen">
            <Box className="creative">Creative</Box>
            <Box className="content">Content</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Formats;
