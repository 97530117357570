@import './colors.css';

.alert {
  color: white;
  border-radius: 12px;
  padding-left: 16px;
  padding-right: 20px;
}

.alert svg {
  color: white;
}

.success {
  background-color: var(--purple);
}

.error {
  background-color: var(--red);
}
