/* eslint-disable max-len */
import React from 'react';

const RemoveIcon = React.memo(() => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3.04547H15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.43171 0.5H6.56807C6.31491 0.5 6.07212 0.600568 5.89311 0.77958C5.71409 0.958592 5.61353 1.20138 5.61353 1.45455V3.04545H10.3863V1.45455C10.3863 1.20138 10.2857 0.958592 10.1067 0.77958C9.92766 0.600568 9.68487 0.5 9.43171 0.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.56812 11.1591V6.38635" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.43188 11.1591V6.38635" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.3655 13.6218C12.3478 13.861 12.2401 14.0845 12.0642 14.2475C11.8882 14.4104 11.6571 14.5007 11.4173 14.5H4.58273C4.34292 14.5007 4.11178 14.4104 3.93584 14.2475C3.75991 14.0845 3.65225 13.861 3.63455 13.6218L2.75 3.04547H13.25L12.3655 13.6218Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default RemoveIcon;
