import React from 'react';
import { useOutletContext } from 'react-router-dom';
import classNames from 'classnames';

import demoStyles from '../extension-demo.module.css';
import { adFormats } from '../../../../utils/content-stream-monitoring-utils';

const Pip = () => {
  /** @type Object*/
  const [encodeArgs, setEncodeArgs] = useOutletContext();
  const uploadedContent = encodeArgs.contentUrl;
  const uploadedAd = encodeArgs.adUrl;
  const uploadedBgSkin = encodeArgs.skin;

  React.useEffect(() => {
    const { adFormat } = encodeArgs;
    if (adFormat !== adFormats.pip) setEncodeArgs({ ...encodeArgs, adFormat: adFormats.pip, overlay: null });
  }, []);

  return (
    <React.Fragment>
      <div
        className={classNames(demoStyles.adFormatLayoutContainer, demoStyles.bgBlack)}
        style={{ backgroundImage: uploadedBgSkin ? `url(${uploadedBgSkin})` : '' }}
      >
        <div className={demoStyles.pipAssetsContainer}>
          {uploadedContent ? (
            <video className={demoStyles.pipContent} autoPlay muted loop src={uploadedContent} />
          ) : (
            <div className={demoStyles.pipContentPlaceholder} />
          )}
          {uploadedAd ? (
            <video className={demoStyles.pipAd} autoPlay muted loop src={uploadedAd} />
          ) : (
            <div className={demoStyles.pipAdPlaceholder} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

Pip.defaultProps = {};
Pip.propTypes = {};

export default Pip;
