/* eslint-disable max-len */
import React from 'react';

const ReportingIcon = React.memo(() => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.76667 1H25.7333C25.7333 1 27.5 1 27.5 2.76667V25.7333C27.5 25.7333 27.5 27.5 25.7333 27.5H2.76667C2.76667 27.5 1 27.5 1 25.7333V2.76667C1 2.76667 1 1 2.76667 1Z"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5332 15.1334H8.06654L10.7283 8.47896C10.7953 8.31307 10.9112 8.17147 11.0605 8.07296C11.2099 7.97444 11.3857 7.92367 11.5645 7.92737C11.7434 7.93108 11.9169 7.98909 12.0621 8.0937C12.2072 8.19832 12.3171 8.34459 12.3772 8.51312L16.2544 19.4959C16.3108 19.6554 16.4117 19.7953 16.5452 19.8991C16.6787 20.0029 16.8392 20.0662 17.0077 20.0815C17.1761 20.0967 17.3454 20.0633 17.4954 19.9852C17.6454 19.9071 17.7698 19.7875 17.8539 19.6408L20.4332 15.1334H23.9665"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default ReportingIcon;
