.extensionDemoScreen {
  padding-top: 62px;
  display: flex;
  justify-content: center;
}

.extensionDemoLandingContainer {
  row-gap: 50px;
  width: 100%;
  max-width: 1440px;
  padding: 0px 60px;
}

.extensionDemoStepsContainer {
  border-radius: 24px;
  margin-top: 25px;
  padding: 28px 0px;
  width: 100%;
}

.extensionDemoHeader {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.extensionDemoTitle {
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  letter-spacing: -0.08rem;
}

.extensionDemoDescription {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-family: 'Inter', sans-serif;
  max-width: 442px;
  color: var(--siteDarkGrey);
}

.stepperContainer {
  width: 690px;
  height: calc(63px - 20px);
  border-radius: 100px;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 11px;
}

.stepperStepContainer {
  display: flex;
  align-items: center;
  gap: 11px;
}

.divider {
  height: 44px;
  border-color: rgba(35, 40, 51, 0.6);
}

.stepperStep {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(35, 40, 51, 0.6);
}

.activeStepperStep {
  color: rgba(35, 40, 51, 1);
}

.stepDivider {
  width: 77px;
  border-top: 1.5px dashed rgba(0, 0, 0, 0.26);
}

.activeStepDivider {
  border-color: #5a24fb;
}

.stepName {
  /* color: #5a24fb; */
  color: rgba(35, 40, 51, 0.6);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
}

.activeStepName {
  color: #5a24fb;
}

.activeStepperNavLink {
  background-color: var(--siteWhite);
}

.completedStepperNavLink {
  color: var(--purpleDark);
  column-gap: 6px;
}

.videoContent {
  width: 810px;
  height: 456px;
  background-color: #1a1a2a;
  border-radius: 24px;
  box-shadow: 0px 73px 143px 0px rgba(116, 81, 211, 0.21), 0px 36.536px 71.57px 0px rgba(116, 81, 211, 0.16),
    0px 22.007px 43.11px 0px rgba(116, 81, 211, 0.14), 0px 14.103px 27.627px 0px rgba(116, 81, 211, 0.12),
    0px 9.141px 17.906px 0px rgba(116, 81, 211, 0.1), 0px 5.754px 11.272px 0px rgba(116, 81, 211, 0.09),
    0px 3.306px 6.476px 0px rgba(116, 81, 211, 0.07), 0px 1.455px 2.85px 0px rgba(116, 81, 211, 0.05);
}

.uploadVideoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.uploadVideoContentTitle {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.sampleContentButton {
  color: var(--sitePurpleDark);
  border: 1px solid var(--sitePurpleDark);
}

.sampleContentButton:hover {
  background-color: var(--sitePurpleDark);
  color: var(--siteWhite);
}

.sampleContentButton:disabled {
  border-color: rgba(0, 0, 0, 0.26);
}

.demoFooterButtons {
  @media (max-width: 1250px) {
    padding: 7px 12px;
  }
}

.chooseFormatContainer {
  display: flex;
  gap: 42px;
  height: 452px;
  width: calc(100% - 120px);
  justify-content: center;
  @media (max-width: 1400px) {
    gap: 12px;
  }
  @media (max-width: 1250px) {
    height: 395px;
  }
  @media (max-width: 1150px) {
    height: 339px;
  }
}

.adFormatContainer {
  display: flex;
  flex-direction: column;
  background: #eee;
  border-radius: 16px;
  padding: 28px 20px;
  row-gap: 26px;
  min-width: 354px;
  @media (max-width: 1400px) {
    min-width: 285px;
  }
}

.adFormatTitle {
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.adFormatTitleDivider {
  width: 100%;
  height: 1px;
  background-color: #c6c6c6;
  margin-top: 9px;
}

.adFormatTitleContainer {
  padding: 0px 10px;
}

.adFormatList {
  display: flex;
  flex-direction: column;
  height: calc(100% - 82px);
  row-gap: 8px;
}

.adFormatListItem {
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 17px 24px;
  column-gap: 13px;
  color: var(--siteBlack);
}

.activeAdFormatListItem {
  background: var(--siteWhite);
}

.adFormatListItemLabel {
  color: var(--siteDarkGrey);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.adFormatLayoutContainer {
  display: flex;
  justify-content: center;
  min-width: 804px;
  height: 452px;
  flex-shrink: 0;
  border-radius: 24px;
  box-shadow: 0px 73px 143px 0px rgba(116, 81, 211, 0.21), 0px 36.536px 71.57px 0px rgba(116, 81, 211, 0.16),
    0px 22.007px 43.11px 0px rgba(116, 81, 211, 0.14), 0px 14.103px 27.627px 0px rgba(116, 81, 211, 0.12),
    0px 9.141px 17.906px 0px rgba(116, 81, 211, 0.1), 0px 5.754px 11.272px 0px rgba(116, 81, 211, 0.09),
    0px 3.306px 6.476px 0px rgba(116, 81, 211, 0.07), 0px 1.455px 2.85px 0px rgba(116, 81, 211, 0.05);
  @media (max-width: 1250px) {
    min-width: unset;
    width: 703px;
    height: 395px;
  }
  @media (max-width: 1150px) {
    min-width: unset;
    height: 339px;
    width: 603px;
  }
}

.bgBlack {
  background: #1a1a2a;
}

.placeholderContainer {
  background: #535360;
}

.pipContent {
  width: 34%;
  height: 34%;
}

.pipContentPlaceholder {
  opacity: 0.25;
  background: #fff;
  width: 34%;
  height: 34%;
}

.pipAd {
  width: 54%;
  height: 54%;
}

.pipAdPlaceholder {
  opacity: 0.25;
  background: #fff;
  width: 54%;
  height: 54%;
}

.pipAssetsContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  gap: 21px;
}

.pipButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 19px;
}

.lbarAssetsContainer {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: end;
}

.lbarContentPlaceholder {
  background: #1a1a2a;
  width: 80%;
  height: 80%;
}

.lbarContent {
  width: 80%;
  height: 80%;
  border-radius: 0 24px 0 0;
}

.overlayAssetsContainer {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: end;
  border-radius: 16px;
  position: relative;
}

.overlayContentPlaceholder {
  width: 100%;
  background: #535360;
  height: 25%;
  border-radius: 0px 0px 16px 16px;
  opacity: 0.5;
}

.overlayContent {
  height: 20%;
  width: 20%;
  margin-left: 15px;
  margin-bottom: 15px;
}

.overlayVideo {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.borderFrameAssetsContainer {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.borderFrameContentPlaceholder {
  border-radius: 16px;
  background: #535360;
  width: 72%;
  height: 72%;
}

.footerContainer {
  width: calc(100% - 120px);
  justify-content: space-between;
  align-items: end;
}

.pipFooterButtons {
  gap: 16px;
  @media (max-width: 1150px) {
    flex-direction: column;
    gap: 8px;
  }
}

.footerNavButton {
  color: #000;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
  height: fit-content;
  gap: 6px;
}

.footerNavButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.disabledFooterNavButton {
  color: rgba(0, 0, 0, 0.26);
  pointer-events: none;
}

.disabledFooterNavButton:hover {
  background-color: none;
}

.absolute {
  position: absolute;
}

/* select insertion */
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent;
  margin: 0px;
  padding: 0px;
  height: 23px;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 23px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 6px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-thumb {
  cursor: pointer;
  height: 42px;
  width: 16px;
  background: black;
  position: relative;
  z-index: 2;
  background: url('../../../assets/icons/slider-thumb.svg');
  margin-top: -19px;
  background-repeat: no-repeat;
}

.insertionPointSliderContainer {
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.availableInsertionSpace {
  height: 23px;
  background-color: #5a24fb;
  border-radius: 6px;
  opacity: 0.5;
  position: absolute;
  z-index: 3;
  pointer-events: none;
}

/* share */
.shareFormContainer {
  background-color: var(--siteWhite);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 456px;
  width: 810px;
  gap: 30px;
}

.shareFormEncodeStatus {
  color: #5a24fb;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
}

.shareFormFailedEncodeStatus {
  color: #c63838;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
}

.shareFormCompletedEncodeStatus {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  color: #3cb094;
}

.shareFormEncodeStatusIcon {
  height: 26px;
  width: 24px;
}

.shareFormTitle {
  color: #232833;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.shareFormSubtitle {
  color: #232833;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}

.shareFormInputContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 436px;
  max-width: 43px6;
}

.shareFormInput {
  border-radius: 100px;
  border: 1px solid #e2e2e2;
  background: #fff;
  width: calc(100% - 44px);
  padding: 16px 22px;
  max-height: 51px;
  color: #232833;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.shareFormActionButton {
  padding: 16px 22px;
  max-height: 51px;
}

.shareFormActionButton:disabled {
  background-color: rgba(0, 0, 0, 0.26);
}

.inProgressEncodeStatus {
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.unsupportedDeviceScreen {
  height: 100%;
  max-width: 246px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.unsupportedDeviceTextContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.unsupportedDeviceText {
  color: #232833;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}
