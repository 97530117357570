/* eslint-disable max-len */
import React from 'react';

const ScalingIcon = React.memo(() => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.2373 2.84506L3.56458 2.84506C6.42458 2.85988 9.25363 3.43805 11.8901 4.54653C14.5266 5.65501 16.9189 7.27209 18.9302 9.30538C20.9416 11.3387 22.5326 13.7483 23.6124 16.3967C24.6923 19.045 25.2397 21.8802 25.2235 24.7402L25.2235 27.76"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.999962 26.5237L13.2305 14.5368"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.486 14.5366L13.2305 14.5366L13.2305 22.126"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.3482 1L14.1527 1" stroke="#3CB094" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.8431 1L20.6108 1" stroke="#3CB094" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M27.0687 7.21661L27.0687 10.2253"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.0687 13.9156L27.0687 15.7201"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.301 1L27.0687 1L27.0687 3.76764"
      stroke="#3CB094"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default ScalingIcon;
