import React from 'react';
import classNames from 'classnames';
import { Button, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  AcceptedFileTypes,
  AD_BUTTON_ID,
  AD_SAMPLE_BUTTON_ID,
  BACKGROUND_SKIN_BUTTON_ID,
  BACKGROUND_SKIN_SAMPLE_BUTTON_ID,
  DELETE_AD_BUTTON_ID,
  DELETE_BACKGROUND_SKIN_BUTTON_ID,
  encodeArgsType,
  SAMPLE_AD_URL,
  SAMPLE_BACKGROUND_SKIN,
  setEncodeArgsType,
} from '../constants';
import DemoFooter from '../demo-footer';
import FileUploadButton from '../file-upload-button';
import { ComposerToastContext, toastStatus } from '../../../composer/composer-toast-context';
import useInputFileHandler from '../hooks/useInputFileHandler';

import styles from '../../site.module.css';
import demoStyles from '../extension-demo.module.css';
import RemoveIcon from '../svg/remove-icon';

const PipFooter = ({ encodeArgs, setEncodeArgs }) => {
  const { setToast } = React.useContext(ComposerToastContext);
  const { inputFileHandler, uploading } = useInputFileHandler({
    encodeArgs,
    setEncodeArgs,
  });
  const uploadedBgSkin = encodeArgs?.skin;
  const uploadedAdContent = encodeArgs?.adUrl;

  const handleOnDelete = (e) => {
    const currentTargetId = e.currentTarget.id;
    switch (currentTargetId) {
      case DELETE_BACKGROUND_SKIN_BUTTON_ID:
        setEncodeArgs({
          ...encodeArgs,
          skin: null,
        });
        setToast({ status: toastStatus.success, message: 'Successfully removed background!' });
        break;

      case DELETE_AD_BUTTON_ID:
        setEncodeArgs({
          ...encodeArgs,
          adUrl: null,
          adDuration: null,
        });
        setToast({ status: toastStatus.success, message: 'Successfully removed ad!' });
        break;

      default:
        break;
    }
  };

  const handleOnSampleButtonClick = (e) => {
    const currentTargetId = e.currentTarget.id;
    switch (currentTargetId) {
      case AD_SAMPLE_BUTTON_ID:
        setEncodeArgs({
          ...encodeArgs,
          adUrl: SAMPLE_AD_URL,
          adDuration: 15,
        });
        setToast({ status: toastStatus.success, message: 'Successfully set sample ad!' });
        break;

      case BACKGROUND_SKIN_SAMPLE_BUTTON_ID:
        setEncodeArgs({
          ...encodeArgs,
          skin: SAMPLE_BACKGROUND_SKIN,
        });
        setToast({ status: toastStatus.success, message: 'Successfully set sample background!' });
        break;

      default:
        break;
    }
  };

  return (
    <DemoFooter
      childrenContainerStyles={{
        display: 'flex',
        gap: 19,
      }}
      backNavDisabled={false}
      backNavPath="../upload-content"
      nextNavVisible
      nextNavDisabled={uploading || !uploadedAdContent}
      nextNavPath="../select-insertion"
    >
      <div className={demoStyles.pipButtonContainer}>
        <div className={classNames(styles.flex, demoStyles.pipFooterButtons)}>
          {uploadedBgSkin ? (
            <LoadingButton
              className={classNames(styles.button, styles.buttonPurple, demoStyles.demoFooterButtons)}
              id={DELETE_BACKGROUND_SKIN_BUTTON_ID}
              onClick={handleOnDelete}
              startIcon={<RemoveIcon />}
            >
              Remove background
            </LoadingButton>
          ) : (
            <FileUploadButton
              acceptedTypes={Object.keys(AcceptedFileTypes[BACKGROUND_SKIN_BUTTON_ID]).join(',')}
              buttonId={BACKGROUND_SKIN_BUTTON_ID}
              isUploadingFile={uploading}
              handleOnChange={inputFileHandler}
              uploadedFile={!!uploadedBgSkin}
              label="Upload background"
            />
          )}
          <Button
            className={classNames(styles.button, demoStyles.sampleContentButton, demoStyles.demoFooterButtons)}
            disabled={uploading || !!uploadedBgSkin}
            id={BACKGROUND_SKIN_SAMPLE_BUTTON_ID}
            onClick={handleOnSampleButtonClick}
          >
            Use sample background
          </Button>
        </div>
      </div>
      <Divider className={demoStyles.divider} orientation="vertical" />
      <div className={demoStyles.pipButtonContainer}>
        <div className={classNames(styles.flex, demoStyles.pipFooterButtons)}>
          {uploadedAdContent ? (
            <LoadingButton
              className={classNames(styles.button, styles.buttonPurple)}
              id={DELETE_AD_BUTTON_ID}
              onClick={handleOnDelete}
              startIcon={<RemoveIcon />}
            >
              Remove ad
            </LoadingButton>
          ) : (
            <FileUploadButton
              acceptedTypes={Object.keys(AcceptedFileTypes[AD_BUTTON_ID]).join(',')}
              buttonId={AD_BUTTON_ID}
              isUploadingFile={uploading}
              handleOnChange={inputFileHandler}
              uploadedFile={!!uploadedAdContent}
              label="Upload ad"
            />
          )}
          <Button
            className={classNames(styles.button, demoStyles.sampleContentButton)}
            disabled={uploading || !!uploadedAdContent}
            id={AD_SAMPLE_BUTTON_ID}
            onClick={handleOnSampleButtonClick}
          >
            Use sample ad
          </Button>
        </div>
      </div>
    </DemoFooter>
  );
};

PipFooter.defaultProps = {};
PipFooter.propTypes = {
  encodeArgs: encodeArgsType.isRequired,
  setEncodeArgs: setEncodeArgsType.isRequired,
};

export default PipFooter;
