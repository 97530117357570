/* eslint-disable max-len */
import React from 'react';

const YesLogo = React.memo(() => (
  <svg width="93" height="46" viewBox="0 0 93 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2196_830)">
      <path
        d="M52.1589 16.9274L53.0665 20.5489H57.2088V12.7076H28.4722V16.9274H30.0895L27.3104 21.804L24.5312 16.9274H26.1758V12.7076H16.2808V16.9274H17.9561L24.2804 25.6243V29.0743H22.1189V33.2907H32.6162V29.0743H30.3983V25.7941L36.2688 16.9274H39.843V29.0743H37.8913V33.2907H57.2088V25.1391H53.0665L52.1589 29.0743H45.9762V25.3105H50.3471V20.6929H45.9762V16.9206L52.1589 16.9274Z"
        fill="white"
      />
      <path
        d="M70.9715 18.4964C70.4789 17.7997 69.8263 17.2327 69.0693 16.8434C68.3049 16.4405 67.3973 16.2433 66.2901 16.2433C65.1539 16.2433 63.7925 16.6136 63.7925 17.7265C63.7925 19.5235 67.0339 19.4669 70.2618 20.127C73.4896 20.7872 76.7294 22.1504 76.7294 26.8246C76.7294 31.3874 73.3804 33.7537 68.9839 33.7537C67.0271 33.7537 64.9287 33.2701 63.54 31.6446V33.2959H58.4559V26.8195H63.6764C64.0476 27.6107 64.6506 28.2689 65.4047 28.7056C66.1723 29.1174 67.0324 29.3241 67.9023 29.3058C69.2347 29.3058 70.8026 29.0211 70.8026 27.6219C70.8026 25.7975 67.5406 25.7975 64.2787 25.1425C61.0167 24.4875 57.7548 23.2049 57.7548 18.7861C57.7548 15.4476 60.5612 12.2549 65.6111 12.2549C67.7931 12.2549 69.3542 12.7401 71.0005 14.2799V12.7076H76.1067V18.4964L70.9715 18.4964Z"
        fill="white"
      />
      <path
        d="M85.1812 3.42938e-06H7.81881C3.5006 3.42938e-06 0 3.51834 0 7.85843V38.1433C0 42.4824 3.49984 46 7.81711 46H85.1812C89.4985 46.0009 92.9991 42.4841 93 38.145V7.85843C93 3.51834 89.4994 3.42938e-06 85.1812 3.42938e-06ZM82.0062 13.0574V32.9478C82.0053 36.7446 78.9426 39.822 75.165 39.822H17.8418C14.0645 39.8229 11.0015 36.746 11.0006 32.9495V13.0574C11.0006 9.25991 14.0635 6.18146 17.8418 6.18146H75.165C78.9433 6.18146 82.0062 9.25991 82.0062 13.0574Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2196_830">
        <rect width="93" height="46" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

export default YesLogo;
