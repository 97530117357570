import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import homeStyles from '../home/home.module.css';
import scStyles from './stream-composer.module.css';
import styles from '../site.module.css';

const MoreInfoCard = ({ icon, heading, description }) => {
  return (
    <div className={classNames(scStyles.moreInfoCard, styles.mobileBorderRadius)}>
      <div className={scStyles.moreInfoIcon}>{icon}</div>
      <h3 className={styles.heading}>{heading}</h3>
      <div className={classNames(styles.description, homeStyles.cardsDesc)}>{description}</div>
    </div>
  );
};

MoreInfoCard.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
export default MoreInfoCard;
