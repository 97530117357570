.headerOffset {
  padding-top: 62px;
}

.home .homeHeader {
  padding-top: 152px;
}

.homeVideo {
  position: relative;
  margin-top: 66px;
  border-radius: 24px;
  z-index: 1;
  box-shadow: 0px 73px 143px 0px rgba(116, 81, 211, 0.21), 0px 36.536px 71.57px 0px rgba(116, 81, 211, 0.16),
    0px 22.007px 43.11px 0px rgba(116, 81, 211, 0.14), 0px 14.103px 27.627px 0px rgba(116, 81, 211, 0.12),
    0px 9.141px 17.906px 0px rgba(116, 81, 211, 0.1), 0px 5.754px 11.272px 0px rgba(116, 81, 211, 0.09),
    0px 3.306px 6.476px 0px rgba(116, 81, 211, 0.07), 0px 1.455px 2.85px 0px rgba(116, 81, 211, 0.05);
}

.backgroundLogo {
  position: absolute;
  opacity: 20%;
}

.backgroundLogoLeft {
  top: 0px;
  left: 0px;
  width: 30vw;
  height: auto;
}

.backgroundLogoRight {
  top: 30vh;
  right: 0px;
  width: 40vw;
  height: auto;
}

.homeHeader .homeHeading {
  color: var(--siteBlack);
}

.homeHeader .homeHeading span {
  display: inline;
  color: var(--sitePurpleDark);
}

.partnerShowcase {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 30px;
}

.partnerShowcaseRow {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  gap: 15px;
}

.partnerShowcaseIcon {
  width: 125px;
  height: 125px;
  border-radius: 33px;
  background-color: var(--siteWhite);
  box-shadow: 2px 21px 20px -14px rgba(0, 0, 0, 0.43);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}

.partnerShowcaseIconSpacing {
  max-width: 175px;
  max-height: 175px;
  flex-shrink: 0;
}

.nielsenMetrics {
  display: grid;
  grid-template: repeat(4, 1fr) / 1fr 1fr 1fr;
  gap: 20px;
  position: relative;
}

.nielsenMetricsDesc {
  margin-top: 25px;
  color: var(--Text, #232833);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}

.nielsenMetric {
  display: flex;
  gap: 10px;
  grid-row: span 2;
  align-items: flex-start;
  align-self: center;
}

.nielsenMetricHeader {
  grid-row: span 4;
  gap: 24px;
}

.nielsenMetric h3 {
  font-size: 18px;
  font-weight: 600;
  font-family: SelectaMedium;
}

.nielsenMetricPercentage {
  height: 70px;
  width: 70px;
  background-color: var(--sitePurpleDark);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--siteWhite);
  flex-shrink: 0;
}

.nielsenMetricPercentage span {
  color: var(--White, #fff);
  text-align: center;
  font-family: Selecta;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 233.333% */
  letter-spacing: -0.48px;
}

.nielsenMetric p {
  margin-top: 5px;
}

.composerCard {
  background-color: var(--siteGreenLighter);
}

.composerCardHeader {
  display: flex;
  gap: 67px;
}

.composerCardHeader span {
  color: var(--siteGreen);
}

.composerCardHeader svg {
  width: 70px;
}

.composerCardHeaderVideo {
  overflow: hidden;
  border-radius: 16px;
  align-self: center;
  background: black;
}

.composerMiniCards {
  margin-top: 112px;
  display: grid;
  gap: 34px;
  grid-template-rows: 1fr auto;
  grid-template-columns: repeat(3, 1fr);
}

.composerCard span {
  color: var(--siteGreen);
}

.viewingExperienceCard {
  max-height: 466px;
  overflow: hidden;
  grid-column: 1 / span 2;
}

.viewingExperienceImgContainer {
  display: flex;
  justify-content: end;
}

.viewingExperienceImg {
  height: auto;
  width: 722px;
  background: lightgray;
  border-radius: 16px;
}

.brandExperienceCard {
  display: flex;
  flex-direction: column;
  max-height: 466px;
  overflow: hidden;
}

.brandExperienceCardTitle {
  max-width: 164px;
}

.brandExperienceImgContainer {
  display: flex;
  position: relative;
}

.brandExperienceImg {
  border-radius: 16px;
  height: auto;
  width: 417px;
  background: lightgray;
  position: absolute;
  left: 40px;
  filter: drop-shadow(0px 1.404px 1.123px rgba(0, 0, 0, 0.02)) drop-shadow(0px 3.375px 2.7px rgba(0, 0, 0, 0.03))
    drop-shadow(0px 6.355px 5.084px rgba(0, 0, 0, 0.04)) drop-shadow(0px 11.335px 9.068px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 21.202px 16.961px rgba(0, 0, 0, 0.05)) drop-shadow(0px 50.749px 40.599px rgba(0, 0, 0, 0.07));
}

.spotlightCard {
  display: flex;
  width: 100%;
  max-height: 405px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  grid-column: 1 / span 3;
  grid-row: 2;
  padding-top: 39px;
}

.spotlightImg {
  border-radius: 16px;
  width: 768px;
  height: auto;
}

.spotlightScreenshot {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 16px;
  position: absolute;
  top: 10%;
  left: -10%;
  box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.05),
    0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.04), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.04),
    0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03), 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02);
}

.spotlightCardTitle {
  max-width: 243px;
}

.formatsCard {
  background-color: var(--siteGreyLight);
}

.formatsCardDesc {
  text-align: center;
  margin: 20px auto 0px auto;
}

.extensionCard {
  background-color: var(--extensionBlue);
}

.extensionCard span {
  color: var(--siteBlue);
}

.extensionCard svg {
  width: 70px;
}

.extensionCardHeader {
  display: flex;
  gap: 130px;
}

.extensionCardHeaderVideo {
  max-width: 60%;
  border-radius: 24px;
}

.extensionCardHeaderDesc {
  max-width: 30%;
}

.extensionMiniCards {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 32px;
  margin-top: 112px;
}

.extensionMiniCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.extensionMiniCardImg {
  margin: 0px 40px 40px 40px;
  background: #fff;
}

.extensionMiniCardImg img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.creativeStudioCard {
  position: relative;
  overflow: hidden;
  background-color: var(--siteBlack);
}

.creativeStudioCard span {
  color: var(--sitePurple);
  display: inline;
}

.creativeStudioCard div,
.creativeStudioCard h2 {
  color: var(--siteWhite);
}

div.creativeStudioGrad {
  top: 20%;
}

.homeCardWrapper {
  gap: 69px;
  margin: 0 44px;
}

.adFormatsContainer {
  margin-top: 30px;
}

.formatsContainer {
  display: flex;
  justify-content: center;
}

.csDescription {
  color: var(--White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  max-width: 650px;
}

.csCardContent {
  position: relative;
  z-index: 2;
}

.streamingPartnershipsDesc {
  color: var(--Text, #232833);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  max-width: 354px;
}

.composerCardHeaderText {
  color: var(--Text, #232833);
  font-family: Selecta;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 100% */
  letter-spacing: -1.12px;
  width: 432px;
}

.trustedByTopBrands {
  color: var(--Text, #232833);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.nielsenMetricsContainer {
  padding: 86px 40px;
}

.extensionHeader {
  line-height: 56px;
}

@media (max-width: 1536px) {
  .adFormatsContainer {
    overflow: scroll;
  }

  .adFormatsContainer::-webkit-scrollbar {
    display: none;
  }

  .formatsContainer {
    display: flex;
    justify-content: unset;
  }
}

@media (max-width: 1300px) {
  .viewingExperienceImg {
    width: 640px;
  }

  .brandExperienceImg {
    width: 384px;
  }
}

@media (max-width: 1200px) {
  .viewingExperienceImg {
    width: 512px;
  }

  .brandExperienceImgContainer {
    padding-left: 0;
    justify-content: end;
  }

  .brandExperienceImg {
    width: 256px;
  }

  .partnerShowcase {
    gap: 20px;
  }

  .partnerShowcaseRow {
    gap: 20px;
  }

  .partnerShowcaseIcon {
    width: 91px;
    height: 91px;
  }
}

@media (max-width: 1080px) {
  .extensionCardHeader {
    gap: 65px;
    justify-content: center;
    align-items: center;
  }

  .viewingExperienceImg {
    width: 432px;
  }

  .nielsenMetrics {
    grid-template: 2fr repeat(2, 1fr) / 1fr 1fr;
  }

  .nielsenMetricHeader {
    grid-row: initial;
    grid-column: span 2;
    margin-bottom: 20px;
  }

  .nielsenMetric {
    grid-row: initial;
    align-self: initial;
  }

  .partnerShowcaseIcon {
    border-radius: 23px;
  }

  .composerCardHeader {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 900px) {
  .requestDemoWrapper {
    margin: 60px 44px;
  }

  .miniCardImgContainer {
    padding: 0 20px 0px 20px;
    margin-bottom: 40px;
  }

  .miniCardImg {
    border-radius: 12px;
    width: 512px;
  }

  .viewingExperienceImgContainer {
    justify-content: center;
  }

  .partnerShowcase {
    gap: 20px;
  }

  .partnerShowcaseRow {
    gap: 20px;
  }

  .partnerShowcaseIcon {
    width: 61px;
    height: 61px;
    border-radius: 15px;
  }

  .composerCardHeaderHeadingContainer {
    display: flex;
    justify-content: center;
  }

  .composerMiniCards {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
  }

  .brandExperienceCardTitle {
    max-width: unset;
  }

  .brandExperienceImg {
    position: static;
    filter: unset;
  }

  .brandExperienceImgContainer {
    justify-content: center;
    position: static;
  }

  .spotlightCard {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 0;
    max-height: unset;
  }

  .spotlightHeader {
    width: calc(100% - 80px);
  }

  .spotlightCardTitle {
    max-width: unset;
  }

  .home .homeHeader {
    padding-top: 100px;
  }

  .backgroundLogoLeft {
    width: 40vw;
  }

  .backgroundLogoRight {
    top: 50vh;
  }

  .extensionCardHeader {
    flex-direction: column-reverse;
    gap: 20px;
    justify-content: unset;
    align-items: unset;
  }

  .extensionCardHeaderVideo {
    max-width: 100%;
  }

  .extensionCardHeaderDesc {
    max-width: 100%;
  }

  .extensionMiniCards {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
  }

  .extensionMiniCardImg {
    display: flex;
    border: none;
  }

  .extensionMiniCardImg img {
    width: 100%;
    height: auto;
  }

  .nielsenMetricsContainer {
    padding: 40px;
  }
}

@media (max-width: 700px) {
  .miniCardImg {
    width: 432px;
    height: 243px;
  }
}

@media (max-width: 600px) {
  .mobileMiniCard {
    margin: 0 40px 40px 40px;
  }

  .homeCardWrapper {
    gap: 69px;
    margin: 0;
  }

  .nielsenMetrics {
    display: flex;
    flex-direction: column;
  }

  .nielsenMetricsDesc {
    margin-top: 12px;
  }

  .requestDemoWrapper {
    margin: 60px 24px;
  }

  .composerCard {
    padding: 35px 0;
  }

  .composerCardHeader {
    padding: 0 24px;
  }

  .composerMiniCards {
    padding: 0 24px;
  }

  .formatsContainer {
    padding-left: 24px;
  }

  .composerCardHeaderText {
    line-height: normal;
    letter-spacing: -1.12px;
    width: unset;
  }

  .miniCardImgContainer {
    padding: 0;
  }

  .cardsDesc {
    color: var(--Text, #232833);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .partnerShowcaseIcon {
    border-radius: 12px;
  }
}

@media (max-width: 500px) {
  .miniCardImg {
    width: 100%;
    height: auto;
  }

  .backgroundLogoLeft {
    width: 40vw;
  }

  .backgroundLogoRight {
    top: 674px;
    width: 45vw;
  }

  .csDescription {
    max-width: 252px;
  }

  .headerDescription span {
    color: var(--Text, #232833);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
  }
}
