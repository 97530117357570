import React from 'react';
import { matchPath, unstable_useBlocker, useOutletContext } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import classNames from 'classnames';
import { ContentVideo } from '../upload-content';

import { getHoursMinutesSecondsFromTime } from '../utils';
import DemoFooter from '../demo-footer';
import InsertionPointSlider from './insertion-point-slider';
import { ExtensionDemoStepsMap, getMaxInsertionBoundary, MIN_INSERTION_BOUNDARY } from '../constants';

import styles from '../../site.module.css';
import demoStyles from '../extension-demo.module.css';
import ConfirmationDialog from '../../../confirmation-dialog';
import useRedirectToInitialStep from '../hooks/useRedirectToInitialStep';

const SelectInsertion = () => {
  /** @type Object*/
  const [encodeArgs, setEncodeArgs] = useOutletContext();
  const { adFormat, adDuration, contentUrl, contentDuration, insertionPoint } = encodeArgs;
  const videoRef = React.useRef(null);

  useRedirectToInitialStep({
    step: ExtensionDemoStepsMap.INSERTION_POINT,
    encodeArgs,
  });

  const [error, setError] = React.useState(null);

  const contentMidPoint = Number(contentDuration) / 2;
  const adMidPoint = Number(adDuration) / 2;
  const initialAdInsertionPoint = contentMidPoint - adMidPoint;
  const MAX_INSERTION_BOUNDARY = getMaxInsertionBoundary({ contentDuration, adDuration });

  const { minutes: contentMinutes, seconds: contentSeconds } = getHoursMinutesSecondsFromTime(contentDuration);
  const { minutes: insertionMinutes, seconds: insertionSeconds } = getHoursMinutesSecondsFromTime(insertionPoint);

  const blocker = unstable_useBlocker(({ currentLocation, nextLocation }) => {
    const nextLocationShare = matchPath(
      {
        path: ExtensionDemoStepsMap.COMPLETE,
        end: true,
      },
      nextLocation.pathname
    );
    return !nextLocationShare && currentLocation.pathname !== nextLocation.pathname;
  });

  React.useEffect(() => {
    const videoEl = videoRef?.current;
    const controller = new AbortController();

    if (videoEl) {
      videoEl.currentTime = initialAdInsertionPoint;
      videoEl.pause();

      videoEl.addEventListener(
        'timeupdate',
        (e) => {
          const { currentTime } = e.target;
          if (currentTime <= MIN_INSERTION_BOUNDARY) {
            setEncodeArgs({
              ...encodeArgs,
              insertionPoint: MIN_INSERTION_BOUNDARY,
            });
          } else if (currentTime >= MAX_INSERTION_BOUNDARY) {
            setEncodeArgs({
              ...encodeArgs,
              insertionPoint: MAX_INSERTION_BOUNDARY,
            });
          }
        },
        { signal: controller.signal }
      );
    }

    if (!insertionPoint) {
      setEncodeArgs({
        ...encodeArgs,
        insertionPoint: initialAdInsertionPoint,
      });
    }

    return () => {
      controller.abort();
    };
  }, []);

  const handleOnTimestampFieldChange = (e) => {
    if (error) {
      setError('');
    }

    const value = Number(e.currentTarget.value);

    if (value < MIN_INSERTION_BOUNDARY) {
      const time = getHoursMinutesSecondsFromTime(MIN_INSERTION_BOUNDARY);
      setError(`Insertion point can not be lesser than ${time.hours}:${time.minutes}:${time.seconds}`);
      return;
    }

    if (value > MAX_INSERTION_BOUNDARY) {
      const time = getHoursMinutesSecondsFromTime(MAX_INSERTION_BOUNDARY - adDuration);
      setError(`Insertion point can not be greater than ${time.hours}:${time.minutes}:${time.seconds}`);
      return;
    }

    setEncodeArgs({
      ...encodeArgs,
      insertionPoint: Number(value),
    });

    if (videoRef?.current) {
      videoRef.current.currentTime = value;
      videoRef.current.pause();
    }
  };

  return (
    <React.Fragment>
      <div
        className={classNames(styles.flex, styles.flexCol)}
        style={{
          gap: 37,
        }}
      >
        <ContentVideo controls={false} videoUrl={contentUrl} autoPlay={false} loop={false} videoRef={videoRef} />
      </div>
      <DemoFooter
        backNavDisabled={false}
        backNavPath={`../choose-format/${adFormat}`}
        childrenContainerStyles={{
          display: 'flex',
          flexDirection: 'column',
          gap: 23,
          width: 804,
          justifyContent: 'center',
        }}
        nextNavVisible
        nextNavDisabled={false}
        nextNavPath="../share"
      >
        <div
          className={classNames(styles.flex)}
          style={{
            gap: 13,
          }}
        >
          <PlayArrowIcon />
          <div className={demoStyles.insertionPointSliderContainer}>
            <InsertionPointSlider
              contentDuration={contentDuration}
              insertionPoint={insertionPoint}
              minInsertionBoundary={MIN_INSERTION_BOUNDARY}
              maxInsertionBoundary={MAX_INSERTION_BOUNDARY}
              onChange={handleOnTimestampFieldChange}
            />
          </div>
          <p>
            {contentMinutes}:{contentSeconds}
          </p>
        </div>
        <p>
          Insertion point currently set at{' '}
          <span className={styles.textPurpleDark}>
            {insertionMinutes}:{insertionSeconds}
          </span>
          .
        </p>
      </DemoFooter>
      {blocker.state === 'blocked' ? (
        <ConfirmationDialog
          contentText={`
            Navigating away will erase any changes and uploads made.
            `}
          onConfirmClick={() => {
            setEncodeArgs({
              ...encodeArgs,
              insertionPoint: null,
            });
            blocker.proceed?.();
          }}
          onCancelClick={() => blocker.reset?.()}
        />
      ) : null}
    </React.Fragment>
  );
};

SelectInsertion.defaultProps = {};
SelectInsertion.propTypes = {};

export default SelectInsertion;
