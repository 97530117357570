import Cookies from 'js-cookie';
import axios from 'axios';

export const isAdmin = (account) => account && account._id && account.name === 'Admin';

export const getSelectedAccountId = () => {
  return Cookies.get('selectedAccountId');
};

export const setSelectedAccountId = (accountId) => {
  Cookies.set('selectedAccountId', accountId);
};

export const defaultContext = (account) => {
  const { subAccounts = [] } = account;
  if (subAccounts && subAccounts.length > 0) {
    return 'account';
  }
  return 'reporting';
};

export const deriveNavLinks = (account) => {
  const { subAccounts = [], publisherScope, advertiserScope } = account;
  const urls = [];

  urls.push({ name: 'Reporting', url: `/account/${account._id}/reporting` });

  if (subAccounts && subAccounts.length > 0) urls.push({ name: 'Accounts', url: `/account/${account._id}/account` });

  if (publisherScope) {
    urls.push({
      name: 'Content',
      subLinks: [
        {
          name: 'Streams',
          url: `/account/${account._id}/content-stream`,
          path: '/account/:accountId/content-stream',
        },
        {
          name: 'Origins',
          url: `/account/${account._id}/content-stream/origins`,
          path: '/account/:accountId/content-stream/origins',
        },
        {
          name: 'Supply Routes',
          url: `/account/${account._id}/content-stream/supply-routes`,
          path: '/account/:accountId/content-stream/supply-routes',
        },
      ],
    });
  }

  if (advertiserScope) {
    urls.push({
      name: 'Delivery',
      subLinks: [
        {
          name: 'Advertisers',
          url: `/account/${account._id}/delivery/advertiser`,
          path: '/account/:accountId/delivery/advertiser',
        },
        {
          name: 'Campaigns',
          url: `/account/${account._id}/delivery/campaign`,
          path: '/account/:accountId/delivery/campaign',
        },
        {
          name: 'Line Items',
          url: `/account/${account._id}/delivery/line-item`,
          path: '/account/:accountId/delivery/line-item',
        },
        {
          name: 'Creatives',
          url: `/account/${account._id}/delivery/creative`,
          path: '/account/:accountId/delivery/creative',
        },
      ],
    });
  }

  urls.push({ name: 'Settings', url: `/account/${account._id}/settings` });

  return urls;
};

export const getAccounts = async (search) => {
  const url = `${SERVICE_URL}/api/v1/accounts${search}`;
  return axios.get(url).then((response) => response.data);
};

export const getSubAccounts = async (search) => {
  const url = `${SERVICE_URL}/api/v1/account/accounts${search}`;
  return axios.get(url).then((response) => response.data);
};

export const getSubAccountsForAccount = async (accountId, search) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/accounts${search}`;
  return axios.get(url).then((response) => response.data);
};

export const getAccount = async (accountId) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}`;
  return axios.get(url).then((response) => response.data);
};

export const updateAccount = async (accountId, account) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}`;
  return axios.put(url, account).then((response) => response.data);
};

export const postAccountsByIds = async (accountIds, search = '') => {
  const idQuery = { ids: accountIds || [] };
  const url = `${SERVICE_URL}/api/v1/accounts/by_ids${search}`;

  return axios.post(url, idQuery).then((response) => response.data);
};

export const updateDefaultContentStreamInventory = async (accountId, inventoryType, body) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/updateDefaultInventory/${inventoryType}`;
  return axios.put(url, body).then((response) => response.data);
};
