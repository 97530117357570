import { LOGOUT, RECEIVE_NAVLINKS } from '../action-types';

const initialState = [];

const NavReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NAVLINKS: {
      const { navLinks } = action;
      return [...navLinks];
    }
    case LOGOUT:
      return initialState;
    default:
      return [...state];
  }
};

export default NavReducer;
