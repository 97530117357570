import axios from 'axios';
import { ASSET_TYPES } from '../components/creative-studio/studio-sidebar/assets/assets-helper';
import { getAllPreviewInventoryData } from './preview-encodes-service';

const PREVIEW_INVENTORY = 'previewInventory';

const getAssetString = (assetType) => (assetType === ASSET_TYPES.overlaySkin.key ? ASSET_TYPES.skin.key : assetType);

export const getInBreakAsset = async ({ accountId, advertiserId, assetType, assetId }) => {
  const asset = getAssetString(assetType);
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/advertiser/${advertiserId}/asset/${asset}/${assetId}`;
  return axios.get(url).then((response) => response.data);
};

export const getInStreamAsset = async ({ accountId, assetType, assetId }) => {
  const asset = getAssetString(assetType);
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/asset/${asset}/${assetId}`;
  return axios.get(url).then((response) => response.data);
};

export const postAssetS3SignedUrl = async ({ asset, folder }, assetType) => {
  const url = `${SERVICE_URL}/api/v1/asset/${assetType}/signed-url`;
  return axios.post(url, { asset, folder }).then((response) => response.data);
};

export const postTransitionCardIdsByAccountAdvertiser = async (accountId, advertiserId, assetType, cardIds) => {
  const idQuery = { ids: cardIds || [] };
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/advertiser/${advertiserId}/asset/${assetType}/by_ids`;
  return axios.post(url, idQuery).then((response) => response.data);
};

export const getAssetsByAccountAdvertiserAndType = async ({
  accountId,
  advertiserId,
  assetType,
  search = '',
  demoAccount,
  filters,
}) => {
  if (assetType === PREVIEW_INVENTORY) {
    return getAllPreviewInventoryData({ accountId, search, demoAccount, filters });
  }

  const asset = getAssetString(assetType);

  const url = `${SERVICE_URL}/api/v1/account/${accountId}/advertiser/${advertiserId}/assets/${asset}${search}`;
  return axios.get(url).then((response) => response.data);
};

export const getAssetsByAccountAndType = async ({ accountId, assetType, search = '', demoAccount, filters }) => {
  if (assetType === PREVIEW_INVENTORY) {
    return getAllPreviewInventoryData({ accountId, search, demoAccount, filters });
  }

  const asset = getAssetString(assetType);
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/assets/${asset}${search}`;
  return axios.get(url).then((response) => response.data);
};

export const updateInBreakAsset = async ({ accountId, advertiserId, assetType, asset }) => {
  const assetString = getAssetString(assetType);
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/advertiser/${advertiserId}/asset/${assetString}`;
  return axios.put(url, asset).then((response) => response.data);
};

export const updateInStreamAsset = async ({ accountId, assetType, asset }) => {
  const assetString = getAssetString(assetType);
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/asset/${assetString}`;
  return axios.put(url, asset).then((response) => response.data);
};

export const getAssetAssociations = async (accountId, assetType, assetId) => {
  const assetString = getAssetString(assetType);
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/asset/${assetString}/${assetId}/associations`;
  return axios.get(url).then((response) => response.data);
};

export const deleteAsset = async (accountId, assetType, assetId, contentType) => {
  const asset = getAssetString(assetType);
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/asset/${asset}/${assetId}`;
  return axios.delete(url, { data: { contentType } }).then((response) => response.data);
};
