/* eslint-disable max-len */
import React from 'react';

const ComposerIcon = React.memo(() => (
  <svg viewBox="0 0 73 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.631104" y="0.260742" width="72" height="70" rx="24" fill="#D3E9E3" />
    <path d="M46.4773 27.8761H36.6311" stroke="#3CB094" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M41.5542 35.2607H31.708" stroke="#3CB094" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.6311 42.6454H26.7849" stroke="#3CB094" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M23.0926 19.2607H50.1696C50.1696 19.2607 52.6311 19.2607 52.6311 21.7223V48.7992C52.6311 48.7992 52.6311 51.2607 50.1696 51.2607H23.0926C23.0926 51.2607 20.6311 51.2607 20.6311 48.7992V21.7223C20.6311 21.7223 20.6311 19.2607 23.0926 19.2607Z"
      stroke="#3CB094"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default ComposerIcon;
