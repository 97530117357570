import { sourceTypes } from '../../services/content-stream-service';
import { RESET_CONTENT_STREAM, UPDATE_CONTENT_STREAM } from '../action-types';

const initialState = {
  sourceType: sourceTypes.hlsURL,
  height: 1920,
  width: 1080,
  size: 0,
  duration: 0,
  originalFileName: null,
  name: null,
  originalHlsUrl: null,
  originalMp4Url: null,
  playlistAuthType: null,
  playlistAuthKey: null,
};

const ContentStreamWizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTENT_STREAM:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_CONTENT_STREAM:
      return initialState;

    default:
      return state;
  }
};

export default ContentStreamWizardReducer;
