import React from 'react';
import { useOutletContext } from 'react-router-dom';
import classNames from 'classnames';

import demoStyles from '../extension-demo.module.css';
import { adFormats } from '../../../../utils/content-stream-monitoring-utils';

const Overlay = () => {
  /** @type Object*/
  const [encodeArgs, setEncodeArgs] = useOutletContext();
  const uploadedContent = encodeArgs?.contentUrl;
  const uploadedOverlay = encodeArgs?.overlay;

  React.useEffect(() => {
    const { adFormat } = encodeArgs;
    if (adFormat !== adFormats.overlay) {
      setEncodeArgs({ ...encodeArgs, adFormat: adFormats.overlay, adUrl: null, skin: null });
    }
  }, []);

  return (
    <React.Fragment>
      <div className={classNames(demoStyles.adFormatLayoutContainer, demoStyles.bgBlack)}>
        {uploadedContent ? (
          <div className={demoStyles.overlayAssetsContainer}>
            <video className={demoStyles.overlayVideo} autoPlay src={uploadedContent} muted loop />
            {uploadedOverlay ? (
              <img
                className={classNames(demoStyles.overlayContent, demoStyles.absolute)}
                src={uploadedOverlay}
                alt="overlay"
              />
            ) : (
              <div className={classNames(demoStyles.overlayContentPlaceholder, demoStyles.absolute)} />
            )}
          </div>
        ) : (
          <div className={demoStyles.overlayAssetsContainer}>
            {uploadedOverlay ? (
              <img className={demoStyles.overlayContent} src={uploadedOverlay} alt="overlay" />
            ) : (
              <div className={demoStyles.overlayContentPlaceholder} />
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Overlay.defaultProps = {};
Overlay.propTypes = {};

export default Overlay;
