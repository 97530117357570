import {
  startOfDay,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfDay,
  endOfWeek,
  endOfMonth,
  parseISO,
  isValid,
  subDays,
  isEqual,
  subMonths,
  startOfMonth,
  isDate,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc, format } from 'date-fns-tz';

const userTZ = 'UTC';

export const easternTZ = 'America/New_York';

export const toUserTZ = (date, tz) => {
  const timeZone = tz || userTZ;
  return utcToZonedTime(date, timeZone);
};

export const fromUserTZ = (date, tz) => {
  const timeZone = tz || userTZ;
  return zonedTimeToUtc(date, timeZone);
};

export const getUTCDate = (date = new Date()) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
};

export const fromUTCDate = (date = new Date()) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

export const endOfDayUtcEpoch = (utcEpoch) => {
  const convertedEpoch = getUTCDate(new Date(utcEpoch));
  return fromUTCDate(endOfDay(convertedEpoch)).getTime();
};

export const endOfWeekUtcEpoch = (utcEpoch) => {
  const convertedEpoch = getUTCDate(new Date(utcEpoch));
  return fromUTCDate(endOfWeek(convertedEpoch)).getTime();
};

export const endOfMonthUtcEpoch = (utcEpoch) => {
  const convertedEpoch = getUTCDate(new Date(utcEpoch));
  return fromUTCDate(endOfMonth(convertedEpoch)).getTime();
};

export const formatDateFromString = (dateString) => {
  return format(getUTCDate(new Date(dateString)), 'yyyy-MM-dd');
};

export const formatDateHourFromString = (dateString) => {
  return format(getUTCDate(new Date(dateString)), 'yyyy-MM-dd HH:mm:ss');
};

export const getStartEndTimes = (rangeValue) => {
  if (!rangeValue) return [];
  const [start, end] = rangeValue;
  if (!start || !end) return [];
  return [fromUserTZ(start).toISOString(), fromUserTZ(end).toISOString()];
};

export const userTZFromMongo = (dateTime, tz = userTZ) => {
  const parsed = parseISO(dateTime);
  if (!isValid(parsed)) return null;

  return toUserTZ(parsed, tz);
};

export const defaultRange = [
  startOfDay(toUserTZ(getUTCDate(), easternTZ)),
  endOfDay(toUserTZ(getUTCDate(), easternTZ)),
];

export const defaultEpochRange = [startOfDay(getUTCDate()).getTime() / 1000, endOfDay(getUTCDate()).getTime() / 1000];

export const getOffsetDate = ({ days = 0, hours = 0, minutes = 0, date = new Date() }) => {
  const result = new Date(date);
  result.setHours(date.getHours() + days * 24 + hours, date.getMinutes() + minutes);
  return result;
};

export const previousWeekRange = [startOfDay(subDays(getUTCDate(), 7)), endOfDay(getUTCDate())];

export const todayDateRange = [startOfDay(getUTCDate()), endOfDay(getUTCDate())];

export const currentMonthDateRange = [startOfMonth(getUTCDate()), endOfDay(getUTCDate())];

export const previousMonthDateRange = [
  startOfMonth(subMonths(getUTCDate(), 1)),
  endOfMonth(subMonths(getUTCDate(), 1)),
];

export const mtdInterval = getUTCDate().getDate() < 3 ? '1h' : '1d';

export const startEndRangefromMongo = (startTime, endTime, tz = userTZ) => {
  return [userTZFromMongo(startTime, tz), userTZFromMongo(endTime, tz)];
};

export const getDateRangeString = ({ startTime, endTime }) => {
  return `${formatDateFromString(startTime)}_${formatDateFromString(endTime)}`;
};

export const rangeDaysBack = ({ daysBack, endDate = getUTCDate() }) => {
  return [startOfDay(subDays(endDate, daysBack)), endOfDay(endDate)];
};

export const entityToDateRange = ({ startDate, endDate }) => {
  return [startOfDay(startDate), endOfDay(endDate)];
};

export const rangeEqual = (range1, range2) => {
  const [start1, end1] = range1;
  const [start2, end2] = range2;

  return isEqual(start1, start2) && isEqual(end1, end2);
};

export const bucketTimeInMinutes = ({ date = new Date(), bucket = 15, roundUp = true } = {}) => {
  const coeff = 1000 * 60 * bucket;
  if (roundUp) {
    return new Date(Math.ceil(date.getTime() / coeff) * coeff);
  }
  return new Date(Math.floor(date.getTime() / coeff) * coeff);
};

export const getDateRangeFromDate = ({ date, hoursBetween }) => {
  const offset = hoursBetween / 2;
  return [getOffsetDate({ date, hours: -offset }), getOffsetDate({ date, hours: offset })];
};

export const getCurrentYear = () => {
  return format(new Date(), 'yyyy');
};

export const getCurrentMonth = () => {
  return format(new Date(), 'MMMM');
};

export const getYearRange = ({ startDate }) => {
  const currentDate = new Date();
  const yearsArray = eachYearOfInterval({
    start: startDate,
    end: currentDate,
  });
  return yearsArray.map((year) => format(year, 'yyyy')).reverse();
};

export const getAllMonths = () => {
  const allMonths = eachMonthOfInterval({
    start: new Date(2023, 0),
    end: new Date(2023, 11),
  });
  return allMonths.map((month) => {
    return format(month, 'MMMM');
  });
};

// returns the day after the final sunday in the previous month.
export const getStartOfBroadcastMonth = (m) => {
  const previousMonth = subMonths(m, 1);
  const lastDay = endOfMonth(previousMonth);
  const lastDayOfWeek = lastDay.getDay();
  const daysInMonth = endOfMonth(previousMonth).getDate();
  const lastSunday = daysInMonth - lastDayOfWeek;
  return startOfDay(new Date(previousMonth.getFullYear(), previousMonth.getMonth(), lastSunday + 1));
};

// returns the final sunday in the current month.
export const getEndOfBroadcastMonth = (m) => {
  const lastDay = endOfMonth(m);
  const lastDayOfWeek = lastDay.getDay();
  const daysInMonth = endOfMonth(m).getDate();
  const lastSunday = daysInMonth - lastDayOfWeek;
  return startOfDay(new Date(m.getFullYear(), m.getMonth(), lastSunday));
};

export const formatLocalDate = (value) => {
  const userTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = userTZFromMongo(value, userTimeZone);

  if (!date) {
    return '-';
  }

  /** @type {Intl.DateTimeFormatOptions} */
  const opts = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  const now = new Date();
  if (now.getFullYear() !== date.getFullYear()) {
    opts.year = 'numeric';
  }
  return date.toLocaleString(undefined, opts);
};

export const checkValidDate = (date) => isDate(date) && isValid(date);

export const getTimezoneForDisplay = (date) => {
  let displayDate = date;

  if (!checkValidDate(displayDate)) {
    displayDate = new Date();
  }

  const zonedDate = utcToZonedTime(displayDate, easternTZ);
  const offset = zonedDate.getTimezoneOffset();

  let timeZoneAbbreviation;
  if (offset === 300) {
    timeZoneAbbreviation = 'EST';
  } else if (offset === 240) {
    timeZoneAbbreviation = 'EDT';
  }
  return timeZoneAbbreviation;
};

const getTimeZoneHourAdjustment = ({ sourceTimezone, targetTimezone }) => {
  const now = new Date();

  const sourceHourFormatter = new Intl.DateTimeFormat('en-US', {
    timeZone: sourceTimezone,
    hour: 'numeric',
    hour12: false,
  });

  const targetHourFormatter = new Intl.DateTimeFormat('en-US', {
    timeZone: targetTimezone,
    hour: 'numeric',
    hour12: false,
  });

  const sourceHour = Number(sourceHourFormatter.format(now));
  const targetHour = Number(targetHourFormatter.format(now));

  return targetHour - sourceHour;
};

export const adjustReportScheduleDaysForTimezone = ({ days, hour, sourceTimezone, targetTimezone }) => {
  const adjustedDays = [];
  const hourAdjustment = getTimeZoneHourAdjustment({ sourceTimezone, targetTimezone });
  const adjustedHour = hour + hourAdjustment;

  days?.forEach((day) => {
    let adjustedDay = day;
    if (adjustedHour >= 24) {
      adjustedDay = (day + 1) % 7;
    } else if (adjustedHour < 0) {
      adjustedDay = (day - 1 + 7) % 7;
    }
    adjustedDays.push(adjustedDay);
  });

  return adjustedDays;
};

export const adjustReportScheduleHourForTimezone = ({ hour, sourceTimezone, targetTimezone }) => {
  const hourAdjustment = getTimeZoneHourAdjustment({ sourceTimezone, targetTimezone });
  let adjustedHour = (hour + hourAdjustment) % 24;
  if (adjustedHour < 0) {
    adjustedHour += 24;
  }
  return adjustedHour;
};
