import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ExtensionDemoStepsMap, stepsMinRequirements } from './constants';

import demoStyles from './extension-demo.module.css';

export const getStepCompleted = ({ encodeArgs, stepPath }) => {
  const isChooseFormatStep = matchPath({ path: ExtensionDemoStepsMap.CHOOSE_FORMAT, end: true }, stepPath);
  let minReqs;

  if (isChooseFormatStep) {
    const { adFormat } = encodeArgs;
    minReqs = stepsMinRequirements[ExtensionDemoStepsMap.CHOOSE_FORMAT][adFormat];
  } else {
    minReqs = stepsMinRequirements[stepPath];
  }

  if (Array.isArray(minReqs)) {
    for (const el of minReqs) {
      if (!encodeArgs[el]) return false;
    }
  } else {
    return false;
  }

  return true;
};

const StepDivider = ({ isStepActive, isStepCompleted }) => {
  return (
    <div
      className={classNames(
        demoStyles.stepDivider,
        isStepActive || isStepCompleted ? demoStyles.activeStepDivider : ''
      )}
    />
  );
};

StepDivider.propTypes = {
  isStepActive: PropTypes.bool.isRequired,
  isStepCompleted: PropTypes.bool.isRequired,
};

const Stepper = ({ encodeArgs, steps }) => {
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      {Object.entries(steps).map(([stepPath, label], idx) => {
        const isChooseFormatStep = matchPath({ path: ExtensionDemoStepsMap.CHOOSE_FORMAT, end: false }, stepPath);
        const isCompleted = getStepCompleted({
          encodeArgs,
          stepPath,
        });
        const firstItem = idx === 0;
        const isActive = matchPath({ path: stepPath, end: !isChooseFormatStep }, pathname);

        return (
          <div className={demoStyles.stepperStepContainer} key={stepPath}>
            {firstItem ? null : <StepDivider isStepActive={!!isActive} isStepCompleted={isCompleted} />}
            <div
              className={classNames(
                demoStyles.stepperStep,
                isActive || isCompleted ? demoStyles.activeStepperStep : ''
              )}
            >
              <p>Step {idx + 1}</p>
              <p className={classNames(demoStyles.stepName, isActive || isCompleted ? demoStyles.activeStepName : '')}>
                {label}
              </p>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

Stepper.defaultProps = {};
Stepper.propTypes = {
  encodeArgs: PropTypes.shape({}).isRequired,
  steps: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Stepper;
