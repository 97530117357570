@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.careersContainer {
  padding: 45px 4vw 0px 4vw;
}

.careerHeading {
  color: #232833;
  font-family: Selecta;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 112.5% */
}

.careerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #e2e2e2;
}

.careerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  position: sticky;
  top: 60px;
  background-color: #ffffff;
  height: 84px;
}

.careerLineItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addIcon {
  color: #131416;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.rotateIcon {
  animation: spin 1s linear 0s normal;
}

.careerText {
  color: var(--siteDarkGrey);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

h4.careerText {
  font-weight: 800;
}

@media (max-width: 700px) {
  .careerHeading {
    font-size: 24px;
  }
  .addIcon {
    font-size: 28px;
  }
  .careerText {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .careerHeading {
    font-size: 20px;
  }
  .addIcon {
    font-size: 24px;
  }
}
