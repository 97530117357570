import React from 'react';
import { matchPath, NavLink, Outlet, unstable_useBlocker, useOutletContext } from 'react-router-dom';
import classNames from 'classnames';

import {
  defaultAdArgs,
  DemoAdFormatsProps,
  encodeArgsType,
  ExtensionDemoStepsMap,
  setEncodeArgsType,
} from '../constants';
import { adFormats } from '../../../../utils/content-stream-monitoring-utils';
import ConfirmationDialog from '../../../confirmation-dialog';
import PipFooter from './pip-footer';
import LbarFooter from './l-bar-footer';
import OverlayFooter from './overlay-footer';

import styles from '../../site.module.css';
import demoStyles from '../extension-demo.module.css';
import useRedirectToInitialStep from '../hooks/useRedirectToInitialStep';

const FormatFooter = ({ encodeArgs, setEncodeArgs }) => {
  const { adFormat } = encodeArgs;
  const footerProps = { encodeArgs, setEncodeArgs };

  switch (adFormat) {
    case adFormats.pip:
      return <PipFooter {...footerProps} />;

    case adFormats.squeezeBack:
      return <LbarFooter {...footerProps} />;

    case adFormats.overlay:
      return <OverlayFooter {...footerProps} />;

    default:
      return null;
  }
};

FormatFooter.propTypes = {
  encodeArgs: encodeArgsType.isRequired,
  setEncodeArgs: setEncodeArgsType.isRequired,
};

const ChooseFormat = () => {
  /** @type Object*/
  const [encodeArgs, setEncodeArgs] = useOutletContext();
  const { adUrl, skin, overlay } = encodeArgs;

  useRedirectToInitialStep({
    step: ExtensionDemoStepsMap.CHOOSE_FORMAT,
    encodeArgs,
  });

  const uploadedAdAssets = !!(adUrl || skin || overlay);

  const blocker = unstable_useBlocker(({ currentLocation, nextLocation }) => {
    const nextLocationInsertionPoint = matchPath(
      {
        path: ExtensionDemoStepsMap.INSERTION_POINT,
        end: true,
      },
      nextLocation.pathname
    );
    return uploadedAdAssets && !nextLocationInsertionPoint && currentLocation.pathname !== nextLocation.pathname;
  });

  return (
    <React.Fragment>
      <div
        className={classNames(styles.flex, styles.fullWidth, styles.justifyCenter, styles.alignCenter, styles.flexCol)}
        style={{
          gap: 43,
        }}
      >
        <div className={demoStyles.chooseFormatContainer}>
          <div className={demoStyles.adFormatContainer}>
            <div className={demoStyles.adFormatTitleContainer}>
              <p className={demoStyles.adFormatTitle}>Ad Formats</p>
              <div className={demoStyles.adFormatTitleDivider} />
            </div>
            <div className={demoStyles.adFormatList}>
              {Object.entries(DemoAdFormatsProps).map(([path, { icon: Icon, label }]) => {
                return (
                  <NavLink
                    className={({ isActive }) => {
                      return classNames(
                        demoStyles.adFormatListItem,
                        (isActive && demoStyles.activeAdFormatListItem) || ''
                      );
                    }}
                    key={path}
                    relative="path"
                    to={`../${path}`}
                  >
                    <Icon />
                    <p className={demoStyles.adFormatListItemLabel}>{label}</p>
                  </NavLink>
                );
              })}
            </div>
          </div>
          <div>
            <Outlet context={[encodeArgs, setEncodeArgs]} />
          </div>
        </div>
        <FormatFooter encodeArgs={encodeArgs} setEncodeArgs={setEncodeArgs} />
      </div>
      {blocker.state === 'blocked' ? (
        <ConfirmationDialog
          contentText={`
                Navigating away will erase any changes and uploads made.
            `}
          onConfirmClick={() => {
            setEncodeArgs({
              ...encodeArgs,
              ...defaultAdArgs,
            });
            blocker.proceed?.();
          }}
          onCancelClick={() => blocker.reset?.()}
        />
      ) : null}
    </React.Fragment>
  );
};

ChooseFormat.defaultProps = {};
ChooseFormat.propTypes = {};

export default ChooseFormat;
