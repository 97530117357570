import React from 'react';
import { Button, Container } from '@mui/material';
import classNames from 'classnames';
import { NorthEast } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

import styles from '../site.module.css';
import csStyles from './creative-studio.module.css';
import homeStyles from '../home/home.module.css';
import RequestDemoBanner from '../request-demo-banner';
import CsBgLogoLeft from '../svg/cs-bg-logo-left';
import CsBgLogoRight from '../svg/cs-bg-logo-right';

const AD_FORMATS = Object.freeze({
  PIP: 'pip',
  LBAR: 'lbar',
  OVERLAY: 'overlay',
});

const VIDEO_URLS = {
  [AD_FORMATS.PIP]: 'https://tl-open-content.s3.amazonaws.com/web-assets/cs-parallax-pip.mp4',
  [AD_FORMATS.LBAR]: 'https://tl-open-content.s3.amazonaws.com/web-assets/cs-parallax-lbar-short-fast.mp4',
  [AD_FORMATS.OVERLAY]: 'https://tl-open-content.s3.amazonaws.com/web-assets/cs-parallax-overlay-fast.mp4',
};

const useOnIntersecting = ({ onIntersecting, onNotIntersecting = () => {}, observerOptions = {} }) => {
  const ref = React.useRef(null);

  // eslint-disable-next-line
  React.useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            onIntersecting();
          } else {
            onNotIntersecting();
          }
        },
        {
          ...observerOptions,
        }
      );
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref.current]);

  return {
    ref,
  };
};

const CreativeStudio = () => {
  const mobile = window.innerWidth <= 600;

  const [searchParams, setSearchParams] = useSearchParams();
  const adFormatParam = searchParams.get('q');
  const [adFormatContainerIntersecting, setAdFormatContainerIntersecting] = React.useState(false);

  const allVideos = document.getElementsByClassName(csStyles.csAdFormatsImg);
  for (const video of allVideos) {
    // @ts-ignore
    if (video.paused && !video.id.match(/mobile/)) {
      // @ts-ignore
      video.currentTime = 0;
    }
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  React.useEffect(() => {
    const currentVideo = document.getElementById(adFormatParam || AD_FORMATS.PIP);
    if (currentVideo) {
      // @ts-ignore
      currentVideo.currentTime = 0;
      // @ts-ignore
      currentVideo.play();
    }
    const videos = document.getElementsByClassName(csStyles.csAdFormatsImg);
    for (const video of videos) {
      if (video.id !== currentVideo?.id && !video.id.match(/mobile/)) {
        // @ts-ignore
        video.pause();
        // @ts-ignore
        video.currentTime = 0;
      }
    }
  }, [adFormatParam]);

  const { ref: adFormatContainerRef } = useOnIntersecting({
    onIntersecting: () => {
      setAdFormatContainerIntersecting(true);
    },
    onNotIntersecting: () => {
      setAdFormatContainerIntersecting(false);
    },
    observerOptions: { threshold: 1 },
  });
  const { ref: pipAdFormatHeadingRef } = useOnIntersecting({
    onIntersecting: () => {
      searchParams.delete('q');
      setSearchParams(searchParams);
      const video = document.getElementById(AD_FORMATS.PIP);
      // @ts-ignore
      video.currentTime = 0;
    },
  });
  const { ref: pipHeadingRef } = useOnIntersecting({
    onIntersecting: () => {
      searchParams.delete('q');
      setSearchParams(searchParams);
    },
    observerOptions: { threshold: 0.7 },
  });
  const { ref: lbarHeadingRef } = useOnIntersecting({
    onIntersecting: () => {
      searchParams.set('q', AD_FORMATS.LBAR);
      setSearchParams(searchParams);
    },
    observerOptions: { threshold: 0.7 },
  });
  const { ref: overlayHeadingRef } = useOnIntersecting({
    onIntersecting: () => {
      searchParams.set('q', AD_FORMATS.OVERLAY);
      setSearchParams(searchParams);
    },
    observerOptions: { threshold: 0.7 },
  });
  const { ref: pipMobileSectionRef } = useOnIntersecting({
    onIntersecting: () => {
      searchParams.delete('q');
      setSearchParams(searchParams);
      const video = document.getElementById(`${AD_FORMATS.PIP}-mobile`);
      // @ts-ignore
      video.currentTime = 0;
    },
  });
  const { ref: lbarMobileSectionRef } = useOnIntersecting({
    onIntersecting: () => {
      searchParams.delete('q');
      setSearchParams(searchParams);
      const video = document.getElementById(`${AD_FORMATS.LBAR}-mobile`);
      // @ts-ignore
      video.currentTime = 0;
    },
  });
  const { ref: overlayMobileSectionRef } = useOnIntersecting({
    onIntersecting: () => {
      searchParams.delete('q');
      setSearchParams(searchParams);
      const video = document.getElementById(`${AD_FORMATS.OVERLAY}-mobile`);
      // @ts-ignore
      video.currentTime = 0;
    },
  });

  return (
    <div>
      <Container maxWidth="xl" className={classNames(styles.container, csStyles.csContainer)}>
        <div className={classNames(styles.headerOffset, csStyles.csHeaderOffset)}>
          <div className={csStyles.csHeaderContent}>
            <div className={styles.centerBlock}>
              <div>
                <div className={csStyles.csHeadingLogoContainer}>
                  <img src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/cs-logo.svg" alt="creative studio logo" />
                  <div className={classNames(styles.description, styles.medium, csStyles.csDescription)}>
                    Creative Studio
                  </div>
                </div>
                <h1 className={classNames(styles.heading, styles.medium, csStyles.csHeading)}>
                  Build assets for a captivated audience
                </h1>
                <div className={classNames(styles.description, styles.medium, csStyles.csDescription)}>
                  Build creatives and templates for new in-stream inventory and VAST compliant tags.
                </div>
                <Button
                  endIcon={<NorthEast />}
                  className={classNames(styles.button, styles.buttonWhite)}
                  href="/request-demo"
                  style={{ marginTop: 24 }}
                >
                  Get Started
                </Button>
              </div>
            </div>

            <div className={classNames(styles.centerBlock)} style={{ marginTop: 69, position: 'relative' }}>
              <img
                className={classNames(csStyles.csImg)}
                src="https://tl-open-content.s3.amazonaws.com/web-assets/cs-screenshot.png"
                alt="creative studio screenshot"
                height="auto"
                width="82%"
              />
              <img
                width="auto"
                src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/ad-content-scene-card.svg"
                alt=""
                className={classNames(csStyles.csImgLeft, csStyles.csHeaderOverlayImgs)}
              />
              <img
                width="auto"
                src="https://dxgcj7vu1u7nu.cloudfront.net/web-assets/initial-view.svg"
                alt=""
                className={classNames(csStyles.csImgRight, csStyles.csHeaderOverlayImgs)}
              />
            </div>
          </div>

          <div className={csStyles.csHeaderGradient} />
          <CsBgLogoLeft />
          <CsBgLogoRight />
        </div>

        <div
          className={classNames(
            csStyles.csAdFormatsContainer,
            styles.mt12,
            !mobile ? styles.justifyCenter : styles.justifyBetween
          )}
        >
          {mobile ? (
            <div className={csStyles.csAdFormatsHeadings}>
              <section ref={pipMobileSectionRef} style={{ overflow: 'visible', position: 'relative', zIndex: 1 }}>
                <h1 className={classNames(styles.heading, csStyles.csHeading)}>Picture-in-Picture</h1>
                <div className={classNames(styles.description, csStyles.csDescription)} style={{ textAlign: 'center' }}>
                  Effortlessly merge content and ads within a unified video stream.
                </div>
                <video
                  id={`${AD_FORMATS.PIP}-mobile`}
                  className={csStyles.csAdFormatsImg}
                  src={VIDEO_URLS[AD_FORMATS.PIP]}
                  autoPlay
                  playsInline
                  loop
                  muted
                />
              </section>
              <section ref={lbarMobileSectionRef} style={{ overflow: 'visible', position: 'relative', zIndex: 1 }}>
                <h1 className={classNames(styles.heading, csStyles.csHeading)}>Squeeze Back</h1>
                <div className={classNames(styles.description, csStyles.csDescription)} style={{ textAlign: 'center' }}>
                  Reveal personalized L-Bar and Dynamic Border Framed canvases that can be customized, animated, and IAB
                  trackable.
                </div>
                <video
                  id={`${AD_FORMATS.LBAR}-mobile`}
                  className={csStyles.csAdFormatsImg}
                  src={VIDEO_URLS[AD_FORMATS.LBAR]}
                  autoPlay
                  playsInline
                  loop
                  muted
                />
              </section>
              <section ref={overlayMobileSectionRef} style={{ overflow: 'visible' }}>
                <h1 className={classNames(styles.heading, csStyles.csHeading)}>Overlay</h1>
                <div className={classNames(styles.description, csStyles.csDescription)} style={{ textAlign: 'center' }}>
                  Design custom overlays for your most premium content and brand advertisers.
                </div>
                <video
                  id={`${AD_FORMATS.OVERLAY}-mobile`}
                  className={csStyles.csAdFormatsImg}
                  src={VIDEO_URLS[AD_FORMATS.OVERLAY]}
                  autoPlay
                  playsInline
                  loop
                  muted
                />
              </section>
            </div>
          ) : (
            <div className={classNames(styles.flex, csStyles.adFormatsContainer)}>
              {adFormatContainerIntersecting ? <div className={csStyles.csAdFormatsTopGrad} /> : null}
              <div ref={pipAdFormatHeadingRef} className={csStyles.csAdFormatsHeadings}>
                <section ref={pipHeadingRef}>
                  <h1 className={classNames(styles.heading, csStyles.csHeading)}>Picture-in-Picture</h1>
                  <p className={classNames(styles.description, csStyles.csDescription)}>
                    Effortlessly merge content and ads within a unified video stream.
                  </p>
                </section>
                <section ref={lbarHeadingRef}>
                  <div>
                    <h1 className={classNames(styles.heading, csStyles.csHeading)}>Squeeze Back</h1>
                    <p className={classNames(styles.description, csStyles.csDescription)}>
                      Reveal personalized L-Bar and Dynamic Border Framed canvases that can be customized, animated, and
                      IAB trackable.
                    </p>
                  </div>
                </section>
                <section ref={overlayHeadingRef}>
                  <div>
                    <h1 className={classNames(styles.heading, csStyles.csHeading)}>Overlay</h1>
                    <p className={classNames(styles.description, csStyles.csDescription)}>
                      Design custom overlays for your most premium content and brand advertisers.
                    </p>
                  </div>
                </section>
              </div>
              <div className={csStyles.csAdFormatImgs} ref={adFormatContainerRef}>
                <video
                  id={AD_FORMATS.PIP}
                  style={{ display: !adFormatParam ? 'inline' : 'none' }}
                  className={csStyles.csAdFormatsImg}
                  src={VIDEO_URLS[AD_FORMATS.PIP]}
                  loop
                  muted
                />
                <video
                  id={AD_FORMATS.LBAR}
                  style={{ display: adFormatParam === AD_FORMATS.LBAR ? 'inline' : 'none' }}
                  className={csStyles.csAdFormatsImg}
                  src={VIDEO_URLS[AD_FORMATS.LBAR]}
                  loop
                  muted
                />
                <video
                  id={AD_FORMATS.OVERLAY}
                  style={{ display: adFormatParam === AD_FORMATS.OVERLAY ? 'inline' : 'none' }}
                  className={csStyles.csAdFormatsImg}
                  src={VIDEO_URLS[AD_FORMATS.OVERLAY]}
                  loop
                  muted
                />
              </div>
              {adFormatContainerIntersecting ? <div className={csStyles.csAdFormatsBottomGrad} /> : null}
            </div>
          )}
        </div>

        <div className={classNames(styles.centerBlock, homeStyles.requestDemoWrapper)} style={{ padding: 0 }}>
          <RequestDemoBanner />
        </div>
      </Container>
    </div>
  );
};

export default CreativeStudio;
