import React from 'react';
import { createRoot } from 'react-dom/client';

import './styles/application.css';

import * as utils from './utils/api';

import store from './redux/store';
import { IndexApp } from './index-app';
import { initFullStory } from './utils/fullstory';

export const prepareApp = () => {
  if (process.env.NODE_ENV === 'test') return;

  const entrypoint = 'index';
  const lastBuildTime = localStorage.getItem(`${entrypoint}-last-rebuild-time`);
  if (lastBuildTime) {
    console.log(`Last rebuild ${lastBuildTime}ms`);
  }

  utils.prepareRequestHeaders();
  utils.handleAuthErrors(store);
  initFullStory();

  console.log(`${entrypoint} init`);
};

prepareApp();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<IndexApp />);
