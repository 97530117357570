import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadingButton } from '@mui/lab';

import styles from '../site.module.css';
import demoStyles from './extension-demo.module.css';
import UploadIcon from './svg/upload-icon';

const FileUploadButton = ({ acceptedTypes, buttonId, isUploadingFile, uploadedFile, handleOnChange, label }) => {
  const inputRef = React.useRef(null);

  if (uploadedFile) {
    return null;
  }

  return (
    <React.Fragment>
      <input accept={acceptedTypes} ref={inputRef} id={buttonId} onChange={handleOnChange} type="file" hidden />
      <LoadingButton
        className={classNames(styles.button, styles.buttonPurple, demoStyles.demoFooterButtons)}
        component="label"
        disabled={isUploadingFile}
        htmlFor={buttonId}
        id={buttonId}
        loading={isUploadingFile}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          handleOnChange(e);
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            inputRef?.current.click();
          }
        }}
        startIcon={<UploadIcon />}
      >
        {label}
      </LoadingButton>
    </React.Fragment>
  );
};

FileUploadButton.defaultProps = {
  buttonId: undefined,
  label: 'Upload File',
};

FileUploadButton.propTypes = {
  acceptedTypes: PropTypes.string.isRequired,
  buttonId: PropTypes.string,
  isUploadingFile: PropTypes.bool.isRequired,
  uploadedFile: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default FileUploadButton;
