import axios from 'axios';

import { removeLeadingSlash } from '../utils/common';

const sizes = ['bytes', 'kB', 'MB', 'GB', 'TB'];
export function formatBytes(bytes, precision) {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = precision < 0 ? 0 : precision;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const adTypeToFields = {
  overlay: 'overlayEnabled',
  pip: 'pipEnabled',
  interstitial: 'interstitialEnabled',
  preRoll: 'prerollEnabled',
  squeezeBack: 'squeezeBackEnabled',
};

export const sourceTypes = {
  mp4URL: 'mp4URL',
  mp4File: 'mp4File',
  hlsURL: 'hlsURL',
  liveInput: 'liveInput',
  liveHlsURL: 'liveHlsURL',
  liveDashURL: 'liveDashURL',
  live2Vod: 'live2Vod',
};

export const liveSourceTypes = {
  [sourceTypes.liveHlsURL]: sourceTypes.liveHlsURL,
  [sourceTypes.liveDashURL]: sourceTypes.liveDashURL,
  [sourceTypes.liveInput]: sourceTypes.liveInput,
};

export const sourceTypeLabelsForUserInput = {
  [sourceTypes.mp4File]: 'MP4 File',
  [sourceTypes.mp4URL]: 'MP4 Url',
  [sourceTypes.hlsURL]: 'VOD HLS Url',
  [sourceTypes.liveHlsURL]: 'Live HLS Url',
  [sourceTypes.liveDashURL]: 'Live Dash Url',
};

export const sourceTypeLabels = {
  ...sourceTypeLabelsForUserInput,
  liveInput: 'Live Input',
  live2Vod: 'Live to VOD HLS Url',
};

export const INPUT_TYPES = {
  contentOrigin: 'content-origin',
  contentStream: 'content-stream',
  account: 'account',
  campaign: 'campaign',
  lineItem: 'line-item',
  ad: 'ad',
  advertiser: 'advertiser',
  contentStreamTag: 'content-stream-tag',
  inventoryEventType: 'inventory-event-type',
};

export const INPUT_TYPE_ABBREVIATIONS = {
  [INPUT_TYPES.contentStream]: 'CS',
  [INPUT_TYPES.contentOrigin]: 'CO',
  [INPUT_TYPES.account]: 'A',
  [INPUT_TYPES.campaign]: 'CA',
  [INPUT_TYPES.lineItem]: 'LI',
  [INPUT_TYPES.ad]: 'C',
  [INPUT_TYPES.advertiser]: 'AV',
  [INPUT_TYPES.contentStreamTag]: 'T',
  [INPUT_TYPES.inventoryEventType]: 'E',
};

export const defaultInputTypeDisplayNames = {
  [INPUT_TYPES.contentOrigin]: 'Content Origins',
  [INPUT_TYPES.contentStream]: 'Content Streams',
  [INPUT_TYPES.account]: 'Accounts',
  [INPUT_TYPES.contentStreamTag]: 'Content Stream Tags',
  [INPUT_TYPES.inventoryEventType]: 'Inventory Event Types',
};

export const inputTypeDisplayNames = {
  ...defaultInputTypeDisplayNames,
  [INPUT_TYPES.campaign]: 'Campaigns',
  [INPUT_TYPES.lineItem]: 'Line Items',
  [INPUT_TYPES.ad]: 'Creatives',
  [INPUT_TYPES.advertiser]: 'Advertisers',
  [INPUT_TYPES.contentStreamTag]: 'Content Stream Tags',
  [INPUT_TYPES.inventoryEventType]: 'Inventory Event Types',
};

export const playlistAuthTypeLabels = {
  akamai: 'Akamai',
  floSportsSpurl: 'FloSports SPURL',
  willow: 'Willow',
  willowVod: 'Willow VOD',
};

export const accountAuthTypeLabels = (account) => {
  if (!account) return {};

  const authTypes = account.playlistAuthTypes || [];
  const authTypeLabels = {};

  for (const key of authTypes) {
    authTypeLabels[key] = playlistAuthTypeLabels[key];
  }

  return authTypeLabels;
};

/**
 *
 * @param {Object} data
 * @param {String} data.playlistAuthType
 * @returns {Boolean}
 */
export const playlistNeedsAuthKey = ({ playlistAuthType }) => {
  const needsKeyArray = ['akamai', 'floSportsSpurl'];
  return needsKeyArray.includes(playlistAuthType);
};

export const contextUrls = () => {
  const urls = [];
  urls.push({ name: 'Streams', url: `` });
  urls.push({ name: 'Origins', url: `../origins` });
  urls.push({ name: 'Supply Routes', url: `../supply-routes` });
  return urls;
};

export const contentContextUrls = ({ sourceType, isAdmin }) => {
  const urls = [];
  urls.push({ name: 'Config', url: `config` });
  if (sourceType === sourceTypes.liveHlsURL) {
    urls.push({ name: 'Schedule', url: `schedule` });
    urls.push({ name: 'Monitoring', url: `monitoring` });
  } else if (isAdmin) {
    urls.push({ name: 'Extension Targeting', url: `extension-targeting` });
  }
  return urls;
};

export const formatBitrate = (bytesPer) => `${Math.floor(bytesPer / 1000)}kbps`;

export const validateTimeOffset = (offsetString) => {
  const re = new RegExp('[0-2][0-9]:[0-5][0-9]:[0-5][0-9].[0-9]{3}$');
  return re.exec(offsetString);
};

export const offsetStringToMs = (offset) => {
  return new Date(`1970-01-01T${offset}Z`).getTime();
};

export const formatMsToHHMMSS = (ms) => {
  return new Date(ms).toISOString().slice(11, 23);
};

export const displayRepeatAfterMs = (repeatAfterMs) => {
  if (!repeatAfterMs) return false;

  const converted = repeatAfterMs / 1000;

  if (converted < 1) return false;

  if (converted < 60) {
    return `Every ${converted} seconds`;
  }

  if (converted === 60) {
    return 'Every minute';
  }

  let response;
  if (converted >= 61) {
    const minutes = Math.floor(converted / 60);
    const seconds = Math.floor(converted - minutes * 60);
    response = `Every ${minutes} minutes`;

    if (minutes === 1) {
      response = response.replace('minutes', 'minute');
    }

    if (seconds > 0) {
      response += ` and ${seconds} seconds`;

      if (seconds === 1) {
        response = response.replace('seconds', 'second');
      }
    }
  }

  return response;
};

export const getContentStreamPresets = async (accountId, search = '') => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/content-streams/presets${search}`;
  return axios.get(url).then((response) => response.data);
};

export const updateContentStream = async ({ accountId, contentStream }) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/content-stream`;
  return axios.put(url, contentStream).then((response) => response.data);
};

export const getContentStreams = async (accountId, search) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/content-streams${search}`;
  return axios.get(url).then((response) => response.data);
};

export const postContentStreamsByAccountAndIds = async (accountId, contentStreamIds, search = '') => {
  const idQuery = { ids: contentStreamIds || [] };
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/content-streams/by_ids${search}`;

  return axios.post(url, idQuery).then((response) => response.data);
};

export const postExtensionTargeting = async (contentStreamId, accountId, contentStreamTags = [], search) => {
  const tagQuery = { contentStreamTags };
  const url = `
  ${SERVICE_URL}/api/v1/account/${accountId}/content-stream/${contentStreamId}/extension-targeting${search}
  `;
  return axios.post(url, tagQuery).then((response) => {
    return response.data;
  });
};

export const postExtensionTargetingByIds = async (
  contentStreamId,
  accountId,
  contentStreamTags = [],
  extensionIds = [],
  search = ''
) => {
  const query = { ids: extensionIds, contentStreamTags };
  const url = `
  ${SERVICE_URL}/api/v1/account/${accountId}/content-stream/${contentStreamId}/extension-targeting/by_ids${search}
  `;
  return axios.post(url, query).then((response) => response.data);
};

export const getContentStream = async ({ accountId, contentStreamId }) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/content-stream/${contentStreamId}`;
  return axios.get(url).then((response) => response.data);
};

export const postContentStreamsByIds = async (contentStreamIds, search = '') => {
  const idQuery = { ids: contentStreamIds || [] };
  const url = `${SERVICE_URL}/api/v1/content-streams/by_ids${search}`;

  return axios.post(url, idQuery).then((response) => response.data);
};

export const deleteContentStream = async ({ accountId, contentStreamId }) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/content-stream/${contentStreamId}`;
  return axios.delete(url).then((response) => response.data);
};

export const buildS3SourceUrl = ({ s3BucketName }) => {
  return `https://${s3BucketName}.s3.amazonaws.com`;
};

export const buildS3Url = ({ s3BucketName, pathname }) => {
  return `${buildS3SourceUrl({ s3BucketName })}/${removeLeadingSlash(pathname)}`;
};

export const archiveContentStream = async ({ accountId, contentStream, archive }) => {
  const body = { archived: archive };
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/content-stream/${contentStream._id}/archive`;

  return axios.put(url, body).then((response) => response.data);
};

export const formatContentStreamDuration = (seconds, sourceType) => {
  if (liveSourceTypes[sourceType]) {
    return 'Live';
  }

  if (!seconds) {
    return '00:00:00';
  }

  seconds = Math.floor(seconds);

  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};
