/* eslint-disable max-len */
import React from 'react';

const UploadIcon = React.memo(() => {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 10.9765C8.31111 10.9765 8.15528 10.9256 8.0325 10.8238C7.90972 10.722 7.84833 10.5928 7.84833 10.4362V2.30873L5.185 4.51678C5.05278 4.6264 4.90167 4.67729 4.73167 4.66946C4.56167 4.66163 4.41055 4.61074 4.27833 4.51678C4.14611 4.40716 4.08 4.27796 4.08 4.12919C4.08 3.98042 4.14611 3.85123 4.27833 3.74161L7.93333 0.711409C8.00889 0.648769 8.09389 0.597875 8.18833 0.558725C8.28278 0.519575 8.38667 0.5 8.5 0.5C8.61333 0.5 8.71722 0.519575 8.81167 0.558725C8.90611 0.597875 8.99111 0.648769 9.06667 0.711409L12.75 3.7651C12.8633 3.85906 12.9247 3.98042 12.9342 4.12919C12.9436 4.27796 12.8822 4.40716 12.75 4.51678C12.6178 4.61074 12.4619 4.65772 12.2825 4.65772C12.1031 4.65772 11.9472 4.60291 11.815 4.49329L9.15167 2.30873V10.4362C9.15167 10.5928 9.09028 10.722 8.9675 10.8238C8.84472 10.9256 8.68889 10.9765 8.5 10.9765ZM1.64333 14.5C1.19 14.5 0.802778 14.3669 0.481667 14.1007C0.160556 13.8345 0 13.5134 0 13.1376V10.7416C0 10.6007 0.0613888 10.4793 0.184167 10.3775C0.306944 10.2757 0.462778 10.2248 0.651666 10.2248C0.840555 10.2248 0.991667 10.2757 1.105 10.3775C1.21833 10.4793 1.275 10.6007 1.275 10.7416V13.1376C1.275 13.2159 1.31278 13.2864 1.38833 13.349C1.46389 13.4116 1.54889 13.443 1.64333 13.443H15.3567C15.4511 13.443 15.5361 13.4116 15.6117 13.349C15.6872 13.2864 15.725 13.2159 15.725 13.1376V10.7416C15.725 10.6007 15.7864 10.4793 15.9092 10.3775C16.0319 10.2757 16.1783 10.2248 16.3483 10.2248C16.5372 10.2248 16.6931 10.2757 16.8158 10.3775C16.9386 10.4793 17 10.6007 17 10.7416V13.1376C17 13.5134 16.8394 13.8345 16.5183 14.1007C16.1972 14.3669 15.81 14.5 15.3567 14.5H1.64333Z"
        fill="white"
      />
    </svg>
  );
});

export default UploadIcon;
