/* eslint-disable max-len */
import React from 'react';

const TlLogoWhite = React.memo(() => (
  <svg width="113" height="35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2629_236)">
      <path
        d="M27.7421 3.83359C27.1487 3.48326 26.4339 3.47147 25.8299 3.8041L21.5996 6.13017V8.84196L26.3147 6.24931V20.9925L11.182 29.313V14.5698L15.7933 12.0338V9.32203L9.80769 12.6142C9.18954 12.9527 8.80615 13.6014 8.80615 14.3056V30.0668C8.80615 30.7568 9.16005 31.3773 9.7546 31.7276C10.0601 31.908 10.3975 31.9989 10.7361 31.9989C11.0546 31.9989 11.3743 31.9187 11.6668 31.7571L27.6914 22.9458C28.3084 22.6061 28.6917 21.9585 28.6917 21.2544V5.49439C28.6917 4.80436 28.3378 4.18391 27.7433 3.83359H27.7421Z"
        fill="#5A24FB"
      />
      <path
        d="M2.37584 11.0063L17.5086 2.68579V17.429L12.8973 19.965V22.6792L18.8841 19.3847C19.501 19.045 19.8844 18.3974 19.8844 17.6932V1.93205C19.8844 1.24202 19.5305 0.621576 18.9371 0.271249C18.3438 -0.0790768 17.6289 -0.0908723 17.0237 0.240581L1.00035 9.05064C0.383391 9.39035 0 10.0379 0 10.7421V26.5033C0 27.1933 0.353899 27.8137 0.94727 28.1641C1.2528 28.3445 1.59137 28.4354 1.92993 28.4354C2.24844 28.4354 2.56813 28.3551 2.86068 28.1935L7.09096 25.8675V23.1557L2.37584 25.7483V11.0063Z"
        fill="#5A24FB"
      />
      <path
        d="M41.1299 11.9064H43.5565V13.8892H41.1299V19.7209C41.1299 20.7471 41.5499 21.2602 42.693 21.2602C42.9725 21.2602 43.3689 21.213 43.5565 21.167V23.0331C43.3701 23.1026 42.8097 23.243 42.063 23.243C40.1496 23.243 38.9369 22.0764 38.9369 20.0476V13.8892H36.7899V11.9064H37.3963C38.609 11.9064 39.1221 11.1597 39.1221 10.1807V8.36182H41.1287V11.9075L41.1299 11.9064Z"
        fill="white"
      />
      <path
        d="M51.6041 14.1227C51.2773 14.0755 50.9506 14.0531 50.6474 14.0531C48.8048 14.0531 47.5685 15.0321 47.5685 17.5045V23.1262H45.3755V11.9063H47.5213V13.8656C48.3376 12.1399 49.7367 11.72 50.8798 11.72C51.183 11.72 51.4861 11.766 51.6029 11.7896V14.1215L51.6041 14.1227Z"
        fill="white"
      />
      <path
        d="M55.7094 16.712L58.7423 16.2685C59.4194 16.1753 59.6058 15.8249 59.6058 15.4286C59.6058 14.3328 58.8827 13.4222 57.1804 13.4222C55.6409 13.4222 54.7774 14.3788 54.6382 15.6846L52.5384 15.1951C52.772 13.0483 54.7078 11.5562 57.1344 11.5562C60.4941 11.5562 61.8 13.4694 61.8 15.661V21.2827C61.8 22.2617 61.8932 22.8456 61.9403 23.1252H59.7945C59.7485 22.8456 59.6778 22.4257 59.6778 21.6095C59.1882 22.4021 58.0687 23.4755 56.0621 23.4755C53.7759 23.4755 52.306 21.889 52.306 20.1173C52.306 18.1109 53.7759 16.9915 55.7117 16.712H55.7094ZM59.6046 18.3209V17.8078L56.1753 18.3209C55.2422 18.4612 54.5427 18.9968 54.5427 20.0005C54.5427 20.8404 55.2422 21.633 56.3617 21.633C58.1583 21.633 59.6034 20.7696 59.6034 18.3209H59.6046Z"
        fill="white"
      />
      <path
        d="M65.5994 23.125H63.4064V11.9063H65.5522V13.5152C66.3685 12.1163 67.698 11.5796 69.0039 11.5796C71.6864 11.5796 73.0159 13.5152 73.0159 16.0112V23.125H70.8229V16.3839C70.8229 14.821 70.1694 13.5612 68.21 13.5612C66.4829 13.5612 65.597 14.9378 65.597 16.6399V23.1238L65.5994 23.125Z"
        fill="white"
      />
      <path
        d="M76.1199 19.5099C76.2603 20.6764 77.1462 21.6095 78.7329 21.6095C79.9691 21.6095 80.6463 20.91 80.6463 20.1161C80.6463 19.4167 80.1331 18.88 79.2 18.67L77.2866 18.2501C75.5371 17.8774 74.4872 16.6872 74.4872 15.1019C74.4872 13.1886 76.2839 11.5562 78.4757 11.5562C81.5546 11.5562 82.5113 13.5626 82.7449 14.5652L80.8091 15.2882C80.7159 14.7044 80.2487 13.4222 78.4769 13.4222C77.3574 13.4222 76.6106 14.1453 76.6106 14.9155C76.6106 15.5914 77.0306 16.0821 77.8941 16.2685L79.7132 16.6648C81.7422 17.1083 82.8157 18.3445 82.8157 20.0005C82.8157 21.6566 81.4862 23.4755 78.7104 23.4755C75.6315 23.4755 74.3244 21.4927 74.1381 20.2105L76.1211 19.511L76.1199 19.5099Z"
        fill="white"
      />
      <path
        d="M84.4012 23.1251V11.9064H86.4998V13.3997C87.1994 12.1635 88.5996 11.5808 89.9055 11.5808C91.3046 11.5808 92.6577 12.2567 93.2652 13.7028C94.1511 12.0939 95.5974 11.5808 97.0212 11.5808C98.9807 11.5808 100.893 12.9102 100.893 15.8496V23.1274H98.7235V16.0832C98.7235 14.6134 98.0004 13.5176 96.3441 13.5176C94.8047 13.5176 93.7548 14.7302 93.7548 16.3403V23.1274H91.5618V16.0832C91.5618 14.637 90.8622 13.5176 89.1824 13.5176C87.6193 13.5176 86.5694 14.6842 86.5694 16.3639V23.1274H84.4L84.4012 23.1251Z"
        fill="white"
      />
      <path d="M102.666 23.1251V12.3027H104.836V23.1251H102.666Z" fill="white" />
      <path
        d="M110.574 12.3026H113.001V14.2854H110.574V20.1171C110.574 21.1433 110.994 21.6564 112.137 21.6564C112.417 21.6564 112.813 21.6092 113.001 21.5632V23.4293C112.815 23.4989 112.254 23.6393 111.507 23.6393C109.594 23.6393 108.381 22.4727 108.381 20.4439V14.2854H106.234V12.3026H106.841C108.053 12.3026 108.567 11.5559 108.567 10.5769V8.75806H110.573V12.3038L110.574 12.3026Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2629_236">
        <rect width="155" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

export default TlLogoWhite;
